import React, { useEffect, useState } from "react";
import { Box, Grid, Button } from "@mui/material";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import ProductItem from "./ProductItem";

import {
  loadOfferOfWeekProducts,
  loadOfferOfSeasonProducts,
  loadDiscountProducts,
} from "../services/services";
import { useTranslation } from "react-i18next";

const OffersTab = () => {
  const [offerOfWeek, setOfferOfWeek] = useState(null);
  const [offerOfSeason, setOfferOfSeason] = useState(null);
  const [discountList, setDiscountList] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setOfferOfWeek(await loadOfferOfWeekProducts());
      setOfferOfSeason(await loadOfferOfSeasonProducts());
      setDiscountList(await loadDiscountProducts());
    })();
  }, []);

  return (
    <Box
      sx={{
        "& .react-tabs__tab-list": {
          textAlign: "center",
          border: "none",
          margin: "25px 0px",
          "& li": {
            border: "none",
            "& span": {
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "-5px",
                left: "0%",
                width: "0%",
                height: "2.5px",
                backgroundColor: "#9bc55a",
                transition: "all .2s ease",
              },
              "&:hover": {
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: "-5px",
                  left: "0%",
                  width: "100%",
                  height: "2.5px",
                  backgroundColor: "#9bc55a",
                },
              },
            },

            "&.react-tabs__tab--selected": {
              "& span": {
                // padding:'6px 12px',
                // margin:''
                position: "relative",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: "-5px",
                  left: "0%",
                  width: "100%",
                  height: "2.5px",
                  backgroundColor: "#9bc55a",
                },

                // borderBottom:'2.5px solid #9bc55a !important',
              },
            },
          },
        },
      }}
    >
      <Tabs>
        <TabList>
          <Tab>
            <span>{t("Endirimli məhsullar")}</span>
          </Tab>
          <Tab>
            <span>{t("Həftənin təklifləri")}</span>
          </Tab>
          <Tab>
            <span>{t("Sezon təklifləri")}</span>
          </Tab>
        </TabList>

        <TabPanel>
          <Box>
            <Grid container spacing={5}>
              {offerOfWeek !== null
                ? offerOfWeek.map((item, i) => (
                    <Grid item xs={6} sm={6} md={3} lg={3} key={i}>
                      <ProductItem item={item} />
                    </Grid>
                  ))
                : ""}
            </Grid>
          </Box>
          <Box
            sx={{
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#9bc55a",
                textTransform: "capitalize",
              }}
            >
              Daha Çox
            </Button>
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <Grid container spacing={5}>
              {offerOfSeason !== null
                ? offerOfSeason.map((item, i) => (
                    <Grid item xs={6} sm={6} md={3} lg={3} key={i}>
                      <ProductItem item={item} />
                    </Grid>
                  ))
                : ""}
            </Grid>
          </Box>
          <Box
            sx={{
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#9bc55a",
                textTransform: "capitalize",
              }}
            >
              Daha Çox
            </Button>
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <Grid container spacing={5}>
              {discountList !== null
                ? discountList.map((item, i) => (
                    <Grid item xs={6} sm={6} md={3} lg={3} key={i}>
                      <ProductItem item={item} />
                    </Grid>
                  ))
                : ""}
            </Grid>
          </Box>
          <Box
            sx={{
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#9bc55a",
                textTransform: "capitalize",
              }}
            >
              Daha Çox
            </Button>
          </Box>
        </TabPanel>
      </Tabs>
    </Box>
  );
};

export default OffersTab;
