import axios from "axios";
const BASE_URL = "https://admin.barkodelectronics.az/V1.0";

/***************************************************/
/**************** Slider Load *****************/
/***************************************************/

export const loadBannerSliderImages = async () => {
  const res = await axios.get(`${BASE_URL}/image-slider`);
  return res.data;
};

/***************************************************/
/**************** Categories Load  *****************/
/***************************************************/

export const loadCategoryList = async () => {
  const res = await axios.get(`${BASE_URL}/category`);

  let sortedList = res.data?.sort((a, b) => {
    if (Number(a.row) > Number(b.row)) {
      return 1;
    } else if (Number(b.row) > Number(a.row)) {
      return -1;
    } else {
      return 0;
    }
  });
  // console.log("category>>>>>>", sortedList);
  return sortedList;
};

export const loadSubParentCategoryListById = async (categoryId) => {
  const res = await axios.get(
    `${BASE_URL}/category/${categoryId}/parent-subcategory`
  );
  let sortedList = res.data?.sort((a, b) => {
    return Number(a.pendingParentSubcategoryFour) - Number(b.pendingParentSubcategoryFour);
  });
  return sortedList;
};

export const loadSubChildCategoryListById = async (parentSubCategoryId) => {
  const res = await axios.get(
    `${BASE_URL}/parent-subcategory/${parentSubCategoryId}/child-subcategory`
  );
  let sortedList = res.data?.sort((a, b) => {
    return Number(a.pendingChildSubcategoryOne) - Number(b.pendingChildSubcategoryOne);
  });
  return sortedList;
};

/***************************************************/
/**************** Products Load ********************/
/***************************************************/

export const loadProductsBySubId = async (
  categoryId = 3,
  subcategoryId = 1
) => {
  const res = await axios.get(`${BASE_URL}/product/all`, {
    params: {
      categoryId,
      subcategoryId,
    },
  });
  return res.data;
};




/***************************************************/
/********************* Partners ********************/
/***************************************************/

// export const loadPartners = async () => {
//   const res = await axios.get(`${BASE_URL}/partner`);
//   return res.data;
// }



/***************************************************/
/*******ParentCategoryId and ChildCategoryId *******/
/***************************************************/
export const loadProductsByParentSubCategoryId = async (parentSubId) => {
  const res = await axios.get(
    `${BASE_URL}/parent-subcategory/${parentSubId}/products`
  );
  // console.log(res)
  let combinedList = [];
  res.data.childSubCategory.forEach((item) => {
    combinedList = combinedList.concat(item.products);
  });
  return {
    list: combinedList,
    name: res.data.name,
    nameAz: res.data.pendingParentSubcategoryOne,
    nameRu: res.data.pendingParentSubcategoryTwo,
    nameEn: res.data.pendingParentSubcategoryThree,
  };
};
export const loadChildProductsById = async (childSubtId) => {
  const res = await axios.get(
    `${BASE_URL}/child-subcategory/${childSubtId}/products`
  );
  // console.log(res)
  return {
    list: res.data.products,
    name: res.data.name,
    nameAz: res.data.pendingChildSubcategoryOne,
    nameRu: res.data.pendingChildSubcategoryTwo,
    nameEn: res.data.pendingChildSubcategoryThree,
  };
};
// https://admin.barkodelectronics.az/V1.0/child-subcategory/${childSubtId}/products

export const loadSingleProductById = async (productId) => {
  const res = await axios.get(`${BASE_URL}/products/${productId}`);

  return { ...res.data, count: 1 };
};

/***************************************************/
/**************** Offers Services  *****************/
/***************************************************/

export const loadOfferOfWeekProducts = async (productId) => {
  const res = await axios.get(`${BASE_URL}/products/offersOfWeek`);
  // console.log(res.data)
  return res.data;
};
export const loadOfferOfSeasonProducts = async (productId) => {
  const res = await axios.get(`${BASE_URL}/products/offersOfSeason`);
  // console.log(res.data)
  return res.data;
};
export const loadDiscountProducts = async (productId) => {
  const res = await axios.get(`${BASE_URL}/products/productDiscount`);
  return res.data;
};
/***************************************************/
/**************** Payment Type  ********************/
/***************************************************/

export const loadSpecifications = async (productId) => {
  const res = await axios.get(
    `${BASE_URL}/products/${productId}/specifiactions`
  );
  // console.log(res)
  return res.data;
};

/***************************************************/
/**************** Payment Type  ********************/
/***************************************************/

export const loadPaymentTypes = async () => {
  const res = await axios.get(`${BASE_URL}/paymentType`);
  return res.data;
};

export const executePurchase = async (paymentItem) => {
  const res = await axios.post(`${BASE_URL}/pay-by-cash`, paymentItem);
  return res;
};

export const sendPurchaseId = async (purchaseId) => {
  const res = await axios.post(
    `${BASE_URL}/pay-by-cash/sendMail/${purchaseId}`
  );
  // console.log(res);
  return res;
};

export const executeLoanPurchase = async (paymentItem) => {
  const res = await axios.post(`${BASE_URL}/pay-by-credit`, paymentItem);
  // console.log(res)
  return res;
};

export const sendLoanPurchaseId = async (purchaseId) => {
  const res = await axios.post(
    `${BASE_URL}/pay-by-credit/sendMail/${purchaseId}`
  );
  // console.log(res);
  return res;
};

/***************************************************/
/**************** Load Branches  *******************/
/***************************************************/

export const loadBranches = async () => {
  const res = await axios.get(`${BASE_URL}/branch`);
  return res.data;
};

/***************************************************/
/******************** Search   *********************/
/***************************************************/

export const sarchByKey = async (key) => {
  const res = await axios.get(`${BASE_URL}/products/name/${key}`);
  // console.log(res);
  return res.data;
};

/***************************************************/
/******************* About Services ****************/
/***************************************************/

export const loadAbout = async () => {
  const res = await axios.get(`${BASE_URL}/about`);
  return res.data[0];
};

export const updateAbout = async (aboutId, aboutItem) => {
  const res = await axios.put(`${BASE_URL}/about/${aboutId}`, aboutItem);
  return res;
};

/***************************************************/
/******************* FAQ Services ******************/
/***************************************************/

export const loadFAQList = async () => {
  const res = await axios.get(`${BASE_URL}/QAController`);
  return res.data;
};

/***************************************************/
/******************* Contact Services **************/
/***************************************************/

export const personalFormSend = async (formItem) => {
  try {
    const res = await axios.post(`${BASE_URL}/contact`, formItem);
    return res;
  } catch (err) {
    throw err;
  }
};
export const personalFormIdSend = async (mailId) => {
  try {
    const res = await axios.post(`${BASE_URL}/contact/sendMail/${mailId}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const corporativeFormSend = async (formItem) => {
  try {
    const res = await axios.post(`${BASE_URL}/corporate`, formItem);
    return res;
  } catch (err) {
    throw err;
  }
};
export const corporativeFormIdSend = async (mailId) => {
  try {
    const res = await axios.post(`${BASE_URL}/corporate/sendMail/${mailId}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const corporativeFileSend = async (corporateId, corpoFile) => {
  // try{
  const res = await axios.post(`${BASE_URL}/corporateFile`, corpoFile, {
    params: {
      corporateId: corporateId,
    },
  });
  return res;
  // }catch(err){
  //     throw err;
  // }
  // console.log(corporateId)
  // console.log(res)
  // return res;
};

/***************************************************/
/******************* Clicker Services **************/
/***************************************************/

export const clickcerIncreaser = async (clickerObj) => {
  const res = await axios.post(`${BASE_URL}/clicker-counter`, clickerObj);
  try {
    if (res.status === 200 || res.status === 201) {
      return res;
    }
  } catch (err) {
    throw err;
  }
};

/*Check Product with relatedBaseId*/

export const checkProductWithRelatedBaseId = async (id) => {
  const res = await axios.get(
    `https://integration.barkodelectronics.az/product/${id}`
  );
  // console.log(res);
  if (res?.status === 200 || res?.status === 201) return true;
  return false;
};
