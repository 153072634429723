import React, { useState, useEffect } from "react";

import { Box, Typography, Grid, Breadcrumbs } from "@mui/material";

import AboutLogo from "../assets/img/barkod.png";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  loadAbout,
  loadFAQList,
  clickcerIncreaser,
} from "../services/services";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../contexts/LanguageContext";
import { Link } from "react-router-dom";

import First from "../assets/img/about/first.png";
import Second from "../assets/img/about/second.png";

const BannerNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <ArrowForwardIosIcon
      className={className}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

const BannerPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <ArrowBackIosNewIcon
      className={className}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

const About = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const [aboutState, setAboutStaet] = useState(null);
  const [faqList, setFaqList] = useState(null);

  const usSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow:<BannerNextArrow/>,
    // prevArrow:<BannerPrevArrow />,
  };

  const workersSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 913,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 821,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 414,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    // nextArrow:<BannerNextArrow/>,
    // prevArrow:<BannerPrevArrow />,
  };

  const partnersSettings = {
    dots: false,
    infinite: aboutState?.partnersImageEntities?.length > 8 ? true : false,
    arrows: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      // {
      //     breakpoint: 1281,
      //     settings: {
      //         slidesToShow: 4,
      //     }
      // },
      // {
      //     breakpoint: 1025,
      //     settings: {
      //       slidesToShow: 3,
      //     }
      // },
      // {
      //     breakpoint: 913,
      //     settings: {
      //       slidesToShow: 3,
      //     }
      // },
      {
        breakpoint: 821,
        settings: {
          slidesToShow: 6,
          infinite:
            aboutState?.partnersImageEntities?.length > 8 ? true : false,
        },
      },
      // {
      //     breakpoint: 768,
      //     settings: {
      //       slidesToShow: 2,
      //     }
      // },
      // {
      //     breakpoint: 414,
      //     settings: {
      //       slidesToShow: 1,
      //     }
      // },
    ],
    nextArrow: <BannerNextArrow />,
    prevArrow: <BannerPrevArrow />,
  };

  useEffect(() => {
    (async () => {
      setAboutStaet(await loadAbout());
      // console.log(await loadAbout())
      setFaqList(await loadFAQList());
    })();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    return () => {
      (async () => {
        await clickcerIncreaser({
          aboutClicker: true,
          basketClicker: false,
          branchClicker: false,
          contactClicker: false,
          counter: 0,
          discountClicker: false,
          facebookClicker: false,
          homeClicker: false,
          instagramClicker: false,
          pendingClickerFour: false,
          pendingClickerOne: false,
          pendingClickerThree: false,
          pendingClickerTwo: false,
          productClicker: false,
          seasonClicker: false,
          weekClicker: false,
        });
      })();
    };
  }, []);

  return (
    // <>
    //   {/* <Box className="aboutFirstStage">
    //     <Box>
    //       <span className="flex justify-center items-center">{t("About")}</span>
    //     </Box>
    //     <Grid
    //       container
    //       className="aboutContent"
    //       sx={{
    //         marginTop: "50px",
    //       }}
    //     >
    //       <Grid item md={6} className="firstStageLeft">
    //         <Box
    //           className="aboutTextArea"
    //           sx={{
    //             padding: "0px 116.5px",
    //             paddingRight: "50px",
    //           }}
    //         >
    //           <Typography
    //             className="aboutText"
    //             sx={{
    //               fontSize: "17.5px",
    //               color: "#2C3333",
    //               letterSpacing: "0px",
    //               lineHeight: "25px",
    //               // padding:'0px 37.5px',
    //             }}
    //           >
    //             {aboutState !== null && aboutState
    //               ? `${
    //                   language == "az" && aboutState.pendingAboutTwo!=="string"
    //                     ? aboutState.pendingAboutTwo
    //                     : language == "ru" && aboutState.pendingAboutThree!=="string"
    //                     ? aboutState.pendingAboutThree
    //                     : language == "en" && aboutState.pendingAboutFour!=="string"
    //                     ? aboutState.pendingAboutFour
    //                     : aboutState.text
    //                 }`
    //               : "Haqqımızdaaaa"}
    //           </Typography>
    //         </Box>
    //       </Grid>
    //       <Grid item md={6} className="firstStageRight">
    //         <Box
    //           className="aboutLogoArea"
    //           sx={{
    //             // marginRight:'15px',
    //             maxHeight: "400px",
    //             height: "400px",
    //             marginRight: " 116.5px",

    //             // '& .slick-slider':{

    //             // },
    //             "& .slick-slider": {
    //               height: "100%",

    //               "& .slick-list": {
    //                 border: ".5px solid #8e8e8e6b !important",
    //                 borderRadius: "8px",
    //                 // padding:'0px 15px',

    //                 overflow: "hidden",
    //                 boxShadow: " 0px 0px 19px -14px #000 !important",
    //                 height: "calc(100% - 30px)",

    //                 "& .slick-track": {
    //                   height: "100%",
    //                   "& .slick-slide *": {
    //                     height: "100%",
    //                   },
    //                 },
    //               },
    //               "& .slick-dots ": {
    //                 bottom: "0px",
    //                 height: "30px !important",
    //               },
    //               "& .slick-dots li button:before": {
    //                 fontSize: "10px",
    //                 lineHeight: "19px",
    //               },
    //               "& .slick-dots li.slick-active button:before": {
    //                 // fontSize:'10px',
    //                 opacity: "1",
    //                 color: "#ed1c22",
    //                 border: "2px solid #ed1c22",
    //                 borderRadius: "50%",
    //               },
    //             },
    //             "& *": {
    //               height: "100%",
    //             },
    //           }}
    //         >
    //           <Slider {...usSettings}>
    //             {aboutState !== null && aboutState
    //               ? aboutState.companyImageEntities.length !== 0
    //                 ? aboutState.companyImageEntities.map((item, i) => (
    //                     <div key={i}>
    //                       <img
    //                         className="aboutLogo"
    //                         src={item.companyImageSource}
    //                         alt=""
    //                         style={{
    //                           width: "100%",
    //                           // padding:'150px 125px',
    //                         }}
    //                       />
    //                     </div>
    //                   ))
    //                 : ""
    //               : ""}
    //           </Slider>
    //         </Box>
    //       </Grid>
    //     </Grid>
    //   </Box>
    //   <Box
    //     className="secStage"
    //     sx={{
    //       padding: "0px 116.5px",
    //       paddingRight: "50px",
    //     }}
    //   >
    //     <Box>
    //       <Typography className="pageHeading">
    //         {t("Tez-Tez Verilən Suallar")}
    //       </Typography>
    //     </Box>
    //     <Grid container>
    //       <Grid item md={6}>
    //         {faqList !== null
    //           ? faqList.map((item, i) => (
    //               <Accordion key={i}>
    //                 <AccordionSummary
    //                   expandIcon={<ExpandMoreIcon />}
    //                   aria-controls="panel1a-content"
    //                   id="panel1a-header"
    //                 >
    //                   <Typography>{item.questionText}</Typography>
    //                 </AccordionSummary>
    //                 <hr />
    //                 <AccordionDetails>
    //                   <Typography>
    //                     {item.answerResponseDto.answerText}
    //                   </Typography>
    //                 </AccordionDetails>
    //               </Accordion>
    //             ))
    //           : ""}
    //       </Grid>
    //     </Grid>
    //   </Box>
    //   {/* <Box>
    //             <Box>
    //                 <Typography className="pageHeading" sx={{marginTop:'125px !important',marginBottom:'35px !important'}}>
    //                     Komanda
    //                 </Typography>
    //             </Box>
    //             <Box
    //                 sx={{
    //                     '& .slick-slide':{
    //                         padding:'0px 20px',
    //                     },
    //                     '& .slick-dots ':{
    //                         // bottom:'0px',
    //                         marginTop:'20px',
    //                         height:'0px !important',
    //                     },
    //                     '& .slick-dots li button:before':{
    //                         fontSize:'10px',
    //                         lineHeight:'19px',
    //                     },
    //                     '& .slick-dots li.slick-active button:before':{
    //                         // fontSize:'10px',
    //                         opacity: '1',
    //                         color: '#ed1c22',
    //                         border: '2px solid #ed1c22',
    //                         borderRadius: '50%',
    //                     },
    //                 }}
    //             >
    //                  <Slider {...workersSettings}>
    //                     {
    //                         aboutState !==null && aboutState ? 
    //                             aboutState.workersImageEntities.length !== 0?

    //                                 aboutState.workersImageEntities.map((item,i)=>(
    //                                     <div key={i}>
    //                                         <Box
    //                                             sx={{
    //                                                 border:'.5px solid #8e8e8e6b',
    //                                                 boxShadow:' 0px 0px 19px -14px #000',
    //                                                 overflow:'hidden',
    //                                                 borderRadius:'8px',
    //                                                 display: 'flex',
    //                                                 flexDirection: 'column',
    //                                                 justifyContent: 'space-between',
    //                                                 transitopn:'all .2s ease',
    //                                                 transform:'scale(1)',
    //                                                 '&:hover':{
    //                                                     // boxShadow:'0px 0px -17px 10px black',
    //                                                     // boxShadow: '0px 0px 33px -15px black',

    //                                                     // transform: 'scale(1.01)',   
    //                                                     // zIndex: '999',
    //                                                         // transform:'scale(1.1)'
    //                                                 },
    //                                             }}
    //                                         >
    //                                             <Box
    //                                                 sx={{
    //                                                     maxHeight:'350px',
    //                                                     height:'350px',
    //                                                     overflow:'hidden',

    //                                                     '& img':{
    //                                                         height:'100%'
    //                                                     },
    //                                                 }}
    //                                             >
    //                                                 <img 
    //                                                     className='aboutLogo'
    //                                                     src={item.workersImageSource} 
    //                                                     alt="" 
    //                                                     style={{
    //                                                         width:'100%',
    //                                                         // padding:'150px 125px',
    //                                                     }}
    //                                                 />
    //                                             </Box>
    //                                             <Box
    //                                                 sx={{
    //                                                     padding:'5px 10px',
    //                                                 }}
    //                                             >
    //                                                 <span
    //                                                     style={{
    //                                                         fontSize:'23px',

    //                                                     }}
    //                                                 >
    //                                                     Əhməd Cəlalov
    //                                                 </span>
    //                                                 <br />
    //                                                 <span
    //                                                     style={{color:'#8e8e8e'}}
    //                                                 >
    //                                                     Müdür müavini
    //                                                 </span>
    //                                             </Box>
    //                                         </Box>
    //                                     </div>
    //                                 ))
    //                             :''

    //                         :''
    //                     }

    //                 </Slider>
    //             </Box>
    //         </Box> */}
    //   <Box sx={{}}>
    //     <Box>
    //       <Typography
    //         className="pageHeading"
    //         sx={{
    //           marginTop: "125px !important",
    //           marginBottom: "35px !important",
    //         }}
    //       >
    //         {t("Partnyorlar")}
    //       </Typography>
    //     </Box>
    //     <Box
    //       sx={{
    //         // borderBottom:'1px solid #8e8e8e',
    //         // borderTop:'1px solid #8e8e8e',
    //         // height: '140px',
    //         boxShadow: "0px 0px 20px -10px #000",
    //         padding: "70px 0px",
    //         "& .slick-slider": {},
    //         "& .slick-list": {
    //           margin: "0px 35px",
    //         },
    //         "& .slick-arrow": {
    //           color: "#8e8e8e",
    //           zIndex: " 99999999",
    //           height: "100%",
    //           fontSize: "32px",
    //           "&.slick-prev": {
    //             left: "0%",
    //           },
    //           "&.slick-next": {
    //             right: "0%",
    //           },
    //         },
    //       }}
    //     >
    //       <Slider {...partnersSettings}>
    //         {aboutState !== null && aboutState
    //           ? aboutState.partnersImageEntities.length !== 0
    //             ? aboutState.partnersImageEntities.map((item, i) => (
    //               <div key={i} sx={{ height: "70px" }}>
    //                 <img
    //                   className="aboutLogo"
    //                   src={item.partnersImageSource}
    //                   alt=""
    //                   style={{
    //                     width: "100%",
    //                     // height:'40px',
    //                     padding: "0px 15px",
    //                     // padding:'150px 125px',
    //                   }}
    //                 />
    //               </div>
    //             ))
    //             : ""
    //           : ""}
    //       </Slider>
    //     </Box>
    //   </Box> */}
    // </>
    <div className="px-[40px] mb-[80px]">
      <div>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '80px', }}>
          <Link underline="hover" color="inherit" to="/">
            Əsas səhifə
          </Link>
          <span className="font-bold text-black">Haqqımızda</span>
        </Breadcrumbs>
      </div>
      <div className="flex">
        <div className="w-50">
          <div className="flex gap-3 mb-[50px]">
            <div className="bg-red-600 w-3 h-3"></div>
            <div className="flex w-100">
              <span className="font-semibold w-75">Azərbaycanda fəaliyyət göstərən Barkod Electronics müxtəlif markalı rəqəmsal, məişət texnikasının və elektronika məhsullarının topdan, korporativ və pərakəndə satışını həyata keçirir.</span>
            </div>
          </div>
          <div className="flex gap-3 mb-[50px]">
            <div className="bg-red-600 w-3 h-3"></div>
            <div className="flex w-100">
              <span className="font-semibold w-75">Barkod Electronics 2021-ci ildə fəaliyyətə başlayıb, lakin erkən tarixə baxmayaraq, Azərbaycan regionunda 8 mağazamız var. 2024-cü ilə qədər həm paytaxtda, həm də ölkənin regionlarında 10 mağaza açmağı planlaşdırırıq.</span>
            </div>
          </div>
          <div className="flex gap-3 mb-[50px]">
            <div className="bg-red-600 w-3 h-3"></div>
            <div className="flex w-100">
              <span className="font-semibold w-75">İlk mağazamız Siyəzəndə fəaliyyətə başlayaraq, Azərbaycanın bir çox bölgələrində böyüməyə başlamışıq. Hal-hazırda filiallarımız bir çox bölgədə fəaliyyət göstərir : Siyəzən, Tovuz, İsmayıllı, Qazax, Zaqatala, Xudat, Xaçmaz və Bərdə.</span>
            </div>
          </div>
          <div className="flex gap-3 mb-[50px]">
            <div className="bg-red-600 w-3 h-3"></div>
            <div className="flex w-100">
              <span className="font-extrabold text-xl w-75">Hədəf olan növbəti satış məntəqələrimiz:</span>
            </div>
          </div>
          <div className="flex gap-3 mb-[50px]">
            <div className="bg-red-600 w-3 h-3"></div>
            <div className="flex w-100">
              <span className="font-semibold w-75">Müştərilərimizin ehtiyaclarından bir addım öndə olmaq üçün texnologiya, logistika və innovasiyalardan istifadə etməyi hədəfləyirik. Həm xarici təchizatçıların, həm də kiçik yerli müəssisələrin məhsullarını birləşdirərək, biz Azərbaycanın ən ucqar yaşayış məntəqəsindən paytaxta qədər, yaşayış yerindən asılı olmayaraq, bütün insanların həyatını yaxşılaşdırmaq və imkanlar yaratmaq üçün çalışırıq.</span>
            </div>
          </div>
          <div className="flex gap-3 mb-[50px]">
            <div className="bg-red-600 w-3 h-3"></div>
            <div className="flex w-100">
              <span className="font-semibold w-75">Bir Barkod ailəsi olaraq biz dünyaca məşhur olan brendlərin məhsullarını sizə daha yaxın etdik. Apple, Samsung, Honor, Xiaomi, iFFALCON, Oppo, Realme, Bosch, LG, Sony, Taube, HP, Lenovo, Dell və s. kimi brendərin satışı ilə məşguldur. Brend səhifəmiz durmadan artır odur ki, istədiyin dünya brendlərinin məhsullarını rahatlıqla “Barkod” filiallarında və online saytdan izləyə bilərsiz.</span>
            </div>
          </div>
        </div>
        <div className="w-50">
          <div className="flex justify-center">
            <img src={First} alt="" />
          </div>
          <div>
            <img src={Second} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
