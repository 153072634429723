import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Collapse,
  ListItemButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { loadCategoryList } from "../services/services";

import MobileMenuSubParentItem from "./MobileMenuSubParentItem";

import HeaderBasketButton from "../components/HeaderBasketButton";
import { useLanguage } from "../contexts/LanguageContext";

const MobileMenu = ({ isOpen, setMobileMenuIsOpen }) => {
  const { language, setLanguage } = useLanguage();
  const [categoryList, setCategoryList] = useState(null);

  const [menuItemIsOpen, setMenuItemIsOpen] = useState({});

  const onMenuItemOpenHandler = (e) => {
    let tempMenuState = {};
    Object.keys(menuItemIsOpen).forEach((menuKey) => {
      tempMenuState[menuKey] = false;
    });
    setMenuItemIsOpen({
      ...tempMenuState,
      [e.target.closest("[data-name]").dataset.name]:
        !menuItemIsOpen[e.target.closest("[data-name]").dataset.name],
    });
  };

  useEffect(() => {
    (async () => {
      let menuList = await loadCategoryList();
      setCategoryList(await loadCategoryList());
      menuList.forEach((item) => {
        setMenuItemIsOpen({
          ...menuItemIsOpen,
          ["menuItem" + item.id]: false,
        });
      });
    })();
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",

        left: isOpen ? "0%" : "-100%",
        transition: "all .2s ease",
        top: "0%",

        width: "100%",
        zIndex: "99999999",
        height: "100vh",
        display: "flex",
      }}
      // onClick={mobileMenuCloseHandlerByAttr}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          width: "75%",
          zIndex: "99999999",
          transition: "all 1s ease",
          height: "100vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            "& svg": {
              fontSize: "32px",
              color: "#2C3333",
              position: "sticky",
              top: 0,
            },
          }}
        >
          <IconButton onClick={() => setMobileMenuIsOpen(false)}>
            <CloseIcon size="small" />
          </IconButton>
        </Box>
        <List>
          {categoryList !== null
            ? categoryList.map((item, i) => (
                <MobileMenuSubParentItem
                  key={i}
                  item={item}
                  setMobileMenuIsOpen={setMobileMenuIsOpen}
                  menuItemIsOpen={menuItemIsOpen}
                  onMenuItemOpenHandler={onMenuItemOpenHandler}
                />
              ))
            : ""}
          <ListItem>
            <HeaderBasketButton />
          </ListItem>
          <ListItem>
            <select
              style={{
                zIndex: "9999999999",
                outline: "none",
                border: "none",
                fontSize: "16px",
              }}
              value={language}
              onChange={(e) => {
                setLanguage(e.target.value);
              }}
            >
              <option value="en">EN</option>
              <option value="ru">RU</option>
              <option value="az">AZ</option>
            </select>
          </ListItem>
        </List>
      </Box>
      <Box
        className="menuCloseArea"
        sx={{
          backgroundColor: isOpen ? "#000c" : "transparent",
          width: "25%",
          height: "100vh",
          zIndex: "999999",
        }}
        onClick={() => setMobileMenuIsOpen(false)}
      ></Box>
    </Box>
  );
};

export default MobileMenu;
