import React from 'react';

import {
    Box,
    Card,
    CardBody,
    Typography,
    Grid,
    FormControl,
    TextField,
    IconButton,
    Button,

}from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import {connect} from 'react-redux';

import {buyWithCashAction} from '../redux/actions/buyWIthCashAction';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const BuyWithCashgPopUp = ({buyWithCashAction}) => {
    // console.log(props)
    const {t} = useTranslation()

    const closePopUp=()=>{
        buyWithCashAction(null);
    }
    return (
        <>
            <Box
                sx={{
                    width:'100%',
                    top:'0%',
                    left:'0%',
                    height:'100%',
                    position:'fixed',
                    backgroundColor:'#0000008c',
                    zIndex:'999999',
                }}

            >

            <Box
                sx={{
                    padding:'10px',
                    borderRadius:'8px',
                    border:'.5px solid lightgrey',
                    position:'fixed',
                    left:'50%',
                    top:'50%',
                    transform:'translate(-50%,-50%)',
                    zIndex:'99999',
                    backgroundColor:'#fff',
                }}
            >
            <Box
                sx={{
                    display:'flex',
                    justifyContent:'right',
                }}
            >
                <IconButton
                    onClick={closePopUp}
                >
                    <CloseIcon
                    />
                </IconButton>
            </Box>
            <Box
                sx={{
                    padding:'10px 10px'
                }}
            >
                <form>
                    <Grid container>
                        <Grid item md={6}>
                            <FormControl sx={{minWidth:'230px',width:'100%',paddingRight:'10px',marginBottom:'20px',}}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={t("Ad")}

                                />
                            </FormControl>  
                        </Grid>
                        <Grid item md={6}>
                            <FormControl sx={{minWidth:'230px',width:'100%',paddingLeft:'10px',marginBottom:'20px',}}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={t("Soyad")}
                                />
                            </FormControl>  
                        </Grid>
                        <Grid item md={6}>
                            <FormControl sx={{minWidth:'230px',width:'100%',paddingRight:'10px',marginBottom:'20px'}}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    type="number"
                                    label={t("Telefon")}
                                />
                            </FormControl>  
                        </Grid>
                        <Grid item md={6}>
                            <FormControl sx={{minWidth:'230px',width:'100%',paddingLeft:'10px',marginBottom:'20px'}}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    type="text"
                                    label={t("Fin Kod")}
                                />
                            </FormControl>  
                        </Grid>
                        <Grid item md={12}>
                            <Box
                                sx={{
                                    minWidth:'500px',
                                    display:'flex',
                                    justifyContent:'right',
                                }}
                            >
                            <Button
                                sx={{
                                    textTransform:'capitalize',
                                }}
                                variant="contained"
                            >
                                {t("Təstiq Et")}
                            </Button>
                            </Box>
                        </Grid>
                    </Grid> 
                </form>
            </Box>
                
            </Box>
            </Box>
        </>
    );
}

const mapStateToProps=state=>state
const mapDispatchToProps={
    buyWithCashAction,
}

export default connect(mapStateToProps,mapDispatchToProps)(BuyWithCashgPopUp);
