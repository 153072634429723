import React, { useState, useRef, useEffect } from 'react';

import {
    Box,
    Button,
    Grid,
    Typography,
    List,
    ListItem,
    Slider as RangeSlider,
    IconButton,
    Breadcrumbs,
} from '@mui/material';

import Slider from "react-slick";


import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';




import CreditCardIcon from '@mui/icons-material/CreditCard';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import PaymentsIcon from '@mui/icons-material/Payments';

// import ProductSlider from '../components/ProductSlider';

import { connect } from 'react-redux';


import { Link, useFetcher, useParams } from 'react-router-dom';

import {
    loadSingleProductById,
    loadPaymentTypes,

    loadOfferOfWeekProducts,
    loadOfferOfSeasonProducts,
    loadDiscountProducts,

    loadSpecifications,
    checkProductWithRelatedBaseId,
} from '../services/services';


import PurchasePopUp from '../components/PurchasePopUp';
import LoanPurchasePopUp from '../components/LoanPurchasePopUp';

import ProductSlider from '../components/ProductSlider';

import {
    FaMoneyBillWaveAlt,
    FaShoppingBasket,
    FaCreditCard
} from 'react-icons/fa';
import { GiClick } from "react-icons/gi";

import { basketAddAction } from '../redux/actions/basketAction';

import Storage from '../redux/Storage';

import BasketAddedSuccess from '../components/BasketAddedSuccess';
// import BasketAddedError from '../components/BasketAddedError';
import BasketAddedInfo from '../components/BaskertAddedInfo';

import PaymentSuccess from '../components/PaymentSuccess';
import PaymentError from '../components/PaymentError';
import { ConstructionSharp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { use } from 'i18next';
import { red } from '@mui/material/colors';

const Details = ({ basketAddAction, basketReducer, ...props }) => {
    const { t } = useTranslation()
    const [successPopUp, setSuccessPopUp] = useState(false);
    const [infoPopUp, setInfoPopUp] = useState(false);
    const [errorPopUp, setErrorPopUp] = useState(false);


    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentError, setPaymentError] = useState(false);

    // console.log(props)
    const addItemBasketHandler = () => {
        if (!Storage._itemIsInList(productItem)) {
            basketAddAction(productItem)
            Storage._addItemStorage(productItem)
            setSuccessPopUp(true)
        } else {
            setInfoPopUp(true)
        }
    }
    // console.log(basketReducer.basketItems)

    const [discountSliderList, setDiscountSlderList] = useState(null);
    const [offerOfWeekSliderList, seOfferOfWeekSliderList] = useState(null);
    const [offerOfSeasonSliderList, offerOfSeasonSlderList] = useState(null);

    const [specificationList, setSpecificationList] = useState(null);

    const [sliderState, setSliderState] = useState({
        nav1: null,
        nav2: null,
    })

    const [paymentTypeList, setPaymentTypeList] = useState(null);

    const [productItem, setProductItem] = useState(null);

    const [isOpenPurchasePopUp, setIsOpenPurchasePopUp] = useState(false)

    const [isOpenLoanPurchasePopUp, setIsOpenLoanPurchasePopUp] = useState(false)

    // const [typeOfPurchase,setTypeOfPurchase] = useState('');

    const { id } = useParams();
    const [isProductInHold, setProductIsHold] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        (async () => {
            const productItem = await loadSingleProductById(id)
            setSpecificationList(await loadSpecifications(id))

            // console.log(await loadSingleProductById(id))
            // console.log(productItem.productInterest)
            setProductItem(productItem);
            const testMarkVar = productItem.productInterest.map((item, i) => {
                // console.log(item)
                if (i === 0) {
                    return {
                        label: item.month,
                        value: 0,
                    }
                } else {
                    return {
                        label: item.month,
                        value: (100 / (productItem.productInterest.length - 1)) * i,
                    }
                }
            })
            // console.log(testMarkVar)
            setMarks(testMarkVar)
            const res = await checkProductWithRelatedBaseId(productItem.relatedBaseId);
            // if(res?.status===200){
            setProductIsHold(res);
            // console.log(res);
            // }else{
            // console.log(res);
            setProductIsHold(res)
            // }

            setPaymentTypeList(await loadPaymentTypes());
            // console.log(productItem)


            // console.log(productItem.productInterest)
            // console.log(testMarkVar)            

            setDiscountSlderList(await loadDiscountProducts());
            seOfferOfWeekSliderList(await loadOfferOfWeekProducts());
            offerOfSeasonSlderList(await loadOfferOfSeasonProducts());

            // if(productItem.productDiscountPrice ===0 || productItem.productDiscountPrice===''){
            //     setCalculatedPrice(Math.ceil((((productItem.price/100)*loanDates['0'].percentage)+productItem.price)/loanDates['0'].time))
            // }else{
            //     setCalculatedPrice(Math.ceil((((productItem.productDiscountPrice/100)*loanDates['0'].percentage)+productItem.productDiscountPrice)/loanDates['0'].time))
            // }
        })();
    }, [id])
    const [marks, setMarks] = useState(null);

    const [calculatedPrice, setCalculatedPrice] = useState(0);

    // const [loanDates,setLoanDates] = useState({
    //     '0':{
    //         type:'1-3',
    //         time:3,
    //         percentage:10,
    //     },
    //     '50':{
    //         type:'4-6',
    //         time:6,
    //         percentage:13,
    //     },
    //     '100':{
    //         type:'7-12',
    //         time:12,
    //         percentage:20,
    //     },


    // }) 
    // const [loanDatesForOther,setLoanDatesForOther] = useState({
    //                 '0':{
    //                     type:'1-3',
    //                     time:3,
    //                     percentage:10,
    //                 },
    //                 '25':{
    //                     type:'4-6',
    //                     time:6,
    //                     percentage:13,
    //                 },
    //                 '50':{
    //                     type:'7-12',
    //                     time:12,
    //                     percentage:20,
    //                 },
    //                 '75':{
    //                     type:'13-18',
    //                     time:18,
    //                     percentage:25,
    //                 },
    //                 '100':{
    //                     type:'19-24',
    //                     time:24,
    //                     percentage:30,
    //                 },
    //             })



    // const testChangeHandler = (e) => {
    //     if(productItem.productType==='Telefon' || productItem.productType==='Planşet'){

    //         if(productItem.productDiscountPrice===0 || productItem.productDiscountPrice===''){
    //             setCalculatedPrice(Math.ceil((((productItem.price/100)*loanDates[e.target.value].percentage)+productItem.price)/loanDates[e.target.value].time))
    //         }else{
    //             setCalculatedPrice(Math.ceil((((productItem.productDiscountPrice/100)*loanDates[e.target.value].percentage)+productItem.productDiscountPrice)/loanDates[e.target.value].time))
    //         }
    //     }else{
    //         if(productItem.productDiscountPrice===0 || productItem.productDiscountPrice===''){
    //             setCalculatedPrice(Math.ceil((((productItem.price/100)*loanDatesForOther[e.target.value].percentage)+productItem.price)/loanDatesForOther[e.target.value].time))
    //         }else{
    //             setCalculatedPrice(Math.ceil((((productItem.productDiscountPrice/100)*loanDatesForOther[e.target.value].percentage)+productItem.productDiscountPrice)/loanDatesForOther[e.target.value].time))
    //         }

    //     }
    // }

    function valueLabelFormat(value) {
        const currentLabel = marks.find((mark) => mark.value === value);
        setCalculatedPrice(productItem.productInterest[marks.indexOf(currentLabel)].amount);
        return `${t("Aylıq")} ${productItem.productInterest[marks.indexOf(currentLabel)].amount} ₼`
    }

    

    const purchasePopUpOpenHandler = () => {
        if (productItem !== null) {
            setIsOpenPurchasePopUp(true);
        }
        if (!isOpenPurchasePopUp) {
            document.body.style.overflow = 'hidden';
        }
    }
    const purchasePopUpCloseHandler = () => {
        setIsOpenPurchasePopUp(false);
        document.body.style.overflow = 'auto';
    }


    const loadPurchasePopUpOpenHandler = () => {
        if (productItem !== null) {
            setIsOpenLoanPurchasePopUp(true);
        }
        if (!isOpenLoanPurchasePopUp) {
            document.body.style.overflow = 'hidden';
        } 
    }
    
    const loanPurchasePopUpCloseHandler = () => {
        setIsOpenLoanPurchasePopUp(false);
        document.body.style.overflow = 'auto';

    }


    const BannerPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <KeyboardArrowUpIcon
                className={className}
                style={{ ...style, }}
                onClick={onClick}
            />
        )
    }



    const BannerNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <KeyboardArrowDownIcon
                className={className}
                style={{ ...style, }}
                onClick={onClick}
            />
        )
    }

    const [limit, setLimit] = useState(7); // Initial limit set to 3
    const [readMore, setReadMore] = useState(false);

    const handleReadMoreClick = () => {
        setReadMore(!readMore);
        setLimit(readMore ? 7 : specificationList.length);
    };


    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        // cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const multipleSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        vertical: true,
        arrows: false,
        verticalSwiping: true,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 965,
                settings: {
                    slidesToShow: 4,
                    vertical: false,
                    verticalSwiping: false,
                    swipeToSlide: false,
                }
            }
        ]
    };

    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     arrows: false,
    //     speed: 500,
    //     autoplay: true,
    //     autoplaySpeed: 1500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     nextArrow: <BannerNextArrow />,
    //     prevArrow: <BannerPrevArrow />,
    //     responsive: [
    //         {
    //             breakpoint: 414,
    //             settings: {
    //                 arrows: true,
    //                 // slidesToShow: 5,
    //                 // slidesToScroll: 1,
    //                 // infinite: true,
    //                 // dots: true
    //             }
    //         },
    //     ]
    // };


    let slider1Ref = useRef();
    let slider2Ref = useRef();

    // const tumbnailSettings = {
    //     vertical: false,
    //     infinite: false,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     swipeToSlide: true,
    //     slidesToShow: 4,
    //     focusOnSelect: true,
    //     nextArrow: <BannerNextArrow />,
    //     prevArrow: <BannerPrevArrow />,
    //     responsive: [
    //         {
    //             // breakpoint: 444,
    //             // settings: {
    //             //     vertical: false,
    //             //     slidesToShow: 4,

    //             // },
    //         },

    //     ]
    // };


    useEffect(() => {
        setSliderState({
            nav1: slider1Ref.current,
            nav2: slider2Ref.current,
        })
        // console.log(productItem)
        // alert('aaa')
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        // setCalculatedPrice({
        //     time:3,
        //     percentage:10,
        // })
    }, [productItem])
    useEffect(() => {
        
    }, [])

    const [month, setMonth] = useState(6);
    const [monthlyPrice, setMonthlyPrice] = useState(0)



    const monthlyPriceHandler = () => {
        if (productItem) {
            const price = productItem.productDiscountPrice ? productItem.productDiscountPrice / month : productItem.price / month
            setMonthlyPrice(price)
        }
    }

    const monthHandler = (e) => {
        setMonth(e);
    }

    useEffect(() => {
        monthlyPriceHandler();
    }) 

    useEffect(() => {
        monthlyPriceHandler();

    }, [month, monthlyPrice])


    // const collapseHandler = () => {
    //     const collapse = document.getElementById('collapse');
    //     if (collapse.style.height === '520px') {
    //         collapse.style.height = '100%';
    //     } else {
    //         collapse.style.height = '520px';
    //     }
    // }

    // useEffect(() => {
    //     // collapseHandler();
    // }, [collapseHandler])


    return (
        <div style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}>
            <div style={{
                padding: "0 40px"
            }}
            >
                <Box>
                    {
                        successPopUp ?
                            <BasketAddedSuccess close={setSuccessPopUp} />
                            : ''
                    }
                    {
                        infoPopUp ?
                            <BasketAddedInfo close={setInfoPopUp} />
                            : ''
                    }




                    {
                        isOpenPurchasePopUp ?
                            <PurchasePopUp closePurchaseAction={purchasePopUpCloseHandler} successOpen={setPaymentSuccess} products={[productItem]} />
                            : ''
                    }
                    {
                        isOpenLoanPurchasePopUp ?
                            <LoanPurchasePopUp closePurchaseAction={loanPurchasePopUpCloseHandler} productItem={productItem} products={[productItem]} />
                            : ''
                    }

                    {
                        paymentSuccess ?
                            <PaymentSuccess close={setPaymentSuccess} />
                            : ''
                    }
                    {
                        paymentError ?
                            <PaymentError close={setPaymentError} />
                            : ''
                    }


                    {
                        productItem !== null ?

                            <Box className="detailsPreview" sx={{ padding: '34px 0px 0px 0px' }}>
                                <Box
                                    className="product-details-content"
                                >
                                    <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '80px', }}>
                                        <Link underline="hover" color="inherit" to="/">
                                            Əsas səhifə
                                        </Link>
                                        {/* <Link
                                            underline="hover"
                                            color="inherit"
                                            href="/material-ui/getting-started/installation/"
                                        >
                                        </Link> */}
                                        <Typography color="text.primary">{productItem.name}</Typography>
                                    </Breadcrumbs>
                                    <div className='flow-root md:flex'>
                                        <Grid
                                            className='h-[600px] sm:w-full md:w-[50%] flex flex-wrap gap-3'
                                            item md={5} sm={5} xs={12}
                                        >
                                            <div className='h-[8%] sm:order-2 w-full md:order-1 md:w-[20%] md:h-full'>
                                                <div className="slider-container w-[80%] m-auto h-full flex items-center">
                                                    <Slider {...multipleSettings} className='w-full h-[100%] flex'
                                                        focusOnSelect={(data) => console.log(data)}
                                                        asNavFor={sliderState.nav1}
                                                        ref={slider => (slider2Ref.current = slider)}
                                                    >
                                                        {
                                                            productItem.images.map((item, i) => (
                                                                <div key={i} className='border-[1px] border-[rgba(231, 231, 231, 1)] !mr-2 rounded-lg h-[40px] md:h-[120px]'>
                                                                    <img src={item.source} alt="" className='object-contain w-full h-full md:h-[120px]' />
                                                                </div>
                                                            ))
                                                        }
                                                    </Slider>
                                                </div>

                                            </div>
                                            <div className='h-[530px] sm:order-1 w-full md:order-2 md:w-[75%] md:h-full'>
                                                <div className="slider-container w-full h-[530px] md:h-[590px]">
                                                    <Slider {...settings}

                                                        asNavFor={sliderState.nav2}
                                                        ref={slider => (slider1Ref.current = slider)}
                                                    >
                                                        {
                                                            productItem.images.map((item, i) => (
                                                                <div key={i} className='w-full h-[530px] border-[1px] border-[rgba(195, 195, 195, 1)] rounded-lg md:h-[590px]'>
                                                                    <img src={item.source} alt="" className='object-contain w-full h-[530px] md:h-[590px]' />
                                                                </div>
                                                            ))
                                                        }
                                                    </Slider>
                                                </div>
                                            </div>






                                        </Grid>
                                        <Grid
                                            item md={7} sm={7} xs={12}
                                            className="sm:w-full md:w-[50%]"
                                        >
                                            <ul>
                                                <div className='flex justify-between w-100 items-center'>
                                                    <li>
                                                        {
                                                            !isProductInHold ?
                                                                <li>
                                                                    {isProductInHold}
                                                                    <span style={{ fontSize: '18px', color: 'red' }}>{t("Məhsul anbarda mövcud deyil")}</span>
                                                                </li>
                                                                :
                                                                <li>
                                                                    {isProductInHold}
                                                                    <span style={{ fontSize: '18px', color: 'green' }}>{t("Məhsul mövcuddur")}</span>
                                                                </li>
                                                        }
                                                    </li>
                                                    <li className='paymentDetails'>
                                                        <ul style={{ display: 'flex', alignItems: 'center', }}>
                                                            <li>
                                                                <ul style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <li style={{ borderRadius: '12px', marginRight: '5px', display: 'inline-block', padding: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                                                        <CreditCardIcon sx={{ color: '#2C3333' }} />
                                                                        {/* <span style={{ fontSize: '10px', color: '#395B64' }}>{t("kartla ödəniş")}</span> */}
                                                                    </li>
                                                                    <li style={{ borderRadius: '12px', marginRight: '5px', display: 'inline-block', padding: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                                                        <ShoppingCartCheckoutIcon sx={{ color: '#2C3333' }} />
                                                                        {/* <span style={{ fontSize: '10px', color: '#395B64' }}>{t("qapıda ödəniş")}</span> */}
                                                                    </li>
                                                                    <li style={{ borderRadius: '12px', marginRight: '5px', display: 'inline-block', padding: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                                                        <PaymentsIcon sx={{ color: '#2C3333' }} />
                                                                        {/* <span style={{ fontSize: '10px', color: '#395B64' }}>{t("taksitlə ödəniş")}</span> */}
                                                                    </li>

                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </div>
                                                <div style={{ border: '1px solid gray', opacity: '0.2', margin: '20px 0' }}></div>
                                                <li className='detailsWrapper' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <ul className="detailsArea w-100 flex justify-between">

                                                        <li className='productPrice'>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    lineHeight: '23px',
                                                                    flexDirection: 'column',
                                                                    gap: '20px',
                                                                }}
                                                            >
                                                                <div className='flex gap-[5px]'>

                                                                    {
                                                                        productItem.productDiscount ?
                                                                            <Box
                                                                                sx={{
                                                                                    color: '#ed1c22',
                                                                                    fontSize: '20px',
                                                                                    fontWeight: '600',
                                                                                    border: '1px solid rgba(33, 235, 53, 1)',
                                                                                    padding: '5px 10px',
                                                                                    borderRadius: '4px',
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        lineHeight: '20px',
                                                                                        display: 'inline-block',
                                                                                    }}
                                                                                >

                                                                                    {
                                                                                        productItem.productDiscountPrice ?
                                                                                            -(productItem.price - productItem.productDiscountPrice) + '₼'
                                                                                            : ''
                                                                                    }
                                                                                </span>
                                                                            </Box>
                                                                            : ''
                                                                    }
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            textDecoration: productItem.productDiscount ? 'line-through' : '',
                                                                            color: productItem.productDiscount ? '#bbb' : '#ed1c22',
                                                                            fontSize: 'inherit',
                                                                            fontSize: productItem.productDiscount ? '20px' : '50px',
                                                                            fontWeight: productItem.productDiscount ? '400' : '600',
                                                                            marginRight: '10px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            productItem.price + '₼'
                                                                        }
                                                                    </Box>
                                                                </div>
                                                                {
                                                                    productItem.productDiscount ?
                                                                        <Box
                                                                            sx={{
                                                                                color: '#ed1c22',
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    lineHeight: '18px',
                                                                                    fontSize: '50px',
                                                                                    fontWeight: '600',
                                                                                }}
                                                                            >

                                                                                {
                                                                                    productItem.productDiscountPrice ?
                                                                                        productItem.productDiscountPrice + '₼'
                                                                                        : ''
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                        : ''
                                                                }

                                                            </Box>
                                                        </li>
                                                        {
                                                            isProductInHold ?
                                                                <li style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}>
                                                                    <IconButton
                                                                        onClick={addItemBasketHandler}
                                                                        title="Səbətə əlavə et"
                                                                        sx={{
                                                                            marginRight: '33px',
                                                                            backgroundColor: '#ed1c22',

                                                                            '& svg': {
                                                                                fontSize: '40px',
                                                                                color: '#fff',
                                                                            },
                                                                            '&:hover': {
                                                                                backgroundColor: '#ed1c22',
                                                                                '& svg': {
                                                                                    fontSize: '40px',
                                                                                    color: '#fff',
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        <LocalGroceryStoreOutlinedIcon className="cursor-pointer" />
                                                                    </IconButton>
                                                                </li>
                                                                : ''
                                                        }

                                                    </ul>
                                                    {/* <div className='paymentCardArea'>
                                                        {
                                                            productItem?.campaignPaymentEntities.length !== 0 ?
                                                                productItem.campaignPaymentEntities.map((item, i) => (
                                                                    <Box
                                                                        sx={{
                                                                            marginBottom: '15px',
                                                                            padding: '15px 25px',
                                                                            boxShadow: '0px 0px 32px -15px #000',
                                                                            borderRadius: '7.5px',
                                                                        }}
                                                                        className="paymentCardItem"
                                                                    >

                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            <Box sx={{ marginRight: '10px', }}>
                                                                                <img style={{ maxWidth: '70px', maxHeight: '40px', }} src={item.campaignPaymentImageResponseDto.campaignPaymentImageSource} />
                                                                            </Box>
                                                                            <Typography>
                                                                                {
                                                                                    item.campaignPaymentText
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                ))
                                                                : ''
                                                        }
                                                    </div> */}
                                                </li>
                                                <div style={{ border: '1px solid gray', opacity: '0.2', margin: '30px 0', marginBottom: '40px' }}></div>
                                                <li className="calculation">
                                                  <div className='flex w-full'>
                                                        <div className='w-[60%] flex justify-around gap-2 p-8'>
                                                            {/* {
                                                                marks != null && marks.length > 1 ?
                                                                    <RangeSlider
                                                                        aria-label="Custom marks"
                                                                        defaultValue={0}
                                                                        valueLabelFormat={valueLabelFormat}
                                                                        getAriaLabel={(value) => {
                                                                            return `${value} manat`
                                                                        }}
                                                                        step={100 / (productItem.productInterest.length - 1)}
                                                                        valueLabelDisplay="auto"
                                                                        marks={marks}
                                                                    />
                                                                    : ''
                                                            } */}
                                                            <div className='text-center'>
                                                                <span className='font-light sm:text-[10px] md:text-[17px] text-[#F93131]'>0%</span>
                                                                <div className='sm:w-[28px] md:w-[58px] sm:h-[28px] md:h-[58px] cursor-pointer bg-[#D9D9D9] rounded-[50%] flex justify-center items-center font-normal sm:text-[10px] md:text-[20px]' onClick={() => { monthHandler(6) }}>6ay</div>
                                                            </div>
                                                            <div className='text-center'>
                                                                <span className='font-light sm:text-[10px] md:text-[17px] text-[#F93131]'>0%</span>
                                                                <div className='sm:w-[28px] md:w-[58px] sm:h-[28px] md:h-[58px] cursor-pointer bg-[#D9D9D9] rounded-[50%] flex justify-center items-center font-normal sm:text-[10px] md:text-[20px]' onClick={() => { monthHandler(9) }}>9ay</div>
                                                            </div> 
                                                            <div className='text-center'>
                                                                <span className='font-light sm:text-[10px] md:text-[17px] text-[#F93131]'>0%</span>
                                                                <div className='sm:w-[28px] md:w-[58px] sm:h-[28px] md:h-[58px] cursor-pointer bg-[#D9D9D9] rounded-[50%] flex justify-center items-center font-normal sm:text-[10px] md:text-[20px]' onClick={() => { monthHandler(12) }}>12ay</div>
                                                            </div>
                                                            <div className='text-center'>
                                                                <span className='font-light sm:text-[10px] md:text-[17px] text-[#F93131]'>0%</span>
                                                                <div className='sm:w-[28px] md:w-[58px] sm:h-[28px] md:h-[58px] cursor-pointer bg-[#D9D9D9] rounded-[50%] flex justify-center items-center font-normal sm:text-[10px] md:text-[20px]' onClick={() => { monthHandler(18) }}>18ay</div>
                                                            </div>
                                                            <div className='text-center'>
                                                                <span className='font-light sm:text-[10px] md:text-[17px] text-[#F93131]'>0%</span>
                                                                <div className='sm:w-[28px] md:w-[58px] sm:h-[28px] md:h-[58px] cursor-pointer bg-[#D9D9D9] rounded-[50%] flex justify-center items-center font-normal sm:text-[10px] md:text-[20px]' onClick={() => { monthHandler(24) }}>24ay</div>
                                                            </div>
                                                        </div>
                                                        <div className="w-[40%] flex justify-center items-center" >
                                                            {/* <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    marginBottom: '5px',
                                                                }}
                                                                className="calculationResult"
                                                            >
                                                                <Grid container spacing={2}>

                                                                    {
                                                                        paymentTypeList !== null ?
                                                                            paymentTypeList.map((item, i) => (
                                                                                <Grid
                                                                                    key={i}
                                                                                    item
                                                                                    xs={6}
                                                                                    sx={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'column',
                                                                                        alignItems: 'center',
                                                                                        '& p': {
                                                                                            textTransform: 'capitalize',
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <Box
                                                                                        sx={{
                                                                                            marginRight: '5px',
                                                                                            width: '40px',
                                                                                            height: '60px',
                                                                                            borderRadius: '8px',

                                                                                        }}
                                                                                        className="helperPaymentCardArea"
                                                                                    >
                                                                                        <img style={{ width: '100%', height: '100%', }} src={item.paymentTypeImage} alt="" />
                                                                                    </Box>
                                                                                    <p>
                                                                                        {
                                                                                            item.paymentTypeName
                                                                                        }
                                                                                    </p>
                                                                                </Grid>

                                                                            ))
                                                                            : ""
                                                                    }
                                                                </Grid>
                                                            </Box> */}
                                                            <div
                                                            >

                                                                <Box
                                                                    className="text-sm md:text-xl"
                                                                    sx={{
                                                                        textAlign: 'center',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        color: '#ed1c22'
                                                                    }}
                                                                >
                                                                    {t("Aylıq ödəniş")}
                                                                    <br />
                                                                    {
                                                                        Number(monthlyPrice).toFixed(2) + '₼'
                                                                        // productItem !== null && productItem.productInterest.length > 1 ?
                                                                        // Number(calculatedPrice).toFixed(2) + '₼'
                                                                        // : productItem.productInterest[0].amount + '₼'
                                                                    }
                                                                </Box>
                                                            </div>
                                                        </div>

                                                  </div>
                                                </li>
                                                <div style={{ border: '1px solid gray', opacity: '0.2', margin: '30px 0', marginBottom: '40px' }}></div>

                                                <li>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            gap: '30px'
                                                        }}

                                                    >
                                                        {/* <Button
                                                            className="purchaseButton"
                                                            variant='contained'
                                                            size="small"
                                                            sx={{
                                                                textAlign: 'center',
                                                                background: '#ed1c22',
                                                                color: '#fff',
                                                                fontWeight:'bold',
                                                                width:'100%',
                                                                textTransform:'capitalize',
                                                                padding:'7.5px',
                                                                fontSize:'14.5px',
                                                                borderRadius:'150px',
                                                                display:'flex',
                                                                alignItems:'center',
                                                                border:'.5px solid red',
                                                                '& h6':{
                                                                    margin:'0',
                                                                },
                                                                '& span':{
                                                                    borderRadius: '100%',
                                                                    height: '100%',
                                                                    width: '40px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifycontent: 'center',
                                                                    marginRight: '15px',
                                                                    
                                                                }
                                                            }}
                                                            onClick={(e)=>purchasePopUpOpenHandler('Nağd')}
                                                        >
                                                            <h6>Bir kliklə al</h6>
                                                        </Button> */}
                                                        <Button
                                                            disabled={!isProductInHold}
                                                            className="sm:!px-[30px] sm:!py-[20px] md:!px-[30px] md:!py-[20px] sm:!text-sm md:!text-xl"
                                                            variant='contained'
                                                            size="small"
                                                            sx={{
                                                                textTransform: 'capitalize',
                                                                // padding: '30px 50px',
                                                                // '@media (maxWidth: 965px)': {
                                                                //     padding: '0px 0px',
                                                                // },
                                                                // fontSize: '20px',
                                                                borderTopLeftRadius: '20px',
                                                                borderBottomRightRadius: '20px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                color: '#fff',
                                                                backgroundColor: 'rgba(237, 16, 16, 1)',
                                                                borderColor: 'rgba(237, 16, 16, 1)',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifycontent: 'center',
                                                                '&:hover': {
                                                                    backgroundColor: 'rgba(237, 16, 16, 1)',
                                                                },
                                                                '& span': {
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    color: '#fff !important',
                                                                    fontWeight: 500,
                                                                    margin: '0',
                                                                }
                                                            }}
                                                            onClick={loadPurchasePopUpOpenHandler}
                                                        >
                                                            <span>{t("Hissə-hissə al")}</span>

                                                        </Button>
                                                        <Button
                                                            disabled={!isProductInHold}
                                                            className="sm:!px-[30px] sm:!py-[20px] md:!px-[30px] md:!py-[20px] sm:!text-sm md:!text-xl"
                                                            variant='contained'
                                                            size="small"
                                                            sx={{
                                                                textTransform: 'capitalize',
                                                                // padding: '30px 50px',
                                                                // fontSize: '20px',
                                                                border: '1px solid black',
                                                                borderTopLeftRadius: '20px',
                                                                borderBottomRightRadius: '20px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                color: '#000',
                                                                display: 'flex',
                                                                backgroundColor: '#fff',
                                                                alignItems: 'center',
                                                                justifycontent: 'center',
                                                                '&:hover': {
                                                                    backgroundColor: '#fff',
                                                                },
                                                                '& span': {
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    color: '#fff !important',
                                                                    fontWeight: 500,
                                                                    margin: '0',
                                                                }
                                                            }}
                                                            onClick={purchasePopUpOpenHandler}
                                                        >
                                                            <h6>{t("Bir kliklə al")}</h6>

                                                        </Button>


                                                    </Box>
                                                </li>

                                            </ul>
                                        </Grid>
                                    </div>
                                </Box>
                                <Box className="w-full sm:!mt-8">
                                    <span style={{ color: 'rgba(219, 68, 68, 1)', borderBottom: '1px solid rgba(0, 0, 0)', paddingBottom: '5px' }}>Xüsusiyyətlər</span>
                                    <List sx={{ padding: '0px !important', marginTop: '10px', overflow: 'hidden', width: '100%' }}>
                                        {
                                            specificationList !== null && specificationList.length !== 0 ?
                                                specificationList.slice(0, limit).map((item, i) => (
                                                    <ListItem
                                                        sx={{ padding: '0px', width: '100%' }}
                                                        key={i}
                                                    >
                                                        <Grid>
                                                            <div className='flex justify-between sm:w-[350px] md:w-[700px] text-[rgba(119, 118, 118, 1)] border-b-2 my-2'>
                                                                <div>
                                                                    {item.name}
                                                                </div>
                                                                <div>
                                                                    {item.content}
                                                                </div>
                                                            </div>

                                                        </Grid>
                                                    </ListItem>
                                                ))
                                                : ''
                                        }
                                    </List>
                                    {/* <div style={{cursor: 'pointer'}} onClick={() => {collapseHandler()}}>Read More</div> */}
                                    <div className='flex justify-center mt-[20px] w-full md:w-[700px]'>
                                        <Button variant="text" onClick={handleReadMoreClick} className='text-black'>
                                            {readMore ? 'Read Less' : 'Read More'}
                                        </Button>
                                    </div>
                                </Box>
                            </Box>
                            : ''
                    }

                    {
                        discountSliderList !== null ?
                            <ProductSlider sliderHeading={t('Endirimli məhsullar')} sliderName={"Endirimli məhsullar"} sliderList={discountSliderList} />
                            : ''
                    }

                </Box>
            </div>
        </div>
    );
}
const mapStateToProps = state => {
    return state
}
const mapDispatchToProps = {
    basketAddAction,
}


export default connect(mapStateToProps, mapDispatchToProps)(Details);