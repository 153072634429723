import React, { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Box,
  Grid,
  TextField,
  FormControl,
  Typography,
  IconButton,
  Button,
  Checkbox,
} from "@mui/material";

import { FaRegTimesCircle } from "react-icons/fa";

import { BsArrowRight } from "react-icons/bs";

import { executeLoanPurchase, sendLoanPurchaseId } from "../services/services";

import SuccessPopUpGeneral from "./SuccessPopUpGeneral";
import ErrorPupUp from "../components/ErrorPopUp";
import PurchaseWarning from "../components/PurchaseWarning";
import { useTranslation } from "react-i18next";

function LoanPurchasePopUp({ productItem, closePurchaseAction, products }) {
  const { t } = useTranslation();
  const [requestIsError, setRequestIsError] = useState(false);
  const [warningIsOpen, setWarningIsOpen] = useState(false);

  const [userIsAcceptes, setUserIsAccepted] = useState(true);

  const [isTimeSelected, setIsTimeSelected] = useState(false);

  const selectLoanTimeHandler = () => {
    setIsTimeSelected(true);
  };

  const [localProductItem, setLocalProductItem] = useState(null);

  const [loanDates, setLoanDates] = useState({ item: null, month: 1 });

  const [purchaseState, setPurchaseState] = useState({
    agreement: false,
    cardNumber: "",
    creditTime: String(loanDates.month),
    customerEmail: "",
    customerFatherName: "",
    customerFinCode: "",
    customerName: "",
    customerNote: "",
    customerSurname: "",
    deliveryAddress: "",
    deliveryCity: "",
    pendingPayByCreditFour: "",
    pendingPayByCreditOne: "",
    pendingPayByCreditThree: "",
    pendingPayByCreditTwo: "",
    phoneNumber: "",
    productId: {},
  });

  const changeCreditTimeHandler = (item) => {
    setPurchaseState({
      ...purchaseState,
      creditTime: String(item.month),
    });
    setLoanDates({ item: item, month: item.month });
  };

  useEffect(() => {
    setLocalProductItem(productItem);
    setLoanDates({
      item: productItem.productInterest[0],
      month: productItem.productInterest[0].month,
    });
    let productIds = {};
    products.forEach((item) => {
      productIds[item.id] = item.count;
    });
    setPurchaseState({
      ...purchaseState,
      productId: productIds,
    });
  }, []);

  const purchaseChangeHadnler = (e) => {
    if (e.target.name !== "agreement") {
      setPurchaseState({
        ...purchaseState,
        [e.target.name]: e.target.value,
      });
    } else {
      setPurchaseState({
        ...purchaseState,
        [e.target.name]: e.target.value === "true" ? false : true,
      });
    }
  };

  const [purchaseIsOk, setPurchaseIsOk] = useState(false);

  const closePurchasePopUpHandler = () => {
    closePurchaseAction();
  };

  const purchaseFormSubmitHandler = (e) => {
    e.preventDefault();
    if (
      purchaseState.phoneNumber.length < 9 ||
      purchaseState.customerFinCode === ""
    ) {
      setWarningIsOpen(true);
    } else if (!purchaseState.agreement) {
      setWarningIsOpen(true);
      setUserIsAccepted(false);
    } else {
      (async () => {
        const purchaseRes = await executeLoanPurchase(purchaseState);
        if (purchaseRes.status === 201 || purchaseRes.status === 200) {
          const mailSended = await sendLoanPurchaseId(purchaseRes.data.id);
          if (mailSended.status === 200 || mailSended.status === 201) {
            setPurchaseIsOk(true);
            setIsTimeSelected(undefined);
          } else {
            setRequestIsError(true);
          }
        } else {
        }
      })();
    }
  };

  return (
    <Box
      className="purchasePopUp"
      sx={{
        "& .Toastify__toast-container": {
          zIndex: "99999999",
        },
      }}
    >
      {requestIsError ? <ErrorPupUp close={setRequestIsError} /> : ""}
      {warningIsOpen ? <PurchaseWarning close={setWarningIsOpen} /> : ""}

      {purchaseIsOk === true ? <SuccessPopUpGeneral /> : ""}
      {/* <ToastContainer/> */}
      <Box
        sx={{
          position: "fixed",
          left: "0%",
          top: "0%",
          width: "100vw",
          height: "100vh",
          zIndex: "999999999999999",
          paddingBottom: "20px",
          backgroundColor: "rgba(0,0,0,.5)",
        }}
        onClick={closePurchasePopUpHandler}
      ></Box>
      {isTimeSelected === true ? (
        <Box className="purchaseFormWrapper">
          <Box
            className="purchaseForm"
            sx={{
              backgroundColor: "#fff",
              position: "fixed",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              width: "calc(100% - 350px)",
              border: ".5px solid lightgrey",
              zIndex: "999999999999999999",
              borderRadius: "5px",
              overflow: "hidden",
              padding: "15px 30px",
              paddingBottom: "25px",
              boxShadow: "0px 0px 10px -2px #000",
              "& fieldset": {
                borderRadius: "5px",
              },
            }}
          >
            <Box
              sx={{
                textAlign: "right",
              }}
            >
              <IconButton onClick={closePurchasePopUpHandler}>
                <FaRegTimesCircle />
              </IconButton>
            </Box>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "700",
                fontSize: "23px",
                marginBottom: "25px",
                fontFamily: "light-roboto-mono",
              }}
            >
              {t("Müştəri məlumatları kredit")}
            </Typography>
            <form onSubmit={purchaseFormSubmitHandler}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="purchaseFormField"
                >
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      value={purchaseState.customerName}
                      onChange={purchaseChangeHadnler}
                      name="customerName"
                      variant="outlined"
                      label={`${t("Ad")} ${t("Soyad")}`}
                      size={"small"}
                    />
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4} lg={3} className="purchaseFormField">
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField
                                        fullWidth
                                        value={purchaseState.customerSurname}
                                        onChange={purchaseChangeHadnler}
                                        name="customerSurname"
                                        variant="outlined"
                                        // label=""
                                        size={"small"} />
                                </FormControl>
                            </Grid> */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="purchaseFormField"
                >
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      value={purchaseState.customerFatherName}
                      onChange={purchaseChangeHadnler}
                      name="customerFatherName"
                      variant="outlined"
                      label={t("Ata Adı")}
                      size={"small"}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="purchaseFormField"
                >
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      value={purchaseState.cardNumber}
                      // sx={{
                      //     '& fieldset':{
                      //         borderColor:purchaseState.customerFinCode==='' ? "red" :'',
                      //     }
                      // }}
                      onChange={purchaseChangeHadnler}
                      name="cardNumber"
                      variant="outlined"
                      label={t("Seriya nömrəsi")}
                      size={"small"}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="purchaseFormField"
                >
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      value={purchaseState.customerFinCode}
                      sx={{
                        "& fieldset": {
                          // borderColor:purchaseState.customerFinCode==='' ? "red" :'',
                        },
                      }}
                      onChange={purchaseChangeHadnler}
                      name="customerFinCode"
                      variant="outlined"
                      label={t("Fin Kod")}
                      size={"small"}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="purchaseFormField"
                >
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      value={purchaseState.deliveryAddress}
                      onChange={purchaseChangeHadnler}
                      name="deliveryAddress"
                      variant="outlined"
                      label={t("Ünvan")}
                      size={"small"}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="purchaseFormField"
                >
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      value={purchaseState.deliveryCity}
                      onChange={purchaseChangeHadnler}
                      name="deliveryCity"
                      variant="outlined"
                      label={t("Şəhər")}
                      size={"small"}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="purchaseFormField"
                >
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      value={purchaseState.phoneNumber}
                      sx={{
                        "& fieldset": {
                          // borderColor:purchaseState.phoneNumber==='' ? "red" :'',
                        },
                      }}
                      onChange={purchaseChangeHadnler}
                      name="phoneNumber"
                      type="number"
                      variant="outlined"
                      label={t("Telefon Nömrəsi")}
                      size={"small"}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="purchaseFormField"
                >
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      value={purchaseState.customerNote}
                      onChange={purchaseChangeHadnler}
                      name="customerNote"
                      variant="outlined"
                      label={t("Qeyd")}
                      size={"small"}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="purchaseFormField"
                >
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      value={purchaseState.customerEmail}
                      onChange={purchaseChangeHadnler}
                      name="customerEmail"
                      variant="outlined"
                      type="email"
                      label="Email"
                      size={"small"}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="purchaseFormField"
                  sx={{ display: "flex", alignItems: "start" }}
                >
                  <FormControl
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    <label htmlFor="agreement">
                      <p style={{ fontSize: "12px" }}>
                        <Checkbox
                          id="agreement"
                          style={{ height: "25px", marginRight: "5px" }}
                          name="agreement"
                          checked={purchaseState.agreement}
                          value={purchaseState.agreement}
                          onChange={purchaseChangeHadnler}
                          sx={{ width: "0" }}
                        />
                        {t("Bununla razılıq verirəm ki...")}
                      </p>
                    </label>
                    {!userIsAcceptes ? (
                      <span
                        style={{
                          fontSize: "12px",
                          color: "#ed1c22",
                          marginBottom: "7.5px",
                        }}
                      >
                        <b>{t("Razılıq vermək üçün qutunu doldurun")}</b>
                      </span>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={12}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#ed1c22",
                      textTransform: "capitalize",
                      border: ".5px solid #ed1c22",
                      marginTop: "25px",
                      "&:hover": {
                        backgroundColor: "#fff",
                        border: ".5px solid #ed1c22",
                        color: "#ed1c22",
                      },
                    }}
                  >
                    {t("Təstiq Et")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      ) : (
        ""
      )}

      {isTimeSelected === false ? (
        <Box
          className="calculatorWrapper"
          sx={{
            backgroundColor: "#fff",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            width: "calc(100% - 350px)",
            border: ".5px solid lightgrey",
            zIndex: "999999999999999999",
            borderRadius: "5px",
            overflow: "hidden",
            padding: "15px 30px",
            paddingBottom: "25px",
            boxShadow: "0px 0px 10px -2px #000",
            "& fieldset": {
              borderRadius: "5px",
            },
          }}
        >
          <Box>
            <Box
              sx={{
                textAlign: "right",
              }}
            >
              <IconButton onClick={closePurchasePopUpHandler}>
                <FaRegTimesCircle />
              </IconButton>
            </Box>
            <span
              style={{
                textAlign: "center",
                display: 'flex',
                justifyContent: 'center',
                fontWeight: "700",
                fontSize: "23px",
                marginBottom: "25px",
              }}
            >
              {t("Kreditlə al")}
            </span>
          </Box>
          <Box></Box>
          <Box
            className="loanTimeList"
            sx={{
              overflowX: "scroll",
              marginBottom: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "0px 10px",
                marginBottom: "35px",
                minHeight: "150px",
                width: `${productItem.productInterest.length * 175}px`,
              }}
            >
              {localProductItem !== null
                ? productItem.productInterest.map((item, i) => (
                    <>
                      <Box
                        className={
                          loanDates.month == item.month
                            ? "loanOption selected"
                            : "loanOption"
                        }
                        onClick={() => changeCreditTimeHandler(item)}
                      >
                        <div>
                          {item.month} {t("ay")}
                        </div>
                      </Box>
                      {i + 1 < productItem.productInterest.length ? (
                        <Box className="loanDivide"></Box>
                      ) : (
                        ""
                      )}
                    </>
                  ))
                : ""}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "bold",
              fontSize: "23px",
              color: "#282828",
              marginBottom: "45px",
              "& div": {
                width: "33%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span className="infoHeader">{t("Müddət")}</span>
              {localProductItem !== null ? loanDates.month + " ay" : ""}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span className="infoHeader">{t("İlkin ödəniş")}</span>0 ₼
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span className="infoHeader">{t("Aylıq ödəniş")}</span>
              {localProductItem !== null
                ? Number(loanDates.item.amount).toFixed(2) + " ₼"
                : ""}
            </Box>
          </Box>
          <Box sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                backgroundColor: "#27ae60",
                textTransform: "capitalize",
                "& svg": {
                  fontSize: "25px",
                  marginLeft: "10px",
                },
                "&:hover": {
                  backgroundColor: "#27ae60",
                },
              }}
              onClick={selectLoanTimeHandler}
            >
              <span>{t("Sifarişi rəsmiləşdir")}</span>
              <BsArrowRight />
            </Button>
          </Box>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}

export default LoanPurchasePopUp;
