import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { FaShoppingBasket } from "react-icons/fa";
import { connect } from "react-redux";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import { useTranslation } from "react-i18next";
const HeaderBasketButton = ({ basketReducer }) => {
  const { t } = useTranslation();

  useEffect(() => {

  }, [basketReducer.basketItems.length])
  return (
    <>
      <Box style={{
          position: "relative",
        }}>
          <Link to="/basket" style={{ position: "relative" }} className="text-black">
        <LocalGroceryStoreOutlinedIcon className="cursor-pointer" />
        <span
          style={{
            position: "absolute",
            left: "12px",
            top: "-9px",
            borderRadius: "50%",
            fontFamily: "apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
            height: "14px",
            width: "14px",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ed1c22",
            fontSize: "10px",
            color: "#fff",
          }}
        >
          {basketReducer.basketItems.length}
        </span>
        </Link>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBasketButton);
