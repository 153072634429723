const LOAD_PRODUCTS = 'LOAD_PRODUCTS';

const BUYWITHCASH = 'BUYWITHCASH'; 
const CLEANBUYWITHCASH = 'CLEANBUYWITHCASH';

const BASKET_INIT_STORAGE = 'BASKET_INIT_STORAGE'; 
const BASKET_ADD_ITEM = 'BASKET_ADD_ITEM';
const BASKET_DELETE_ITEM = 'BASKET_DELETE_ITEM';
const BASKET_EDIT_ITEM = 'BASKET_EDIT_ITEM';

const BASKET_INCREASE_ITEM = 'BASKET_INCREASE_ITEM';
const BASKET_DECREASE_ITEM = 'BASKET_DECREASE_ITEM';
const BASKET_RESET_ITEM = 'BASKET_RESET_ITEM';

export default {
    LOAD_PRODUCTS,
    
    BUYWITHCASH,
    CLEANBUYWITHCASH,

    BASKET_INIT_STORAGE,    
    BASKET_ADD_ITEM,
    BASKET_DELETE_ITEM,
    BASKET_EDIT_ITEM,
    BASKET_INCREASE_ITEM,
    BASKET_DECREASE_ITEM,
    BASKET_RESET_ITEM,  
};