import Types from '../types/types';




export const basketInitListAction=(list)=>{
    return {
        type:Types.BASKET_INIT_STORAGE,
        payload:list,
    }
}

export const basketAddAction=(item)=>{
    return{
        type:Types.BASKET_ADD_ITEM,
        payload:item,
    }
}

export const basketDeleteAction=(item)=>{
    return{
        type:Types.BASKET_DELETE_ITEM,
        payload:item,
    }
}

// export const basketEditAction=(item)=>{
//     return{
//         type:Types.BASKET_EDIT_ITEM,
//         payload:item,
//     }
// }


export const basketIncreaseAction =(item)=>{
    return {
        type:Types.BASKET_INCREASE_ITEM,
        payload:item,
    }
}
export const basketDecreaseAction =(item)=>{
    return {
        type:Types.BASKET_DECREASE_ITEM,
        payload:item,
    }
}
export const basketResetAction =(item)=>{
    return {
        type:Types.BASKET_RESET_ITEM,
        payload:item,
    }
}