import React, { useRef, useState, useEffect } from "react";
import Logo from "../assets/img/barkod.png";

import "../assets/styles/header.css";
import { Link, useNavigate } from "react-router-dom";

import {
  Box,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Select,
  MenuItem,
} from "@mui/material";

import { BsSearch } from "react-icons/bs";

// import FaFacebookF from '@mui/icons-material/Facebook';

import MenuIcon from "@mui/icons-material/Menu";

import { SlBasket } from "react-icons/sl";
import {
  FaSearch,
  FaFacebookF,
  FaInstagram,
  FaShoppingBasket,
  FaWhatsapp,
} from "react-icons/fa";

import { clickcerIncreaser } from "../services/services";

import CategoryMenu from "../components/CategoryMenu";

import HeaderBasketButton from "../components/HeaderBasketButton";

import { loadAbout } from "../services/services";
import { useLanguage } from "../contexts/LanguageContext";
import zIndex from "@mui/material/styles/zIndex";
import { useTranslation } from "react-i18next";

const Header = ({
  setMobileMenuIsOpen,
  seHeaderDropMenuIsOpen,
  headerDropMenuIsOpen,
}) => {
  const { t } = useTranslation();
  const [aboutInformations, setAboutInformations] = useState(null);
  const [clickerState, setClickerState] = useState({
    aboutClicker: false,
    basketClicker: false,
    branchClicker: false,
    contactClicker: false,
    counter: 1,
    discountClicker: false,
    facebookClicker: false,
    homeClicker: false,
    instagramClicker: false,
    pendingClickerFour: false,
    pendingClickerOne: false,
    pendingClickerThree: false,
    pendingClickerTwo: false,
    productClicker: false,
    seasonClicker: false,
    weekClicker: false,
  });
  const { language, setLanguage } = useLanguage();
  const clickerClickHandler = (obj) => {
    // ()()
    //     const pathItem = e.target.dataset;
    (async () => {
      await clickcerIncreaser(obj);
    })();
  };

  const [autoPlaceholder, setAutoPlaceholder] = useState("");
  const dataElement = useRef();

  const navigate = useNavigate();

  const [headerSearchIsOpen, setHeaderSearchIsOpen] = useState(false);

  const [headerSearchText, setHeaderSearchText] = useState("");

  const changeHeaderSearchText = (e) => {
    // setHeaderSearchText()
    setHeaderSearchText(e.target.value);
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/axtaris-neticeleri/${headerSearchText}`);
  };
  // console.log()

  useEffect(() => {
    setHeaderSearchText("");
  }, [window.location.pathname]);

  useEffect(() => {
    (async () => {
      setAboutInformations(await loadAbout());
    })();
    let lengthhh = dataElement.current.dataset.holder.length;
    let count = 0;
    setInterval(() => {
      count = count + 1;
      if (count <= lengthhh) {
        // setAutoPlaceholder(dataElement.current.dataset.holder.slice(0,count));
      } else {
        // count=0;
        // setAutoPlaceholder()
      }
    }, 400);
  }, []);
  return (
    <header
      style={{
        position: "sticky",
        top: "-1px",
        zIndex: "9999999",
        backgroundColor: "#fff",
        borderBottom: ".5px solid lightgrey",
        padding: "20px 0px",
      }}
    >
      <Collapse
        in={headerDropMenuIsOpen}
        timeout="auto"
        unmountOnExit
        sx={{
          position: "absolute",
          top: "100%",
          transition: "all .5s ease",
          zIndex: "99999",
          left: "55px",
        }}
      >
        <Box
          sx={{
            bottom: "0%",
          }}
        >
          <CategoryMenu seHeaderDropMenuIsOpen={seHeaderDropMenuIsOpen} />
        </Box>
      </Collapse>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
        className=""
      >
        <Box
          className="headerLeftPart"
          sx={{
            zIndex: "9999999999999999",
            width: "20%",
            display: "flex",
            justifyContent: "start",
            "& img": {
              width: "100%",
            },
          }}
        >
          <Box
            className="mobileSearch"
            sx={{ display: "none", "& svg": { color: "#000" } }}
          >
            <IconButton
              onClick={() => setHeaderSearchIsOpen(!headerSearchIsOpen)}
            >
              <BsSearch />
            </IconButton>
          </Box>
          <Box
            className="app-logo"
            sx={{
              width: "138px",
              marginRight: "50px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Link to="/home">
              <img src={Logo} alt="" />
            </Link>
          </Box>
          <Box
            sx={{
              // width:'100px',
              display: "flex",
              alignItems: "center",
              justifycContent: "center",
            }}
          >
            <IconButton
              sx={{
                color: "#000",
                display: "flex",
                alignItems: "center",
                padding: "0px",
                width: "100%",
                transition: "all .5s ease",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#ff0009",
                },
              }}
            >
              <Box
                className="mobileMenuToggle"
                sx={{
                  display: "none",
                  "& svg": {
                    fontSize: "32.5px",
                    color: "#000",
                  },
                }}
              >
                <MenuIcon
                  onClick={() => setMobileMenuIsOpen(true)}
                  size="small"
                />
              </Box>
            </IconButton>
          </Box>
        </Box>
        <form action="" onSubmit={onSearchSubmit} style={{ display: "flex" }}>
          <Box
            className="headerSearchInput"
            sx={{
              minWidth: "350px",
              width: "20%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              // label='Axtarış et'
              data-holder="Axtarış et"
              ref={dataElement}
              // placeholder={autoPlaceholder}
              fullWidth
              placeholder={t("Axtarış Et")}
              onChange={changeHeaderSearchText}
              value={headerSearchText}
              sx={{
                "&:-ms-input-placeholder ": {
                  color: "red",
                },

                "&::-ms-input-placeholder": {
                  color: "red",
                },
                "& input": {
                  color: "#8e8e8e",
                  borderRight: ".5px solid #e5e5e5",
                  "&::placeholder": {
                    color: "#8e8e8e",
                    fontSize: "14px",
                  },
                },
                // '& label':{
                //     fontSize:'14px',
                //     '&.Mui-focused':{
                //         color:'#8e8e8e',
                //     },
                //     color:'#8e8e8e',
                // },
                "& fieldset": {
                  // borderRadius:'30px',
                  borderColor: "#e5e5e5 !important",
                  color: "#000",
                  "&:focus": {
                    borderColor: "red !important",
                  },
                },
                "&:hover": {
                  "& fieldset": {
                    borderColor: ".5px solid #e5e5e5 !important",
                    borderWidth: ".5px !important",
                  },
                  // backgroundColor:'red',
                },
                // '& fieldset.MuiOutlinedInput-notchedOutline':{
                // borderColor:'#000 !important',
                // }
                "& svg": {
                  // stroke:'#8e8e8e',
                  // border:'none',
                  // color:'#8e8e8e',
                },
              }}
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{ marginLeft: "10px", cursor: "pointer" }}
                    position="start"
                  >
                    <Link
                      style={{ color: "#8e8e8e", marginLeft: "8px" }}
                      to={`/axtaris-neticeleri/${headerSearchText}`}
                    >
                      <FaSearch />
                    </Link>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </form>
        <Box
          className="socials"
          sx={{
            width: "40%",
            textAlign: "center",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box className="language-change" sx={{ display: "flex", alignItems: "center", padding: "0 12px" }}>
            <select
              style={{
                zIndex: "9999999999",
                outline: "none",
                border: "none",
                fontSize: "16px",
              }}
              value={language}
              onChange={(e) => {
                setLanguage(e.target.value);
              }}
            >
              <option value="en">EN</option>
              <option value="ru">RU</option>
              <option value="az">AZ</option>
            </select>
          </Box>
          <ul
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              "& a": {
                padding: "0!important",
              },
              "& button": {
                padding: "0!important",
              },
            }}
          >
            <li>
              <a
                href="https://www.instagram.com/barkod_electronics_/"
                target="_blank"
              >
                <IconButton
                  onClick={() =>
                    clickerClickHandler({
                      aboutClicker: false,
                      basketClicker: false,
                      branchClicker: false,
                      contactClicker: false,
                      counter: 0,
                      discountClicker: false,
                      facebookClicker: false,
                      homeClicker: false,
                      instagramClicker: true,
                      pendingClickerFour: false,
                      pendingClickerOne: false,
                      pendingClickerThree: false,
                      pendingClickerTwo: false,
                      productClicker: false,
                      seasonClicker: false,
                      weekClicker: false,
                    })
                  }
                  sx={{
                    "& svg": {
                      fill: "#8e8e8e",
                      // transition:'all .2s ease',
                      "&:hover": {
                        // fill:'#fff',
                      },
                    },
                    "&:hover": {
                      "& svg": {
                        fill: "#fff",
                      },
                      background: "#f09433",
                      background:
                        "-moz-linear-gradient(45deg, #405DE6 50%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)",
                      background:
                        "-webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)",
                      background:
                        "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)",
                    },
                  }}
                >
                  <FaInstagram />
                </IconButton>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/BarkodElectronics"
                target="_blank"
              >
                <IconButton
                  onClick={() =>
                    clickerClickHandler({
                      aboutClicker: false,
                      basketClicker: false,
                      branchClicker: false,
                      contactClicker: false,
                      counter: 0,
                      discountClicker: false,
                      facebookClicker: true,
                      homeClicker: false,
                      instagramClicker: false,
                      pendingClickerFour: false,
                      pendingClickerOne: false,
                      pendingClickerThree: false,
                      pendingClickerTwo: false,
                      productClicker: false,
                      seasonClicker: false,
                      weekClicker: false,
                    })
                  }
                  sx={{
                    "& svg": {
                      fill: "#8e8e8e",
                      // transition:'all .2s ease',
                      "&:hover": {
                        // fill:'#fff',
                      },
                    },
                    "&:hover": {
                      "& svg": {
                        fill: "#fff",
                      },
                      background: "#4267B2",
                    },
                  }}
                >
                  <FaFacebookF />
                </IconButton>
              </a>
            </li>
            <li>
              <a
                href={`https://api.whatsapp.com/send?phone=${aboutInformations?.pendingAboutOne}`}
              >
                <IconButton
                  sx={{
                    "& svg": {
                      fill: "#8e8e8e",
                      // transition:'all .2s ease',
                      "&:hover": {
                        // fill:'#fff',
                      },
                    },
                    "&:hover": {
                      "& svg": {
                        fill: "#fff",
                      },
                      background: "#25D366",
                    },
                  }}
                >
                  <FaWhatsapp />
                </IconButton>
              </a>

            </li>
          </ul>

          <ul
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "0 6px"
            }}
          >
            <li
              style={{
                display: "flex",
                padding: "0 12px"
              }}
            >
              <HeaderBasketButton />
            </li>
          </ul>
        </Box>
      </Box>
      <Collapse in={headerSearchIsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <form onSubmit={onSearchSubmit}>
            <ListItem>
              <TextField
                onChange={changeHeaderSearchText}
                value={headerSearchText}
                sx={{
                  backgroundColor: "#000",
                  borderRadius: "8px",
                  // color:"#000",
                  backgroundColor: "transparent",
                  "& input": {
                    color: "#000",
                  },
                  "& .Mui-focused": {
                    "& fieldset": {
                      borderColor: "#000 !important",
                    },
                  },
                  "& fieldset": {
                    color: "#000 !important",
                    borderColor: "#000 !important",
                    borderRadius: "23px",
                    backgroundColor: "transparent !important",
                  },
                  "& label": {
                    color: "#000 !important",
                  },
                }}
                fullWidth
                size="small"
                label={t("Axtarış Et")}
              />
            </ListItem>
          </form>
        </List>
      </Collapse>
    </header>
  );
};

export default Header;
