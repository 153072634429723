import React, { useEffect, useState } from 'react';
import CategoryMenu from '../components/CategoryMenu';

import {
    Box,
    Grid,
    Button,
} from '@mui/material';
import ProductSlider from '../components/ProductSlider';


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';




import { connect } from 'react-redux';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


import {

    loadBannerSliderImages,
    loadOfferOfWeekProducts,
    loadOfferOfSeasonProducts,
    loadDiscountProducts,

    clickcerIncreaser,


} from '../services/services';

import OffersTab from '../components/OffersTab';
import { useTranslation } from 'react-i18next';
import { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Partners from '../components/Partners';


const BannerNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <ArrowForwardIosIcon
            className={className}
            style={{ ...style, }}
            onClick={onClick}
        />
    )
}


const BannerPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <ArrowBackIosNewIcon
            className={className}
            style={{ ...style, }}
            onClick={onClick}
        />
    )
}

const Home = ({
    productLoadReducer,
}) => {
    const { t } = useTranslation();
    const [discountSliderList, setDiscountSlderList] = useState(null);
    const [offerOfWeekSliderList, seOfferOfWeekSliderList] = useState(null);
    const [offerOfSeasonSliderList, offerOfSeasonSlderList] = useState(null);



    const [bannerSlider, setBannerSlider] = useState(null)
    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        // speed: 500,
        autoplay: true,
        autoplaySpeed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <BannerNextArrow />,
        prevArrow: <BannerPrevArrow />,
    };
    // console.log();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        (async () => {
            setBannerSlider(await loadBannerSliderImages());

            setDiscountSlderList(await loadDiscountProducts());
            seOfferOfWeekSliderList(await loadOfferOfWeekProducts());
            offerOfSeasonSlderList(await loadOfferOfSeasonProducts());
        })()

        return (() => {
            (async () => {
                await clickcerIncreaser({
                    "aboutClicker": false,
                    "basketClicker": false,
                    "branchClicker": false,
                    "contactClicker": false,
                    "counter": 0,
                    "discountClicker": false,
                    "facebookClicker": false,
                    "homeClicker": true,
                    "instagramClicker": false,
                    "pendingClickerFour": false,
                    "pendingClickerOne": false,
                    "pendingClickerThree": false,
                    "pendingClickerTwo": false,
                    "productClicker": false,
                    "seasonClicker": false,
                    "weekClicker": false
                })
            })()
        })
    }, [])

    return (
        <Box
        sx={{
            width: '100%',
            // padding: '0 40px',
        }}
        className="md:px-[40px] sm:p-0"
        >
            <Box className="p-0">
                <Grid container className='w-[100%] flex justify-between'>
                    <div className="hidden md:block w-[30%] h-auto lg:flex items-center my-auto">
                        <CategoryMenu />
                    </div>
                    <div className='w-full h-auto md:w-[70%] md:h-[544px]'>
                        <div
                            className="w-full h-full flex items-center"
                            sx={{
                               
                                '& svg': {
                                    color: '#fff',
                                    fontSize: '38px !important',
                                    '& path': {
                                    },
                                    '&:hover': {
                                        color: '#fff',
                                    },
                                    '&.slick-prev': {
                                        // marginLeft: '50px',
                                        zIndex: '9',
                                    },
                                    '&.slick-next': {
                                        marginRight: '50px',
                                        zIndex: '9',
                                    }
                                },
                                '& img': {
                                    width: '100%',

                                },
                               
                            }}
                        >
                            <div  style={{
                                width: '95%',
                                margin: '0 auto',
                                zIndex:'-1'
                                // height: '544px',
                            }}>
                                <Carousel className='rounded-lg flex justify-center items-center overflow-hidden ' autoPlay showStatus={false} infiniteLoop={true} showThumbs={false} useKeyboardArrows={false} stopOnHover={false}>
                                    {
                                        bannerSlider !== null ?
                                            bannerSlider.map((item, i) => (
                                                <div key={i} className='w-full h-full ' >
                                                    <img src={item.source} className='w-full ' alt="" 
                                                    // style={{
                                                    //     width: '100%',
                                                    //     height: '544px',
                                                    // }}
                                                    />
                                                </div>
                                            ))
                                            : ''
                                    }
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </Grid>

            </Box>

            <div className='h-[100px] mt-[21px] w-100'
                style={{
                    marginBottom: '-34px'
                }}
            >
                <Partners />
            </div>

            <div className='sm:px-[40px] '>
                {
                    discountSliderList !== null ?
                        <ProductSlider sliderHeading={t('Super Təkliflər')} sliderName={"Endirimli məhsullar"} sliderList={discountSliderList}/>
                        : ''
                }
            </div>
            <div className=' mt-[40px] w-100 border-b-[0.5px] border-black/30'></div>


            <div className='sm:px-[40px]'>
                {
                    offerOfWeekSliderList !== null ?
                        <ProductSlider sliderHeading={t('Həftənin təklifləri')} sliderName={"Həftənin təklifləri"} sliderList={offerOfWeekSliderList} />
                        : ''
                }
            </div>
            <div className=' mt-[40px] w-100 border-b-[0.5px] border-black/30'></div>


            

            
            <div className='sm:px-[40px]'>
                {
                    offerOfSeasonSliderList !== null ?
                        <ProductSlider sliderHeading={t('Sezon təklifləri')} sliderName={"Sezon təklifləri"} sliderList={offerOfSeasonSliderList} />
                        : ''
                }
            </div>

            {/* <OffersTab/>                    */}


        </Box>

    );
}


const mapStateToProps = state => state;
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
