import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { Box } from "@mui/material";

import { loadSubChildCategoryListById } from "../services/services";
import { useLanguage } from "../contexts/LanguageContext";

const SubParentCategoryMenuItem = ({ subParentCategoryItem, closeMenuHandler }) => {
  const [subChildCategoryList, setSubChildCategoryList] = useState(null);
  const { language } = useLanguage();

  const handleClick = () => {
    closeMenuHandler();
  };




  useEffect(() => {
    (async () => {
      setSubChildCategoryList(
        await loadSubChildCategoryListById(subParentCategoryItem.id)
      );
    })();
  }, []);

  return (
    <div className="w-[200px]">
      <Box
        sx={{
          float: "left",
          margin: "0px 54px",
          maxWidth: "200px",
          width: "175px",
          "& .subParentList ": {
            "& li": {
              paddingLeft: "0px",
              paddingBottom: "0px",
              paddingTop: "0px",
            },
            "& li:last-of-type": {
              marginBottom: "25px",
            },
          },
          "& a": {
            "&:hover": {
              color: "#ed1c22 !important",
              fontWeight: "bold",
            },
          },
          "& h6 a": {
            fontWeight: "700",
          },
          "& .parentCategoryTitle": {
            "& a": {
              minWidth: "175px",
            },
          },
        }}
      >
        <h6
          className="parentCategoryTitle"
          style={{
            textTransform: "capitalize",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          <Link to={`/neticeler/umumi/${subParentCategoryItem.id}`} onClick={() => handleClick()}>

            {language == "az" && subParentCategoryItem.pendingParentSubcategoryOne
              ? subParentCategoryItem.pendingParentSubcategoryOne
              : language == "ru" &&
                subParentCategoryItem.pendingParentSubcategoryTwo
                ? subParentCategoryItem.pendingParentSubcategoryTwo
                : language == "en" &&
                  subParentCategoryItem.pendingParentSubcategoryThree
                  ? subParentCategoryItem.pendingParentSubcategoryThree
                  : subParentCategoryItem.name}

                  
          </Link>
        </h6>
        <ul
          className="subParentList"
          style={{
            textAlign: "left",
            fontSize: "14px",
            letterSpacing: "-.32px",
            textTransform: "capitalize",
          }}
        >
          {subChildCategoryList !== null
            ? subChildCategoryList.map((item, i) => (
              <li key={i}>
                <Link
                  to={`/neticeler/alt-mehsullar/${item.id}`}
                  style={{
                    width: "100%",
                    display: "block",
                    fontSize: "14px",
                    letterSpacing: "-.32px",
                    color: "#545353",
                    marginBottom: "2.5px",
                  }}
                  onClick={() => handleClick()}
                >
                  {language == "az" && item.pendingChildSubcategoryOne
                    ? item.pendingChildSubcategoryOne
                    : language == "ru" && item.pendingChildSubcategoryTwo
                      ? item.pendingChildSubcategoryTwo
                      : language == "en" && item.pendingChildSubcategoryThree
                        ? item.pendingChildSubcategoryThree
                        : item.name}
                </Link>
              </li>
            ))
            : ""}
        </ul>
      </Box>


      {/* <Box
        sx={{
          // float: "left",
          margin: "0px 50px",
          maxWidth: "200px",
          width: "175px",
          "& .subParentList ": {
            "& li": {
              paddingLeft: "0px",
              paddingBottom: "0px",
              paddingTop: "0px",
            },
            "& li:last-of-type": {
              marginBottom: "25px",
            },
          },
          "& a": {
            "&:hover": {
              color: "#ed1c22 !important",
              fontWeight: "bold",
            },
          },
          "& h6 a": {
            fontWeight: "700",
          },
          "& .parentCategoryTitle": {
            "& a": {
              minWidth: "175px",
            },
          },
        }}
      >
        <h6
          className="parentCategoryTitle"
          style={{
            textTransform: "capitalize",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          <Link to={`/neticeler/umumi/${subParentCategoryItem.id}`}>
            {language == "az" && subParentCategoryItem.pendingParentSubcategoryOne
              ? subParentCategoryItem.pendingParentSubcategoryOne
              : language == "ru" &&
                subParentCategoryItem.pendingParentSubcategoryTwo
                ? subParentCategoryItem.pendingParentSubcategoryTwo
                : language == "en" &&
                  subParentCategoryItem.pendingParentSubcategoryThree
                  ? subParentCategoryItem.pendingParentSubcategoryThree
                  : subParentCategoryItem.name}
          </Link>
        </h6>
        <ul
          className="subParentList"
          style={{
            textAlign: "left",
            fontSize: "14px",
            letterSpacing: "-.32px",
            textTransform: "capitalize",
          }}
        >
          {subChildCategoryList !== null
            ? subChildCategoryList.map((item, i) => (
              <li key={i}>
                <Link
                  to={`/neticeler/alt-mehsullar/${item.id}`}
                  style={{
                    width: "100%",
                    display: "block",
                    fontSize: "14px",
                    letterSpacing: "-.32px",
                    color: "#545353",
                    marginBottom: "2.5px",
                  }}
                >
                  {language == "az" && item.pendingChildSubcategoryOne
                    ? item.pendingChildSubcategoryOne
                    : language == "ru" && item.pendingChildSubcategoryTwo
                      ? item.pendingChildSubcategoryTwo
                      : language == "en" && item.pendingChildSubcategoryThree
                        ? item.pendingChildSubcategoryThree
                        : item.name}
                </Link>
              </li>
            ))
            : ""}
        </ul>
      </Box> */}

    </div>
  );
};

export default SubParentCategoryMenuItem;
