import React, { useEffect, useState } from "react";

import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Collapse,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { FaMinus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { loadSubChildCategoryListById } from "../services/services";
import { useLanguage } from "../contexts/LanguageContext";

const MobileMenuSubParentItem = ({
  item,
  subParentCategoryListIsOpen,
  onMenuParentItemOpenHandler,
  setMobileMenuIsOpen,
}) => {
  const [subParentCategoryList, setSubParentCategoryList] = useState(null);
  const { language } = useLanguage();

  useEffect(() => {
    (async () => {
      setSubParentCategoryList(await loadSubChildCategoryListById(item.id));
    })();
  }, []);

  return (
    <>
      {/* {
            item.name
        } */}
      <ListItem
        onClick={onMenuParentItemOpenHandler}
        data-name={`menuItem${item.id}`}
        sx={{
          marginLeft: "30px",
        }}
      >
        <ListItemIcon
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <FaMinus/> */}
          <Box
            sx={{
              transition: "all .2s ease",
              transform: `rotate(${
                subParentCategoryListIsOpen[`menuItem${item.id}`]
                  ? " 90deg"
                  : " 0deg"
              })`,
            }}
          >
            <ArrowForwardIosIcon />
          </Box>
        </ListItemIcon>
        <ListItemText
          sx={{
            textTransform: "capitalize",
          }}
          primary={`${
            language == "az" && item.pendingParentSubcategoryOne
              ? item.pendingParentSubcategoryOne
              : language == "ru" && item.pendingParentSubcategoryTwo
              ? item.pendingParentSubcategoryTwo
              : language == "en" && item.pendingParentSubcategoryThree
              ? item.pendingParentSubcategoryThree
              : item.name
          }`}
        />
      </ListItem>
      <Collapse
        in={subParentCategoryListIsOpen[`menuItem${item.id}`]}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {subParentCategoryList !== null
            ? subParentCategoryList.map((item, i) => (
                <ListItemButton
                  key={i}
                  onClick={() => setMobileMenuIsOpen(false)}
                  sx={{
                    pl: 4,
                    "& a": {
                      color: "#000",
                      textTransform: "capitalize",
                      textDecoration: "none",
                      paddingLeft: "35px",
                    },
                  }}
                >
                  <Link to={`/neticeler/alt-mehsullar/${item.id}`}>
                    <ListItemText
                      primary={`${
                        language == "az" && item.pendingChildSubcategoryOne
                          ? item.pendingChildSubcategoryOne
                          : language == "ru" && item.pendingChildSubcategoryTwo
                          ? item.pendingChildSubcategoryTwo
                          : language == "en" &&
                            item.pendingChildSubcategoryThree
                          ? item.pendingChildSubcategoryThree
                          : item.name
                      }`}
                    />
                  </Link>
                </ListItemButton>
              ))
            : ""}
        </List>
      </Collapse>
    </>
  );
};

export default MobileMenuSubParentItem;
