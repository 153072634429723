import React, { useEffect, useRef, useState } from "react";

import { GiHamburgerMenu } from "react-icons/gi";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Input, InputAdornment, TextField } from "@mui/material";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Link, useNavigate, useParams } from "react-router-dom";

import CategoryMenu from "./CategoryMenu";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import MobileCategory from "./MobileCategory";

const NavigationBar = () => {
  const { t } = useTranslation();
  const [isHome, setIsHome] = useState(false);
  // const test = useParams()
  // console.log()
  // useEffect(()=>{
  //     console.log(window.location.pathname)
  // },[window.location.pathname])
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const dataElement = useRef();

  const navigate = useNavigate();

  const [headerSearchText, setHeaderSearchText] = useState("");

  const changeHeaderSearchText = (e) => {
    // setHeaderSearchText()
    setHeaderSearchText(e.target.value);
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/axtaris-neticeleri/${headerSearchText}`);
    setHeaderSearchText("");
  };

  const openCloseMenuHandler = () => {
    // if(window.location.pathname==='/' || window.location.pathname==='/home'){
    // }else{
    setIsOpenMenu(true);
    console.log("tes");

    // }
  };

  const closeMenuHandler = () => {
    console.log("closeMenuHandler");
    setIsOpenMenu(false);
  };

  return (
    <div
      className="md:top-[80px] sm:top-[79px]"
      style={{
        position: "sticky",
        zIndex: "999999999999",
        backgroundColor: "#ffffff",
        width: "100%",
        "@media (maxWidth: 600px)": {
          padding: "0",
        },
      }}
    >
      <Box
        className="w-full justify-center pt-[34px] pb-[26px] px-[40px]"
        sx={{
          zIndex: "9999999",
          position: "sticky",
          "& li": {
            "& a": {
              textDecoration: "none",
              color: "#545353",
              "&:hover": {
                color: "#ed1c22",
              },
            },
          },
          "& svg": {
            marginRight: "10px",
            fontSize: "23px",
          },
        }}
      >
        <ul
          className="flex justify-between  w-full items-center"
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#fff",
            width: "100%",
          }}
        >
          <li
            style={{
              // padding:'7.5pxpx',
              // display: 'flex',
              alignItems: "center",
              // width: '250px',
              fontWeight: "700",
              fontSize: "16px",
              cursor: "pointer",
              // position: 'relative',
            }}
            className="flex md:hidden w-[40%]"
            
          >
            <GiHamburgerMenu onClick={() => openCloseMenuHandler()} />

            {isOpenMenu ? (
              <Box
                sx={{
                  position: "absolute",
                  zIndex: "9",
                  left: "-5px",
                  top: "-120%",
                  width: "100%",
                  paddingTop: "33px",
                }}
              >
                <CategoryMenu closeMenuHandler={() => closeMenuHandler()} />
              </Box>
            ) : (
              ""
            )}
          </li>
          <form
            action=""
            onSubmit={onSearchSubmit}
            style={{ display: "flex" }}
            className="sm:w-[100%] md:w-[120%] "
          >
            <Box className="flex w-full items-center ">
              <div className="bg-[#f5f5f5] !h-[38px] pt-[18px] rounded-l-lg border-[#f5f5f5]">
                <InputAdornment
                  sx={{ marginLeft: "10px", cursor: "pointer" }}
                  position="start"
                >
                  <Link
                    style={{ color: "#000", marginLeft: "8px" }}
                    to={`/axtaris-neticeleri/${headerSearchText}`}
                  >
                    <SearchOutlinedIcon />
                  </Link>
                </InputAdornment>
              </div>
              <input
                style={{
                  padding: "7px, 12px, 7px, 20px",
                }}
                // className='focus:outline-none items-center  flex flex-wrap p-3 rounded-r-lg  w-[811px] h-[38px] outline-none bg-[#f5f5f5] border-none'
                className="focus:outline-none items-center  flex flex-wrap p-3 rounded-r-lg w-100 !h-[38px] outline-none bg-[#f5f5f5] border-none"
                placeholder={"Axtarış"}
                ref={dataElement}
                onChange={changeHeaderSearchText}
                value={headerSearchText}
                data-holder="Axtarış et"
              />
              {/* <TextField
                                data-holder="Axtarış et"
                                ref={dataElement}
                                fullWidth
                                placeholder={t("Axtarış")}
                                onChange={changeHeaderSearchText}
                                value={headerSearchText}
                                sx={{
                                    "&:-ms-input-placeholder ": {
                                        color: "red",
                                    },

                                    "&::-ms-input-placeholder": {
                                        color: "red",
                                    },
                                    "& input": {
                                        color: "#F5F5F5",
                                        borderRight: ".5px solid #e5e5e5",
                                        "&::placeholder": {
                                            color: "#8e8e8e",
                                            fontSize: "14px",
                                        },
                                    },
                                   
                                    "& fieldset": {
                                        borderColor: "#F5F5F5 !important",
                                        color: "#000",
                                        "&:focus": {
                                            borderColor: "red !important",
                                        },
                                    },
                                    "&:hover": {
                                        "& fieldset": {
                                            borderColor: ".5px solid #F5F5F5 !important",
                                            borderWidth: ".5px !important",
                                        },
                                        // backgroundColor:'red',
                                    },
                                    // '& fieldset.MuiOutlinedInput-notchedOutline':{
                                    // borderColor:'#000 !important',
                                    // }
                                    "& svg": {
                                        // stroke:'#8e8e8e',
                                        // border:'none',
                                        // color:'#8e8e8e',
                                    },
                                }}
                                size="large"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            sx={{ marginLeft: "10px", cursor: "pointer" }}
                                            position="start"
                                        >
                                            <Link
                                                style={{ color: "#8e8e8e", marginLeft: "8px" }}
                                                to={`/axtaris-neticeleri/${headerSearchText}`}
                                            >
                                                <FaSearch />
                                            </Link>
                                        </InputAdornment>
                                    ),
                                }}
                            /> */}
            </Box>
          </form>
        </ul>
      </Box>
    </div>
  );
};

export default NavigationBar;
