import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import { loadAbout } from '../services/services';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function Partners() {

    
const BannerNextArrow = () => {
    return (
      <ArrowForwardIosIcon/>
    );
  };
  
  const BannerPrevArrow = () => {
    return (
      <ArrowBackIosNewIcon/>
    );
  };

  const [aboutState, setAboutStaet] = useState(null);


  useEffect(() => {
    (async () => {
      setAboutStaet(await loadAbout());
    })();
  }, []);


    const partnersSettings = {
        dots: false,
        infinite: aboutState?.partnersImageEntities?.length > 8 ? true : false,
        arrows: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2500,
        slidesToShow: 10,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 821,
            settings: {
              slidesToShow: 2,
              infinite:
                aboutState?.partnersImageEntities?.length > 8 ? true : false,
            },
          },
        ],
        nextArrow: <BannerNextArrow />,
        prevArrow: <BannerPrevArrow />,
      };
    return (
        <>
            <Box sx={{ height: '100%', width: '100%'}}>
                <Box
                    sx={{
                        height: '100%',
                        "& .slick-slider": {
                            height: '100%'
                        },
                        "& .slick-list": {
                            height: '100%',
                        },
                        "& .slick-track": {
                            display: "flex",
                            height: '100%',
                            width: '100%',
                            gap: '58px',
                            alignItems: "center",
                            justifyContent: "center",
                            padding: '0px 40px'
                        },
                        "& .slick-slide": {
                            height: '100%',
                            width: '60px !important',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        },
                        "& .slick-arrow": {
                            color: "#8e8e8e",
                            zIndex: " 99999999",
                            height: "100%",
                            fontSize: "32px",
                            "&.slick-prev": {
                                left: "0%",
                            },
                            "&.slick-next": {
                                right: "0%",
                            },
                        },
                    }}
                >
                    <Slider {...partnersSettings}>
                        {aboutState !== null && aboutState
                            ? aboutState.partnersImageEntities.length !== 0
                                ? aboutState.partnersImageEntities.map((item, i) => (
                                    <div key={i} style={{ width: '100%', display: 'flex', }}>
                                        <img
                                            className="md:w-[40px] sm:w-[30px] md:h-[40px] sm:h-[30px]"
                                            src={item.partnersImageSource}
                                            alt=""
                                        />
                                    </div>
                                ))
                                : ""
                            : ""}
                    </Slider>
                </Box>
            </Box>
        </>
    )
}

export default Partners