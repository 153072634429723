import React, { useState, useEffect, useContext } from "react";

import { List, ListItem, Box } from "@mui/material";

import CategoryMenuItem from "./CategoryMenuItem";

import { loadCategoryList } from "../services/services";

import { BsChevronRight } from "react-icons/bs";

import DiscountIcon from "@mui/icons-material/Discount";
import { useLanguage } from "../contexts/LanguageContext";
import CloseIcon from '@mui/icons-material/Close';
import MobileCategory from "./MobileCategory";

const CategoryMenu = ({ seHeaderDropMenuIsOpen, closeMenuHandler }) => {
  const [ovarleyIsOpen, setOvarleyIsOpen] = useState(false);
  const [categoryList, setCategoryList] = useState(null);
  const { language } = useLanguage();


  const handleClick = () => {
    closeMenuHandler();
  };

  useEffect(() => {
    (async () => {
      setCategoryList(await loadCategoryList());
    })();
  }, []);

  // const categoryMenuList= [
  //     {
  //         menuText:'Smartfonlar',
  //         icon:<PhoneIphoneIcon/>
  //         // subGroup:[
  //             // {
  //                 // linkText:''
  //             // }
  //         // ]
  //     },
  //     {
  //         menuText:'Smart qadjedlər',
  //         icon:<WatchIcon/>
  //     },
  //     {
  //         menuText:'Notbuklar,PK,Planşetlər',
  //         icon:<ComputerIcon/>
  //     },
  //     {
  //         menuText:'Mətbəx üçün məişət texnikası',
  //         icon:<KitchenIcon/>
  //     },
  //     {
  //         menuText:'Ev üçün məişət texnikaları',
  //         icon:<LocalLaundryServiceIcon/>
  //     },
  //     {
  //         menuText:'TV,audio,video',
  //         icon:<RadioIcon/>
  //     },
  //     {
  //         menuText:'Foto texnika',
  //         icon:<CameraAltIcon/>
  //     },
  //     {
  //         menuText:'Hobbi və əyləncə',
  //         icon:<VideogameAssetIcon/>
  //     },
  //     {
  //         menuText:'Alətlər',
  //         icon:<HandymanIcon/>
  //     },
  //     {
  //         menuText:'Avtomobil məhsulları',
  //         icon:<DirectionsCarIcon/>
  //     },
  //     {
  //         menuText:'Mebel və tekstil',
  //         icon:<WeekendIcon/>
  //     },
  // ]

  return (
    <div className="flex w-[101.1%] h-auto md:h-full rounded-[4px]">
      {ovarleyIsOpen ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            left: "0%",
            top: "0%",
            // zIndex: "99999999999999999",
            // backgroundColor: "#0000005e",
            position: "fixed",
          }}
          className="menuOvarley"
        ></Box>
      ) : (
        ""
      )}
      <div
        style={{
          width: "100%",
          height: '100%',
          margin: "0px",
          position: "relative",
          zIndex: "0",
          backgroundColor: "#fff",
          // borderRadius:'2px',
        }}
      // onMouseEnter={()=>setovarleyIsOpen(true)} onMouseLeave={()=>setovarleyIsOpen(false)}
      >
        <div className="flex justify-end py-4 md:hidden">
          <CloseIcon onClick={() => handleClick()} />
        </div>
        <List
        className="category max-h-[85vh] overflow-y-auto"

          sx={{
            width: "100%",
            overflowY: "auto", 
            maxHeight: "85vh",
            borderRadius: "4px",
            padding: "0px",
            // overflow: "hidden",
            position: "static",
            zIndex: "9999",
            backgroundColor: "#fff",
            "& li": {
              padding: '20px 5px',
              // border: "1px solid #8e8e8e",
              width: '90%',
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
              cursor: "pointer",
              transition: "all .2s ease",
              lineHeight: "15px",
              justifyContent: 'space-between',
              "&.categoryMenuItem:hover": {
                backgroundColor: "#eeeff2",
                "& svg": {},
              },
              "& svg": {
                marginRight: "10px",
                fontSize: "15px",
                color: '#000',
                fontWeight: '700'
              },
            },
          }}
        >


          <div className="sm:!hidden md:!block">
            {categoryList !== null
              ? categoryList.map((menuItem, i) => (
                <CategoryMenuItem
                  seHeaderDropMenuIsOpen={seHeaderDropMenuIsOpen}
                  setOvarleyIsOpen={setOvarleyIsOpen}
                  key={i}
                  categoryId={menuItem.id}>
                  {/* <span
                    style={{
                      width: "25px",
                      height: "25px",
                      marginRight: "12.5px",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      src={menuItem.categoryIcon}
                      // alt="Category icon image"
                    />
                  </span> */}
                  <div
                    className='pl-1'
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "400",
                        color: "#000",
                        fontSize: '16px',
                        // textTransform:'capitalize',
                      }}
                    >
                      {language == "az" && menuItem.name 
                        ? menuItem.name
                        : language == "ru" && menuItem.pendingCategoryTwo
                          ? menuItem.pendingCategoryTwo
                          : language == "en" && menuItem.pendingCategoryThree
                            ? menuItem.pendingCategoryThree
                            : null}
                    </span>
                    <BsChevronRight style={{ fontSize: "15px", color: '#000', margin: '0' }} />
                  </div>
                </CategoryMenuItem>

              ))
              : ""}
          </div>

          <div className="sm:!block md:!hidden">
            {categoryList !== null
              ? categoryList.map((menuItem, i) => (
                <MobileCategory
                  seHeaderDropMenuIsOpen={seHeaderDropMenuIsOpen}
                  setOvarleyIsOpen={setOvarleyIsOpen}
                  key={i}
                  categoryId={menuItem.id}>
                  <div
                    className='pl-1'
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "400",
                        color: "#000",
                        fontSize: '16px',
                        // textTransform:'capitalize',
                      }}
                    >
                      {language == "az" && menuItem.name
                        ? menuItem.name
                        : language == "ru" && menuItem.pendingCategoryTwo
                          ? menuItem.pendingCategoryTwo
                          : language == "en" && menuItem.pendingCategoryThree
                            ? menuItem.pendingCategoryThree
                            : null}
                    </span>
                    <BsChevronRight style={{ fontSize: "15px", color: '#000', margin: '0' }} />
                  </div>
                </MobileCategory>

              ))
              : ""}
          </div>
        </List>
      </div>
      {/* <div style={{
        marginLeft: '18px',
        borderRight: '1px solid #8e8e8e'
      }}>
      </div> */}
    </div>
  );
};

export default CategoryMenu;
