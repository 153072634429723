import React,{useState,useEffect} from 'react';
import {
    ListItem,
    Box,
    Grid,
} from '@mui/material';

import {Link} from 'react-router-dom';


import {loadSubParentCategoryListById} from '../services/services';

import SubParentCategoryMenuItem from './SubParentCategoryMenuItem';

const CategoryMenuItem = ({children,categoryId,setOvarleyIsOpen}) => {
    
    const [menuIsOpen,setMenuIsOpen]= useState(false);
    const [subParentCategoryList,setSubParentCategoryList] = useState(null);

    const menuOpenHandler=(e,id)=>{
        (async()=>{
            // setSubCategoryList(await loadSubCategories(id));
        })()
        // if(categoryId !==5 && categoryId !== 2){
            setMenuIsOpen(true)
            setOvarleyIsOpen(true)
        // }
    }

    const menuCloseHandler=(e)=>{
        setOvarleyIsOpen(false)
        setMenuIsOpen(false)
    }

    useEffect(()=>{
        (async()=>{
            setSubParentCategoryList(await loadSubParentCategoryListById(categoryId))
        })()
    },[])


    return (
        <>
            {/* <ListItem 
                className="categoryMenuItem"
                sx={{
                    position:'static',
                    width: '215px',
                    borderRadius:'4px',
                    // borderBottom:'.2px solid lightgrey',
                }}
                onMouseEnter={(e)=>menuOpenHandler(e,categoryId)}
                onMouseLeave={menuCloseHandler}
            >
                <Box
                    sx={{
                        position:'absolute',
                        left:'100%',
                        top:'0%',
                        height:'100%',
                        zIndex:'99',
                        width:menuIsOpen?'890px':'0px',
                        opacity:!menuIsOpen?'0':'1',
                        transition:'all .3s ease',
                        padding:'10px 0px',
                        backgroundColor:'#fff',
                        borderRadius:'4px',
                        overflow:'scroll',
                        overflowX:'hidden',
                        display: 'flex',
                        width: '955px',
                        '&::-webkit-scrollbar':{
                            width:'5px',
                            backgroundColor:'red',
                        },
                        '&::-webkit-scrollbar-thumb':{
                            backgroundColor:'#ff0009',
                            borderRadius:'8px',
                        },
                        '&::-webkit-scrollbar-track':{
                            backgroundColor:'lightgrey',
                            borderRadius:'8px',
                        },
                        '& a':{
                            textDecoration:'none',
                            color:'#000',
                            width:'33%',
                            fontSize:'14px',
                            boxSizing:'border-box',
                            padding:'2.5px 7.5px',
                            marginBottom:'10px',
                            fontWeight:'500',
                            display:'inline-block',
                            transition:'all .5s ease',
                            '&:hover':{
                                // color:'red',
                            }
                        }
                    }}
                >
                    {
                        subParentCategoryList !== null ?
                            subParentCategoryList.map((subParentCategoryItem,i)=>(
                                <SubParentCategoryMenuItem
                                    key={i}
                                    subParentCategoryItem={subParentCategoryItem}
                                />
                            ))
                        :''
                    }
                </Box>
            {
                children
            }  
            </ListItem> */}



            <ListItem 
            className="sm:hidden md:block"
            sx={{
                position:'static',
                width: '215px',
                borderRadius:'4px',

                // borderBottom:'.2px solid lightgrey',
            }}
            onMouseEnter={(e)=>menuOpenHandler(e,categoryId)}
            onMouseLeave={menuCloseHandler}
        >
            <Grid container
            className='sm:!hidden md:block'
                sx={{
                    display: 'flex',
                    position:'absolute',
                    left:'100%',
                    top:'0%',
                    height:'100%',
                    width:menuIsOpen?'800px':'0px',
                    opacity:!menuIsOpen?'0':'1',
                    transition:'all .3s ease',
                    padding:'10px 0px',
                    backgroundColor:'#fff',
                    borderRadius:'2px',
                    overflow:'scroll',
                    overflowX:'hidden',
                    '.grid-item:nth-child(4)': {
                        gridRow: 'span 2', /* Spans across 2 rows */
                      },
                    'MuiBox-root': {
                        width: '10px',
                    },
                    '&::-webkit-scrollbar':{
                        width:'5px',
                        backgroundColor:'red',
                    },
                    '&::-webkit-scrollbar-thumb':{
                        backgroundColor:'#ff0009',
                        borderRadius:'8px',
                    },
                    '&::-webkit-scrollbar-track':{
                        backgroundColor:'lightgrey',
                        borderRadius:'8px',
                    },
                    '& a':{
                        textDecoration:'none',
                        color:'#000',
                        width:'33%',
                        fontSize:'14px',
                        boxSizing:'border-box',
                        padding:'2.5px 7.5px',
                        marginBottom:'10px',
                        fontWeight:'500',
                        display:'inline-block',
                        transition:'all .5s ease',
                        '&:hover':{
                            // color:'red',
                        }
                    }
                }}
            >
                {
                    subParentCategoryList !== null ?
                        subParentCategoryList.map((subParentCategoryItem,i)=>(
                            <SubParentCategoryMenuItem
                                key={i}
                                subParentCategoryItem={subParentCategoryItem}
                            />
                        ))
                    :''
                }
            </Grid>
          {
            children
          }  
        </ListItem>
        </>
    );
}

export default CategoryMenuItem;