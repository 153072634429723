import { combineReducers } from "redux";

import {buyWithCashReducer} from './buyWithCashReducer';
import {basketReducer} from './basketReducer';
import { productLoadReducer } from "./productLoadRedcuer";


const rootReducer = combineReducers({
    buyWithCashReducer,
    productLoadReducer,
    basketReducer,
    
})

export default rootReducer;