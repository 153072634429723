import { useEffect, Suspense } from "react";
import FullLayout from "./layout/FullLayout";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Details from "./pages/Details";
import Branches from "./pages/Branches";
import ChildCategoryProducts from "./pages/ChildCategoryProducts";
import SearchResults from "./pages/SearchResults";
import ParentCategoryProducts from "./pages/ParentCategoryProducts";
import Basket from "./pages/Basket";
import Contact from "./pages/Contact";

import "bootstrap/dist/css/bootstrap.css";
import "./App.css";

import { connect } from "react-redux";

import { basketInitListAction } from "./redux/actions/basketAction";
import Storage from "./redux/Storage";
import { checkProductWithRelatedBaseId } from "./services/services";

// const checkHandler= async(item)=>{
//   const res = await checkProductWithRelatedBaseId(item.relatedBaseId);
//   if(res?.status===200){
//     // console.log(item)
//     return item
//   }
// }

import axios from "axios";
import MapWithMarkers from "./pages/MapWithMarkers";

function App({ basketInitListAction }) {
  useEffect(() => {
    const list = Storage._getStorageList();
    if (list) {
      const checkedList = [];
      list.forEach((item, i) => {
        (async () => {
          if (await checkProductWithRelatedBaseId(item.relatedBaseId)) {
            checkedList.push(item);
          }
        })();
      });
      setTimeout(() => {
        // console.log(checkedList)
        basketInitListAction(checkedList);
      }, 1000);
    }
  }, []);

  return (
    <div className="App">
      <FullLayout>
        {/* <Suspense fallback={<TestFallBack/>}> */}

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/basket" element={<Basket />} />
          {/* <Route exact path="/magazalarr" element={<Branches />} /> */}
          <Route exact path="/magazalar" element={<MapWithMarkers />} />
          <Route
            exact
            path="/axtaris-neticeleri/:key"
            element={<SearchResults />}
          />
          <Route
            exact
            path="/neticeler/:type/:id"
            element={<ChildCategoryProducts />}
          />
          {/* <Route exact path='/categoryProducts/:id' element={<ParentCategoryProducts/>} /> */}
          <Route exact path="/details/:id" element={<Details />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
        {/* </Suspense> */}
      </FullLayout>
    </div>
  );
}

const mepStateToProps = (state) => state;
const mapDispatchToProps = {
  basketInitListAction,
};

export default connect(mepStateToProps, mapDispatchToProps)(App);
