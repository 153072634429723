import Types from '../types/types';

const reducerState={
    buyWithCashItem:null,
} 

export const buyWithCashReducer=(
    state=reducerState,
    {type,payload},
)=>{

    switch(type){
        case Types.BUYWITHCASH:
            return {
                ...reducerState,
                buyWithCashItem:payload,
            }
        case Types.CLEANBUYWITHCASH:
            return {
                ...reducerState,
                buyWithCashItem:null,
            }
        default: return state;
    }
}