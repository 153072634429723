import React from 'react';

import {
    Box,
    List,
    ListItem,
    Button,
    Typography,
    FormControl,
    TextField,
    IconButton,
    Grid,
} from '@mui/material';
import { Link } from 'react-router-dom';

import testImg from '../assets/img/tempProductPictures/sliderPic.webp';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { connect } from 'react-redux';

import {
    basketIncreaseAction,
    basketDecreaseAction,
    basketResetAction,

    basketDeleteAction,
} from '../redux/actions/basketAction';

import BasketStorage from '../redux/Storage';


const BasketItem = ({
    item,
    basketIncreaseAction,
    basketDecreaseAction,
    basketResetAction,

    basketDeleteAction,
}) => {
    // console.log(item)
    const increaseItemCountHandler = (item) => {
        basketIncreaseAction(item)
    }
    const decreaseItemCountHandler = (item) => {
        basketDecreaseAction(item)
    }
    const resetItemCountHandler = (item) => {
        basketResetAction(item)
    }

    const deleteItemHandler = (item) => {
        basketDeleteAction(item)
        BasketStorage._removeItemStorage(item);
    }
    // console.log(item)
    return (
        <>



            <Box
                className="flex row justify-between mb-[30px] mt-[10px] p-[25px] w-100 rounded-lg"
                sx={{
                    boxShadow: '1px 1px 5px 1px rgba(0, 0, 0, 0.05)',
                }}
            >


                <Box className="flex items-center w-[20%] ">
                    <Link to={`/details/${item.id}`} className='flex' style={{
                        display: 'flex', gap: '10px',
                        alignItems: 'center',
                    }} >
                        <Box
                            sx={{
                                width: '70px',
                                height: '70px',
                                '& img': {
                                    objectFit: 'contain',
                                    width: '100%',
                                    height: '100%',
                                },
                            }}
                        >

                            <img src={item.images[0]?.source} alt="" />
                        </Box>
                        <Typography
                            className="text-black"
                            sx={{
                                fontWeight: 'bold',
                                width: '100%',
                            }}
                        >
                            <span className='sm:hidden md:block basketPrice w-100'>
                                {
                                    item.name
                                }
                            </span>
                        </Typography>
                    </Link>

                </Box>
                <Box className="flex items-center w-[20%]  justify-center">
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        <span
                            className='basketPrice'
                            style={{
                                textDecoration: item?.productDiscount ? 'line-through' : 'normal',
                                color: item?.productDiscount ? '#8E8E8E' : '#ff0000',
                                fontSize: item?.productDiscount ? '' : '23px',
                            }}
                        >
                            {
                                item.price ?
                                    '₼' + Number(item.price) * Number(item.count)
                                    : ''
                            }
                        </span>
                        <span
                            className='basketDiscountPrice'
                        >
                            {
                                item.productDiscount ?
                                    <Typography
                                        sx={{
                                            fontSize: '23px',
                                            fontWeight: 'bold',
                                            color: '#ff0000',
                                        }}
                                    >
                                        ₼{Number(item.productDiscountPrice) * Number(item.count)}
                                    </Typography>
                                    : ''
                            }
                        </span>
                    </Typography>
                </Box>
                <Box className="flex items-center w-[20%]  justify-center">
                    <Box
                        className="priceControlButtons"
                        sx={{
                            // marginRight: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            border: '.5px solid rgba(0, 0, 0, 0.4)',
                            borderRadius: '4px',
                            // width: '72px',
                            height: '44px',
                            padding: '6px 12px',
                            width: '72px'
                        }}
                    >
                        <div className='flex content-center items-center ml-[7px]'>
                            <span >{item.count}</span>
                            <div>
                                <IconButton className='btnInc' onClick={() => increaseItemCountHandler(item)}>
                                    <KeyboardArrowUpIcon className='increaseIcon' sx={{ fontSize: '20px', marginBottom: '-10px', color: 'black' }} />
                                </IconButton>
                                <IconButton className='btnDec' onClick={() => decreaseItemCountHandler(item)}>
                                    <KeyboardArrowDownIcon className='decrease' sx={{ fontSize: '20px', marginBottom: '5px', marginTop: '-10px', color: 'black', }} />
                                </IconButton>
                            </div>
                        </div>
                    </Box>

                </Box>
                <Box className="flex items-center w-[20%]  justify-end">

                    <IconButton
                        sx={{
                            width: '50px',
                            height: '50px',
                            '& svg': {
                                fontSize: '32px',
                                color: '#ff0009',
                                borderRadius: '50%',
                            }
                        }}
                        onClick={() => deleteItemHandler(item)}
                    >
                        <DeleteOutlineIcon className='deleteIcon' />
                    </IconButton>
                </Box>
            </Box>
        </>
    );
}

const mapStateToProps = state => state;
const mapDispatchToProps = {
    basketIncreaseAction,
    basketDecreaseAction,
    basketResetAction,
    basketDeleteAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(BasketItem);
