import React,{useState,useEffect} from 'react';
import {
    Box,
    Grid,
    Typography,
    FormGroup,
    InputLabel,
    TextField,
    Button,
} from '@mui/material';


import {

} from '../services/services';

import {
    ImLocation2,
    ImPhone,
} from 'react-icons/im';
import {
    FiMail
} from 'react-icons/fi';
import {
    BsUpload
}from 'react-icons/bs'

import {
    FaRegCheckCircle
} from 'react-icons/fa';

import SuccessPopUpGeneral from '../components/SuccessPopUpGeneral';
import ErrorPopUpGeneral from '../components/ErrorPopUp';

import {
    personalFormSend,
    personalFormIdSend,

    corporativeFormSend,
    corporativeFormIdSend,
    corporativeFileSend,
    clickcerIncreaser,
    loadAbout,
} from '../services/services';

import ContactFormSuccess from '../components/ContactFormSuccess';
import ContactFormError from '../components/ContactFormError';
import { useTranslation } from 'react-i18next';


const Contact = () => {
    const {t} = useTranslation()
    const [aboutInfos,setAboutInfos] = useState(null);
    const [contactFormSuccess,setContactFormSuccess]=useState(false);
    const [contactFormError,setContactFormError]=useState(false);
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
    useEffect(()=>{
        (async()=>{
            setAboutInfos(await loadAbout());
        })()
        return ()=>{
            (async()=>{
                await clickcerIncreaser({
                    "aboutClicker": false,
                    "basketClicker": false,
                    "branchClicker": false,
                    "contactClicker": true,
                    "counter": 0,
                    "discountClicker": false,
                    "facebookClicker": false,
                    "homeClicker": false,
                    "instagramClicker": false,
                    "pendingClickerFour": false,
                    "pendingClickerOne": false,
                    "pendingClickerThree": false,
                    "pendingClickerTwo": false,
                    "productClicker": false,
                    "seasonClicker": false,
                    "weekClicker": false
                  })
            })()
        }
    },[])



    const [contactCustomerDetailsForm,setContactCustomerDetailsForm] = useState({
        name:'',
        surname:'',
        phoneNumber:'',
        mail:'',
        text:'',
        pendingContactFour: "string",
        pendingContactOne: "string",
        pendingContactThree: "string",
        pendingContactTwo: "string",
            })
    const contactFormChangeHandler = (e)=>{
        setContactCustomerDetailsForm({
            ...contactCustomerDetailsForm,
            [e.target.name]:e.target.value,
        })
    }
    
    const contactFormSubmitHandler=(e)=>{
        (async()=>{
            if(contactCustomerDetailsForm.name !=='' && contactCustomerDetailsForm.surname!=='' && contactCustomerDetailsForm.phoneNumber!=='' && contactCustomerDetailsForm.text!=='' ){
                const res = await personalFormSend(contactCustomerDetailsForm);
                // setIsSuccess(true)
                if(res.status === 200 || res.status === 201 ){
                    //  setIsSuccess(true)
                    const idRes = await personalFormIdSend(res.data.id);
                    if(idRes.status===200 || idRes.status===201){
                        // window.location.reaload();
                        setContactFormSuccess(true)
                    }else{
                        setContactFormError(true)
                    }
                }
            }
        })()
        e.preventDefault();
    }





    const [isSuccess,setIsSuccess] = useState(false);
    const [isError,setIsError] = useState(false);

    const [contactCorporativeDetailsForm,setContactCorporativeDetailsForm] = useState({
        companyName:'',
        mail:'',
        personName:'',
        phoneNumber:'',
    })
    
    const [fileChoosed,setFileChoosed] = useState(false);

    const FormData = require('form-data');

    let corporativFile = new FormData();
    
    const chooseCorporativeFile = (e)=>{
        corporativFile.append('file',e.target.files[0])
        setFileChoosed(true)
    }
    
    const deleteCorporativeFile = ()=>{
        corporativFile.delete("file");
        setFileChoosed(false);
    }
    
    const contactCorporativeFormChangeHandler=(e)=>{
        setContactCorporativeDetailsForm({
            ...contactCorporativeDetailsForm,
            [e.target.name]:e.target.value,
        })
    }
    

    const contactCorporativeFormSubmitHandler =(e)=>{
        (async()=>{
            if(contactCorporativeDetailsForm.personName !=='' && contactCorporativeDetailsForm.companyName!=='' && contactCorporativeDetailsForm.phoneNumber!=='' ){
                const corporativeFormRes = await corporativeFormSend(contactCorporativeDetailsForm);
                if(corporativeFormRes.status===200 || corporativeFormRes.status===201){

                        if(fileChoosed){
                            (async()=>{
                                setTimeout(()=>{
                                    (async()=>{
                                        setTimeout(()=>{
                                            (async()=>{
                                                const res = await corporativeFileSend(corporativeFormRes.data.id,corporativFile)
                                                if(res.status===200 || res.status===201){
                                                    const res  = await corporativeFormIdSend(corporativeFormRes.data.id);
                                                    if(res.status===200 || res.status===201){
                                                        setIsSuccess(true)
                                                        
                                                    }else{
                                                        setIsError(true);
                                                    }
                                                }

                                            })()
                                        },2000)
                                    })()
                                },2000)
                            })()
                           
                        }
                       
                }
            }
        })()
        e.preventDefault();
    }
    



    return (
        <Box className="mb-[80px]">
            

            {
                contactFormSuccess ?
                    <ContactFormSuccess close={setContactFormSuccess}/>
                :''
            }
            {
                contactFormError ?
                    <ContactFormError close={setContactFormError}/>
                :''
            }
            {
                isSuccess ?
                    <SuccessPopUpGeneral/>
                :''
            }
            {
                isError ?
                    <ErrorPopUpGeneral/>
                :''
            }
            <Box>
                <Typography className="pageHeading">
                    {t("Fərdi Əlaqə")}
                </Typography>
            </Box>
            <Box
                className="contactWrapper"
                sx={{
                    margin:'0px auto',
                    padding:'0px 116.5px',
                }}
            >
                <Grid container className='personalFormWrapper'>
                    <Grid 
                        item 
                        md={6}
                        sm={12}
                        className='contactAdressStage'
                        sx={{
                            display:'flex',
                            alignItems:'startx',
                            '& a':{
                                marginBottom:'25px',
                                color:'#8e8e8e',
                                textDecoration:'none',
                                display:'flex',
                                alignItems:'end',
                                '& svg':{
                                    fontSize:'28px',
                                    color:'#ed1c22',
                                    marginRight:'7.5px',
                                },
                                '& p':{
                                    margin:'0',
                                },
                            },
                        }}
                    >
                        <ul>
                            <li>
                                <a style={{color:'#8e8e8e',}}>
                                    <span>
                                        <ImLocation2/>
                                    </span>
                                    <p>
                                        {aboutInfos?.address}
                                    </p>
                                </a>
                            </li>
                            <li>
                                <a href={aboutInfos?.mail}>
                                    <span>
                                        <FiMail/>
                                    </span>
                                    <p>
                                        {aboutInfos?.mail}
                                    </p>
                                </a>
                            </li>
                            <li>
                                <a href={aboutInfos?.phoneNumber}>
                                    <span>
                                        <ImPhone/>
                                    </span>
                                    <p>
                                    {aboutInfos?.phoneNumber}
                                    </p>
                                </a>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item md={6} className='contactFormsStage'>
                        <form onSubmit={contactFormSubmitHandler}>
                            <Grid container spacing={3}>
                                <Grid item md={6} sm={6} xs={12}>
                                    <FormGroup>
                                        <InputLabel 
                                            htmlFor="name"
                                            sx={{
                                                marginBottom:'7.5px',
                                            }}
                                        >
                                            <b>
                                                {t("Ad")}
                                            </b>
                                        </InputLabel>
                                        <TextField
                                            id="name"
                                            name="name"
                                            placeholder={t("Ad")}
                                            size="small"
                                            value={contactCustomerDetailsForm.name}
                                            type="text"
                                            onChange={contactFormChangeHandler}
                                        />
                                    </FormGroup>

                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <FormGroup>
                                        <InputLabel 
                                            htmlFor="surname"
                                            sx={{
                                                marginBottom:'7.5px',
                                            }}
                                        >
                                            <b>
                                                {t("Soyad")}
                                            </b>
                                        </InputLabel>
                                        <TextField
                                            id="surname"
                                            name="surname"
                                            placeholder={t("Soyad")}
                                            size="small"
                                            value={contactCustomerDetailsForm.surname}
                                            type="text"
                                            onChange={contactFormChangeHandler}
                                        />
                                    </FormGroup>

                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <FormGroup>
                                        <InputLabel 
                                            htmlFor="phoneNumber"
                                            sx={{
                                                marginBottom:'7.5px',
                                            }}
                                        >
                                            <b>
                                            {t("Telefon Nömrəsi")}
                                            </b>
                                        </InputLabel>
                                        <TextField
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            placeholder={t("Telefon Nömrəsi")}
                                            size="small"
                                            value={contactCustomerDetailsForm.phoneNumber}
                                            type="number"
                                            onChange={contactFormChangeHandler}
                                        />
                                    </FormGroup>

                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <FormGroup>
                                        <InputLabel 
                                            htmlFor="mail"
                                            sx={{
                                                marginBottom:'7.5px',
                                            }}
                                        >
                                            <b>
                                                {t("Email Ünvanı")}
                                            </b>
                                        </InputLabel>
                                        <TextField
                                            id="mail"
                                            name="mail"
                                            placeholder={t("Email Ünvanı")}
                                            type="email"
                                            size="small"
                                            value={contactCustomerDetailsForm.mail}
                                            onChange={contactFormChangeHandler}
                                        />
                                    </FormGroup>

                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <FormGroup>
                                        <InputLabel 
                                            htmlFor="text"
                                            sx={{
                                                marginBottom:'7.5px',
                                            }}
                                        >
                                            <b>
                                                {t("Qeyd")}
                                            </b>
                                        </InputLabel>
                                        <TextField
                                            id="text"
                                            name="text"
                                            placeholder={t("Qeyd")}
                                            size="small"
                                            value={contactCustomerDetailsForm.text}
                                            type="text"
                                            multiline
                                            // maxRows={4}
                                            minRows={5}
                                            onChange={contactFormChangeHandler}
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Box
                                        sx={{
                                            textAlign:'right'
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            sx={{
                                                textTransform:'capitalize',
                                                padding:'7.5px 75px',
                                                backgroundColor:'#ed1c22',
                                                '&:hover':{
                                                    backgroundColor:'#ed1c22',
                                                    
                                                }
                                            }}
                                        >
                                            {t("Göndər")}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Typography className="pageHeading">
                    {t("Korparativ Əlaqə")}
                </Typography>
            </Box>
            <Box
                className="contactWrapper"
                sx={{
                    margin:'0px auto',
                    padding:'0px 116.5px',
                }}
            >
                <Grid container>
                    <Grid item md={12}>
                        <form 
                            onSubmit={contactCorporativeFormSubmitHandler}
                        >
                            <Grid container spacing={3}>
                                <Grid item md={6} sm={6} xs={12}>
                                    <FormGroup>
                                        <InputLabel 
                                            htmlFor="personName"
                                            sx={{
                                                marginBottom:'7.5px',
                                            }}
                                        >
                                            <b>
                                                {t("Şəxs Adı")}
                                            </b>
                                        </InputLabel>
                                        <TextField
                                            id="personName"
                                            name="personName"
                                            placeholder={t("Şəxs Adı")}
                                            size="small"
                                            value={contactCorporativeDetailsForm.personName}
                                            type="text"
                                            onChange={contactCorporativeFormChangeHandler}
                                        />
                                    </FormGroup>

                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <FormGroup>
                                        <InputLabel 
                                            htmlFor="companyName"
                                            sx={{
                                                marginBottom:'7.5px',
                                            }}
                                        >
                                            <b>
                                                {t("Şirkət Adı")}
                                            </b>
                                        </InputLabel>
                                        <TextField
                                            id="companyName"
                                            name="companyName"
                                            placeholder={t("Şirkət Adı")}
                                            size="small"
                                            value={contactCorporativeDetailsForm.companyName}
                                            type="text"
                                            onChange={contactCorporativeFormChangeHandler}
                                        />
                                    </FormGroup>

                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <FormGroup>
                                        <InputLabel 
                                            htmlFor="phoneNumber"
                                            sx={{
                                                marginBottom:'7.5px',
                                            }}
                                        >
                                            <b>
                                                {t("Telefon Nömrəsi")}
                                            </b>
                                        </InputLabel>
                                        <TextField
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            placeholder={t("Telefon Nömrəsi")}
                                            size="small"
                                            value={contactCorporativeDetailsForm.phoneNumber}
                                            type="number"
                                            onChange={contactCorporativeFormChangeHandler}
                                        />
                                    </FormGroup>

                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <FormGroup>
                                        <InputLabel 
                                            htmlFor="mail"
                                            sx={{
                                                marginBottom:'7.5px',
                                            }}
                                        >
                                            <b>
                                                {t("Email Ünvanı")}
                                            </b>
                                        </InputLabel>
                                        <TextField
                                            id="mail"
                                            name="mail"
                                            placeholder={t("Email Ünvanı")}
                                            type="email"
                                            size="small"
                                            value={contactCorporativeDetailsForm.mail}
                                            onChange={contactCorporativeFormChangeHandler}
                                        />
                                    </FormGroup>

                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <FormGroup>
                                        <InputLabel 
                                            // htmlFor="coporativeFile"
                                            sx={{
                                                marginBottom:'7.5px',
                                            }}
                                        >
                                            <b>
                                                {t("Fayl Seçin")}
                                            </b>
                                        </InputLabel>
                                        <Box
                                            sx={{
                                                overflow:'hidden',
                                                borderRadius: '4px',
                                                '& label':{
                                                    width: '100%',
                                                    border: '0.5px solid lightgrey',
                                                    borderRadius: '4px',
                                                    padding: '30px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontSize: '24px',
                                                    color: '#8e8e8e',
                                                    cursor: 'pointer',
                                                },
                                                position:'relative',
                                            }}
                                        >
                                            {
                                                fileChoosed?
                                                    <>
                                                       
                                                        <Box
                                                            sx={{
                                                                position:'absolute',
                                                                left:'0%',
                                                                top:'0%',
                                                                width:'100%',
                                                                height:'100%',
                                                                background:'#fff',
                                                                border:'.5px solid lightgrey',
                                                                borderRadius:'4px',
                                                                display:'flex',
                                                                flexDirection:'column',
                                                                alignItems:'center',
                                                                justifyContent:'center',
                                                                '& svg':{
                                                                    fontSize:'42px',
                                                                    color:'#19ae19',
                                                                }
                                                            }}
                                                        >
                                                             <span style={{marginBottom:'15px',}}>
                                                                <FaRegCheckCircle/>
                                                             </span>
                                                            <Button
                                                                variant='contained'
                                                                onClick={deleteCorporativeFile}
                                                                sx={{
                                                                    textTransform:'capitalize',
                                                                    color:"#fff",
                                                                    backgroundColor:'#ed1c22',
                                                                    '&:hover':{
                                                                        color:"#fff",
                                                                        backgroundColor:'#ed1c22',
                                                                    }
                                                                }}
                                                            >
                                                                {t("Təmizlə")}
                                                            </Button>
                                                        </Box>
                                                    </>
                                                :''
                                            } 
                                            <label htmlFor="coporativeFile">
                                                <span>{t("Fayl Seçin")}</span>
                                                <BsUpload/>
                                            </label>
                                            <input
                                                hidden
                                                id="coporativeFile"
                                                name="coporativeFile"
                                                placeholder="Qeyd"
                                                size="small"
                                                // value={corporativFile}
                                                type="file"
                                                // multiline
                                                maxrows={4}
                                                // minRows={5}
                                                onChange={chooseCorporativeFile}
                                            />
                                        </Box>
                                    </FormGroup>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Box
                                        sx={{
                                            textAlign:'right'
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            sx={{
                                                textTransform:'capitalize',
                                                padding:'7.5px 75px',
                                                backgroundColor:'#ed1c22',
                                                '&:hover':{
                                                    backgroundColor:'#ed1c22',
                                                    
                                                }
                                            }}
                                        >
                                            {t("Göndər")}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default Contact;
