import React, { useEffect, useState } from 'react';

import {
    Box,
    Grid,
    Card,
    CardContent,
    CardActions,
    CardMedia,
    Button,
    IconButton,
    Typography,
} from '@mui/material';

import { useParams } from 'react-router-dom';

import {
    sarchByKey,
} from '../services/services';

import ProductItem from '../components/ProductItem';

import BasketAddedSuccess from '../components/BasketAddedSuccess';
// import BasketAddedError from '../components/BasketAddedError';
import BasketAddedInfo from '../components/BaskertAddedInfo';

const SearchResults = () => {

    const [successPopUp, setSuccessPopUp] = useState(false);
    const [infoPopUp, setInfoPopUp] = useState(false);
    const [errorPopUp, setErrorPopUp] = useState(false);

    const [productsList, setProductsList] = useState(null);

    const { key } = useParams();
    // console.log(categoryId,subcategoryId);

    useEffect(() => {
        (async () => {
            setProductsList(await sarchByKey(key));
        })()
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        console.log(productsList);
    }, [key])
    return (
        <>

            {
                successPopUp ?
                    <BasketAddedSuccess close={setSuccessPopUp} />
                    : ''
            }
            {
                infoPopUp ?
                    <BasketAddedInfo close={setInfoPopUp} />
                    : ''
            }

            <Box
                className="px-[40px]"
                sx={{
                    marginTop: '35px',
                    '& .productItem': {
                        marginRight: '0px !important',
                    }
                }}
            >
                <span className='red text-4xl font-bold flex justify-center items-center h-full'>
                    {
                        productsList == 0 ?
                            'Nəticə tapılmadı!'
                            : ''
                    }
                </span>
                <Grid container spacing={2} className='searchResultsContainer'>
                    {
                        productsList !== null ?
                            productsList.map((productItem, i) => (
                                <Grid item className="searchResultProductItem" xs={12} sm={12} md={3} lg={3} key={i}>
                                    <ProductItem item={productItem} success={setSuccessPopUp} info={setInfoPopUp} />
                                </Grid>
                            ))
                            : ''
                    }
                </Grid>
            </Box>
        </>
    );
}

export default SearchResults;
