import React, { useState, useEffect } from 'react';

import {
    Box,
    Grid,
    TextField,
    Button,
    Typography,
} from '@mui/material';

import { Link, } from 'react-router-dom';

import FooterLogo from '../assets/img/barkod.png';
import FacebookLogo from '../assets/icons/Socials/Icon-Facebook.png';
import InstagramLogo from '../assets/icons/Socials/icon-instagram.png';
import LinkedinLogo from '../assets/icons/Socials/Icon-Linkedin.png';
import TelegramLogo from '../assets/icons/Socials/tg.png'
import WhatsappLogo from '../assets/icons/Socials/wp.png'

import { loadAbout } from '../services/services';
import { useTranslation } from 'react-i18next';


const Footer = () => {
    const { t } = useTranslation()
    const [aboutInformations, setAboutInformations] = useState(null);
    useEffect(() => {
        (async () => {
            setAboutInformations(await loadAbout());
        })()
    }, [])


    return (
        <Box
            className="footerArea "
            sx={{
                width: '100%',
                marginTop: 'auto',
                backgroundColor: '#000',
                color: '#fff',
            }}
        >
            <Box className=""
                sx={{
                    '& *': {
                        color: '#fff !important',
                    },
                    '& button': {
                        color: '#fff !important',
                    },
                    padding: '40px 33.5px',
                    paddingLeft: '55px',
                    textAlign: 'left',
                    paddingTop: '80px',
                }}
            >
                <div className="footerTopWrapper flex md:flex-row sm:flex-col">
                    <div className="sm:w-100 md:w-3/12">
                        <Box
                            className="linksArea"
                            sx={{
                                textAlign: 'left',
                            }}
                        >
                            <Box
                                sx={{
                                    marginBottom: '25px',
                                }}
                            >
                                <div
                                    variant="h5"
                                    className="footerHeading"
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        width: '127px',
                                        color: '#fff',
                                        width: '100%',
                                    }}
                                >
                                    {t("Şirkət")}
                                    <ul
                                        style={{
                                            color: '#fff',
                                            fontSize: '13px',
                                            marginTop: '4px',
                                            fontWeight: '400',
                                        }}
                                    >
                                        <li className='p-[5px] pl-0 pr-0' style={{
                                            color: '#A1A1A1',
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            opacity: '.7'
                                        }}>
                                            <Link
                                                style={{
                                                    color: '#fff',
                                                    textDecoration: 'none',
                                                }}
                                                to="/home">
                                                {t("Haqqımızda")}
                                            </Link>
                                        </li>
                                        <li className='p-[5px] pl-0 pr-0' style={{
                                            color: '#A1A1A1',
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            opacity: '.7'
                                        }}>
                                            <Link
                                                style={{
                                                    color: '#fff',
                                                    textDecoration: 'none',
                                                }}
                                                to="/about">
                                                {t("Mağazalar")}
                                            </Link>
                                        </li>
                                        <li className='p-[5px] pl-0 pr-0' style={{
                                            color: '#A1A1A1',
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            opacity: '.7'
                                        }}>
                                            <Link
                                                style={{
                                                    color: '#fff',
                                                    textDecoration: 'none',
                                                }}
                                                to="/magazalar">
                                                {t("Karyera")}
                                            </Link>
                                        </li>
                                        <li className='p-[5px] pl-0 pr-0' style={{
                                            color: '#A1A1A1',
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            opacity: '.7'
                                        }}>
                                            <Link
                                                style={{
                                                    color: '#fff',
                                                    textDecoration: 'none',
                                                }}
                                                to="/contact">
                                                {t("Çatdırılma qaydaları")}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </Box>
                        </Box>
                    </div>
                    <div className="sm:w-100 md:w-3/12">
                        <Box
                            className="linksArea"
                            sx={{
                                textAlign: 'left',
                            }}
                        >
                            <Box
                                sx={{
                                    marginBottom: '25px',
                                }}
                            >
                                <div
                                    variant="h5"
                                    className="footerHeading"
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        color: '#fff',
                                        width: '100%',
                                        '&::after': {
                                            content: "''",
                                            width: '100%',
                                            top: '90%',
                                            left: '0%',
                                            borderRadius: '8px',
                                            height: '2px',
                                            position: 'absolute',
                                            backgroundColor: '#fff',
                                        },
                                        '& li': {
                                            // marginBottom:'5px',
                                            // marginBottom:'5px',
                                            fontSize: '14px',

                                        }
                                    }}
                                >
                                    {t("Məlumat")}
                                    <ul
                                        style={{
                                            color: '#fff',
                                            fontSize: '13px',
                                            marginTop: '4px',
                                            fontWeight: '400',
                                        }}
                                    >
                                        <li className='p-[5px] pl-0 pr-0' style={{
                                            color: '#A1A1A1',
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            opacity: '.7'
                                        }}>
                                            <Link
                                                style={{
                                                    color: '#fff',
                                                    textDecoration: 'none',
                                                }}
                                                to="/home">
                                                {t("Korporativ satış")}
                                            </Link>
                                        </li>
                                        <li className='p-[5px] pl-0 pr-0' style={{
                                            color: '#A1A1A1',
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            opacity: '.7'
                                        }}>
                                            <Link
                                                style={{
                                                    color: '#fff',
                                                    textDecoration: 'none',
                                                }}
                                                to="/about">
                                                {t("Kampaniyalar")}
                                            </Link>
                                        </li>
                                        <li className='p-[5px] pl-0 pr-0' style={{
                                            color: '#A1A1A1',
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            opacity: '.7'
                                        }}>
                                            <Link
                                                style={{
                                                    color: '#fff',
                                                    textDecoration: 'none',
                                                }}
                                                to="/magazalar">
                                                {t("İstifadə qaydaları")}
                                            </Link>
                                        </li>
                                        <li className='p-[5px] pl-0 pr-0' style={{
                                            color: '#A1A1A1',
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            opacity: '.7'
                                        }}>
                                            <Link
                                                style={{
                                                    color: '#fff',
                                                    textDecoration: 'none',
                                                }}
                                                to="/contact">
                                                {t("Bloq")}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </Box>
                        </Box>
                    </div>
                    <div className="sm:w-100 md:w-4/12">
                        <Box
                            className="footer3Custom"
                            sx={{
                                // padding: '0px 35px',
                            }}
                        >
                            <Box
                                sx={{
                                    marginBottom: '25px',
                                }}
                            >

                                <div
                                    className="footerHeading"
                                    variant="h5"
                                    style={{
                                        fontFamily: 'poppins',
                                        textAlign: 'left',
                                        color: '#fff',
                                        display: 'inline',
                                        position: 'relative',
                                        width: '100%',
                                        marginTop: '15px',
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        '&::after': {
                                            content: "''",
                                            width: '100%',
                                            top: '90%',
                                            left: '0%',
                                            borderRadius: '8px',
                                            height: '2px',
                                            position: 'absolute',
                                            backgroundColor: '#fff',
                                        },
                                    }}
                                >
                                    {t("Əlaqə")}
                                </div>
                            </Box>
                            <ul
                                style={{
                                    color: '#Fff',
                                    fontSize: '13px',
                                }}
                            >
                                <li
                                    style={{
                                        color: '#A1A1A1',
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        marginTop: '24px',
                                    }}
                                >
                                    <Link
                                        style={{
                                            color: '#fff',
                                            textDecoration: 'none',
                                        }}
                                        to="/home">
                                        {t("*0092")}
                                    </Link>
                                </li>
                                <li
                                    style={{
                                        color: '#A1A1A1',
                                        fontWeight: '500',
                                        fontSize: '12px',
                                        opacity: '.7',
                                        marginTop: '25px',
                                        width: '189px',
                                    }}
                                >
                                    <Link
                                        style={{
                                            color: '#fff',
                                            textDecoration: 'none',
                                        }}
                                        to="/home">
                                        {t("Əhməd Rəcəbli 1/9, Azərbaycan, Bakı şəhəri")}
                                    </Link>
                                </li>

                            </ul>

                        </Box>
                    </div>
                    <div className="sm:w-100 md:w-4/12 sm:mt-[20px]">
                        <Box
                            sx={{
                                padding: ''
                            }}
                        >
                            <Box
                                sx={{
                                    marginBottom: '25px',
                                }}
                            >
                                <div
                                    className="footerHeading"
                                    variant="h5"
                                    style={{
                                        fontFamily: 'poppins',
                                        // fontSize:'18px',
                                        // textDecoration:'underline',
                                        textAlign: 'left',
                                        color: '#fff',
                                        display: 'inline',
                                        position: 'relative',
                                        width: '100%',
                                        marginTop: '15px',
                                        fontSize: '20px',
                                        fontWeight: '600',
                                        '&::after': {
                                            content: "''",
                                            width: '100%',
                                            top: '90%',
                                            left: '0%',
                                            borderRadius: '8px',
                                            height: '2px',
                                            position: 'absolute',
                                            backgroundColor: '#fff',
                                        },
                                    }}
                                >
                                    {t("Bizi izləyin")}
                                    <ul
                                        style={{
                                            color: '#fff',
                                            fontSize: '13px',
                                            marginTop: '4px',
                                            fontWeight: '400',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '24px',
                                        }}
                                    >
                                        <li className='p-[5px] pl-0 pr-0' style={{
                                            color: '#fff',
                                            fontWeight: '500',
                                            fontSize: '12px',
                                        }}>
                                            <Link
                                                style={{
                                                    color: '#fff',
                                                    textDecoration: 'none',
                                                }}
                                                target='_blank'
                                                to="https://www.facebook.com/BarkodElectronics">
                                                <img src={FacebookLogo} />
                                            </Link>
                                        </li>
                                        <li className='p-[5px] pl-0 pr-0' style={{
                                            color: '#fff',
                                            fontWeight: '500',
                                            fontSize: '12px',
                                        }}>
                                            <Link
                                                style={{
                                                    color: '#fff',
                                                    textDecoration: 'none',
                                                }}
                                                target='_blank'
                                                to="https://www.instagram.com/barkodelectronics.az/">
                                                <img src={InstagramLogo} />
                                            </Link>
                                        </li>
                                        <li className='p-[5px] pl-0 pr-0 flex items-center' style={{
                                            color: '#fff',
                                            fontWeight: '500',
                                            fontSize: '12px',
                                        }}>
                                            <Link
                                                style={{
                                                    color: '#000',
                                                    textDecoration: 'none',
                                                }}
                                                target='_blank'
                                                to="https://bit.ly/3T1tSfZ">
                                                <img src={WhatsappLogo} className='text-white w-[18px] h-[18px]'/>
                                            </Link>
                                        </li>
                                        <li className='p-[5px] pl-0 pr-0' style={{
                                            color: '#fff',
                                            fontWeight: '500',
                                            fontSize: '12px',
                                        }}>
                                            <Link
                                                style={{
                                                    color: '#fff',
                                                    textDecoration: 'none',
                                                }}
                                                target='_blank'
                                                to="https://www.linkedin.com/company/barkod-electronics/">
                                                <img src={LinkedinLogo} />
                                            </Link>
                                        </li>
                                       
                                        <li className='p-[5px] pl-0 pr-0' style={{
                                            color: '#fff',
                                            fontWeight: '500',
                                            fontSize: '12px',
                                        }}>
                                            <Link
                                                style={{
                                                    color: '#fff',
                                                    textDecoration: 'none',
                                                }}
                                                target='_blank'
                                                to="https://t.me/barkodelectronics">
                                                <img className='w-[24px] h-[24px]' src={TelegramLogo} />
                                            </Link>
                                        </li>
                                        

                                    </ul>
                                </div>
                            </Box>

                        </Box>
                    </div>
                </div>
            </Box>

            <Box
                className="footerDown flex justify-center items-center text-white opacity-40"
                sx={{
                    backgroundColor: '#000',
                    fontSize: '16px',
                    padding: '20px 116.5px',
                    borderTop: '.5px solid gray',
                }}
            >
                © 2024
                Barkod Electronics.
                All right reserved
            </Box>
        </Box>
    );
}

export default Footer;
