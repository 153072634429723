import {
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  NativeSelect,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import Logo from "../assets/img/barkod-logo-png.png";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import { useLanguage } from "../contexts/LanguageContext";
import HeaderBasketButton from "../components/HeaderBasketButton";
import MenuIcon from "@mui/icons-material/Menu";
import { loadAbout } from "../services/services";
import CategoryMenu from "../components/CategoryMenu";




function Headertest({
  setMobileMenuIsOpen,
  setHeaderDropMenuIsOpen,
  headerDropMenuIsOpen,
}) {
  const { t } = useTranslation();
  const { language, setLanguage } = useLanguage();

  const [aboutInformations, setAboutInformations] = useState(null);

  const dataElement = useRef();

  const navigate = useNavigate();

  const [headerSearchIsOpen, setHeaderSearchIsOpen] = useState(false);

  const [headerSearchText, setHeaderSearchText] = useState("");

  const changeHeaderSearchText = (e) => {
    // setHeaderSearchText()
    setHeaderSearchText(e.target.value);
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/axtaris-neticeleri/${headerSearchText}`);
  };
  // console.log()

  useEffect(() => {
    setHeaderSearchText("");
  }, [window.location.pathname]);

  return (
    <header
      style={{
        borderBottom: ".5px solid #8e8e8e52",
        width: "100%",
        position: "sticky",
        top: "-1px",
        zIndex: "999999999999",
        backgroundColor: "#fff",
        padding: "0 40px",
      }}
    >
      {/* <div className="flex justify-between">
        <Collapse
          in={headerDropMenuIsOpen}
          timeout="auto"
          unmountOnExit
          sx={{
            position: "absolute",
            top: "100%",
            transition: "all .5s ease",
            zIndex: "99999",
            // left: "55px",
          }}
        >
          <Box
            sx={{
              bottom: "0%",
            }}
          >
            <CategoryMenu setHeaderDropMenuIsOpen={setHeaderDropMenuIsOpen} />
          </Box>
        </Collapse>



        <Box
          sx={{
            width:'100px',
            display: "flex",
            alignItems: "center",
            justifycContent: "center",
          }}
        >
          <IconButton
            sx={{
              color: "#000",
              display: "flex",
              alignItems: "center",
              padding: "0px",
              width: "100%",
              transition: "all .5s ease",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#ff0009",
              },
            }}
          >
            <Box
              className="mobileMenuToggle"
              sx={{
                display: "none",
                "& svg": {
                  fontSize: "32.5px",
                  color: "#000",
                },
              }}
            >
              <MenuIcon
                onClick={() => setMobileMenuIsOpen(true)}
                size="small"
              />
            </Box>
          </IconButton>
        </Box>
        <Box
          className="mobileSearch"
          sx={{
            display: "none",
            "& svg": { color: "#000" },
            width: "138px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
        </Box>

        <IconButton onClick={() => setHeaderSearchIsOpen(!headerSearchIsOpen)}>
          <BsSearch />
        </IconButton>

       
      </div> */}
      <div className=" flex m-auto w-full items-center justify-center h-20 ">
        <div className="w-full flex items-center  md:gap-[131px] ">
          <div>
            <div
              className="app-logo w-[139px] flex items-center"
            >
              <Link to="/">
                <img src={Logo} alt="" />
              </Link>
            </div>
          </div>
          <div className="hidden md:block ">
            <ul
              style={{
                fontFamily: "poppins",
                fontSize: "16px",
                "& li": {
                  "& a": {
                    fontFamily: "Title/16PX Regular",
                    fontweight: "400",
                    linewidth: "24px",
                  },
                },
              }}
              className="flex w-[481px] gap-[56px] font-semibold leading-6 text-black "
            >
              <li>
                {/* about */}
                <Link className="text-black" to="/about">
                  {t("Haqqımızda")}
                </Link>
              </li>
              <li>
                {/* magazalar */}
                <Link className="text-black" to="/magazalar">
                  {t("Stores")}
                </Link>
              </li>
              <li>
                {/* contact */}
                <Link className="text-black" to="/contact">
                  {t("Korporativ satışlar")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex items-center">
          <div className="block md:hidden px-[5px]">
            <span>*0092</span>
          </div>
          <div className="flex items-center md:gap-2 order-2 md:order-1 ">
            {/* <FavoriteBorderOutlinedIcon className="cursor-pointer" /> */}
            <HeaderBasketButton />
          </div>
          <div className=" flex sm:order-1 md:order-2 md:ml-2">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <FormControl fullWidth>
                <NativeSelect
                  sx={{
                    borderRadius: 0,
                    width: "60px",
                    "&:before": {
                      borderColor: "white",
                      borderRadius: 0,
                    },
                    "&:after": {
                      borderColor: "white",
                      borderRadius: 0,
                    },
                    "&:hover": {
                      borderColor: "white",
                    },
                    "& .MuiSelect-icon": {
                      color: "white",
                    },
                    "& .MuiSelect-select": {
                      color: "white",
                    },
                  }}
                  value={language}
                  onChange={(e) => {
                    setLanguage(e.target.value);
                  }}
                  className="font-poppins"
                >
                  <option value="en" className="font-poppins">
                    En
                  </option>
                  <option value="ru" className="font-poppins">
                    Ru
                  </option>
                  <option value="az" className="font-poppins">
                    Az
                  </option>
                </NativeSelect>
              </FormControl>
            </Box>
          </div>
        </div>
      </div>
      {/* <Collapse in={headerSearchIsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <form onSubmit={onSearchSubmit}>
            <ListItem>
              <TextField
                onChange={changeHeaderSearchText}
                value={headerSearchText}
                sx={{
                  backgroundColor: "#000",
                  borderRadius: "8px",
                  // color:"#000",
                  backgroundColor: "transparent",
                  "& input": {
                    color: "#000",
                  },
                  "& .Mui-focused": {
                    "& fieldset": {
                      borderColor: "#000 !important",
                    },
                  },
                  "& fieldset": {
                    color: "#000 !important",
                    borderColor: "#000 !important",
                    borderRadius: "23px",
                    backgroundColor: "transparent !important",
                  },
                  "& label": {
                    color: "#000 !important",
                  },
                }}
                fullWidth
                size="small"
                label={t("Axtarış Et")}
              />
            </ListItem>
          </form>
        </List>
      </Collapse> */}
    </header>
  );
}

export default Headertest;
