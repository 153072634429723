import React, { useState, useEffect } from 'react';

import {
    Box,
    Button,
    Grid,
    Typography,
    Card,
    List,
    FormControl,
    ListItem,
    TextField,
} from '@mui/material';
import BasketItem from '../components/BasketItem';

import { connect } from 'react-redux';

import { basketInitListAction } from '../redux/actions/basketAction';

import PurchasePopUp from '../components/PurchasePopUp';

import {
    clickcerIncreaser,
} from '../services/services';

import PurchaseSuccess from '../components/PurchaseSuccess';
import PurchaseError from '../components/PurchaseError';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


const Basket = ({ basketReducer, basketInitListAction }) => {
    const { t } = useTranslation()
    const [purchaseSuccess, setPurchaseSuccess] = useState(false);
    const [purchaseError, setPurchaseError] = useState(false);
    // console.log(basketReducer);
    const [totalPrice, setTotalPrice] = useState(0);

    const [collectedProducs, setCollectedProducts] = useState([]);


    useEffect(() => {
        if (basketReducer.basketItems.length > 0) {

            const total = basketReducer.basketItems.reduce((acc, curr) => {

                if (curr.productDiscountPrice !== 0) {
                    return acc + (curr.productDiscountPrice * curr.count)
                    // setTotalPrice(totalPrice+item.productDiscountPrice)
                } else {
                    return acc + (curr.price * curr.count)
                    // setTotalPrice(totalPrice+item.price)
                }
            }, 0)
            // basketReducer.basketItems.forEach(item=>{
            //     // console.log(item)
            // })
            setTotalPrice(total)
        } else {
            setTotalPrice(0)
        }
        // basketReducer.basketItems.forEach((item)=>{
        //     let tempList = collectedProducs.map(item=>item.id)
        //     setCollectedProducts([...collectedProducs,{id:item.id,count:item.count}]);
        // })

    }, [basketReducer])
    useEffect(() => {

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        return (() => {
            (async () => {
                await clickcerIncreaser({
                    "aboutClicker": false,
                    "basketClicker": true,
                    "branchClicker": false,
                    "contactClicker": false,
                    "counter": 0,
                    "discountClicker": false,
                    "facebookClicker": false,
                    "homeClicker": false,
                    "instagramClicker": false,
                    "pendingClickerFour": false,
                    "pendingClickerOne": false,
                    "pendingClickerThree": false,
                    "pendingClickerTwo": false,
                    "productClicker": false,
                    "seasonClicker": false,
                    "weekClicker": false
                })
            })()
        })
    }, [])



    const [isOpenPurchasePopUp, setIsOpenPurchasePopUp] = useState(false)

    const basketPurchaseHandler = () => {
        if (basketReducer.basketItems.length !== 0) {
            setIsOpenPurchasePopUp(true);
            console.log()
        }
    }
    const purchasePopUpCloseHandler = () => {
        setIsOpenPurchasePopUp(false);
    }


    return (
        <Box
            className=""
            sx={{
                // padding: '15px',
                minHeight: '300px',
                marginTop: '30px',
            }}
        >
            {
                purchaseSuccess ?
                    <PurchaseSuccess close={setPurchaseSuccess} />
                    : ""
            }
            {
                purchaseError ?
                    <PurchaseError close={setPurchaseError} />
                    : ""
            }

            {
                isOpenPurchasePopUp ?
                    <PurchasePopUp successOpen={setPurchaseSuccess} errorOpen={setPurchaseError} products={basketReducer.basketItems} closePurchaseAction={purchasePopUpCloseHandler} />
                    : ''
            }

            <Box
            className="flex justify-center flex-col"
                sx={{
                    minHeight: '300px',
                    overflowY: 'scroll',
                    '&': {
                        '&::-webkit-scrollbar': {
                            width: '5px',
                        },

                        /* Track */
                        '&::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                        },

                        /* Handle */
                        '&::-webkit-scrollbar-thumb': {
                            background: '#888',
                        },

                        /* Handle on hover */
                        '&::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                        }
                    },
                    padding: '0px 30px',
                }}
            >

                <Grid container md={12} sm={12}
                    className="flex row justify-between mb-[30px] mt-[10px] p-[25px] w-100 rounded-lg"
                    sx={{
                        boxShadow: '1px 1px 5px 1px rgba(0, 0, 0, 0.05)',
                    }}
                >


                    <Box
                        className="flex items-center w-[20%]  "
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                            }}
                        >
                            <span
                                className='basketPrice '
                            >
                                Məhsul
                            </span>
                        </Typography>
                    </Box>
                    <Box className="flex items-center w-[20%]   justify-center">
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                            }}
                        >
                            <span
                                className='basketPrice'
                            >
                                Qiymət
                            </span>
                        </Typography>
                    </Box>
                    <Box className="flex items-center w-[20%]   justify-center">
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                            }}
                        >
                            <span
                                className='basketPrice'
                            >
                                Say
                            </span>
                        </Typography>
                    </Box>
                    <Box className="flex items-center w-[20%]   justify-end">
                        <Typography
                            sx={{
                                marginRight: '15.6px',
                                fontWeight: 'bold',
                            }}
                        >
                            <span
                                className='basketPrice'
                            >
                                Sil
                            </span>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={12} sm={12}>
                    <List>
                        {
                            basketReducer.basketItems.length !== 0 ?
                                basketReducer.basketItems.map((item, i) => (
                                    <BasketItem key={i} item={item} />
                                ))
                                : <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: '27px',
                                        fontWeight: '600',
                                    }}
                                >
                                    {t("Səbətdə məhsul yoxdur")}
                                </Typography>

                        }
                    </List>
                </Grid>
            </Box>

            <div >
                <Link
                    to={'/'}
                    className='purchaseButton text-black ml-[30px]'
                >
                    <span style={{
                        fontWeight: 'bold',
                        fontSize: '16px',
                        textTransform: 'inherit',
                        display: 'inline',
                        borderRadius: '4px',
                        border: '1px solid rgba(0, 0, 0, 0.5)',
                        color: '#000',
                        borderColor: 'rgba(0, 0, 0, 0.5)',
                        transitionDuration: '.4s',
                        padding: '20px 50px',
                    }}>
                        {t("Alış-verişə davam et")}
                    </span>
                </Link>
            </div>


            <Box className="sm:mx-[30px] md:ml-[30px]">
                <Box>
                    <div className='flex flex-col gap-[24px] sm:w-100 md:w-[470px] h-100 mt-[80px] rounded-lg px-[32px] py-[24px]'
                        style={{ border: '1px solid rgba(213, 213, 213, 1)', }}>
                        <span className='text-xl font-semibold'>
                            {t('Sifarişi rəsmiləşdir')}
                        </span>
                        <div className='flex justify-between text-base font-semibold'>
                            <span >
                                {t('Ümumi')}:
                            </span>
                            <span>
                                {totalPrice}₼
                            </span>
                        </div>
                        <div style={{ borderBottom: '1px solid rgba(213, 213, 213, 1)' }}></div>
                        <div className='flex justify-between text-base font-semibold'>
                            <span >
                                {t('Çatdırılma')}:
                            </span>
                            <span>
                                {t('Pulsuz')}
                            </span>
                        </div>
                        <div style={{ borderBottom: '1px solid rgba(213, 213, 213, 1)' }}></div>
                        <div className='flex justify-between text-base font-semibold'>
                            <span >
                                {t('Yekun')}:
                            </span>
                            <span>
                                {totalPrice}₼
                            </span>
                        </div>
                        <div className='flex justify-center'>
                            <form action="" className='w-[230px] h-100'>

                                <FormControl
                                    sx={{
                                        width: '230px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button
                                        // fullWidth
                                        className='purchaseButton'
                                        onClick={basketPurchaseHandler}
                                        sx={{
                                            textTransform: 'capitalize',
                                            padding: '16px, 48px, 16px, 48px',
                                            width: '230px',
                                            backgroundColor: '#ed1c22',
                                            color: '#fff',
                                            borderColor: '#ed1c22',
                                            height: '56px',
                                            '&:hover': {
                                                color: '#fff',
                                                backgroundColor: '#ed1c22',
                                                borderColor: '#ed1c22',
                                            }
                                        }}
                                        variant="outlined"
                                    >
                                        <span style={{ fontSize: '16px' }}>
                                            {t("Sifarişi tamamla")}
                                        </span>
                                    </Button>
                                </FormControl>


                            </form>
                        </div>
                    </div>
                </Box>
            </Box>
        </Box>
    );
}

const mapStateToProps = state => state;
const mapDispatchToProps = {
    basketInitListAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(Basket);
