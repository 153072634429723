import React, { useEffect, useState } from "react";

import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Collapse,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { loadSubParentCategoryListById } from "../services/services";

import MobileMenuSubChildItem from "./MobileMenuSubChildItem";
import { useLanguage } from "../contexts/LanguageContext";

const MobileMenuSubParentItem = ({
  item,
  menuItemIsOpen,
  onMenuItemOpenHandler,
  setMobileMenuIsOpen,
}) => {
  const [subParentCategoryList, setSubParentCategoryList] = useState(null);

  const [subParentCategoryListIsOpen, setSubParentCategoryListIsOpen] =
    useState(null);
  const { language } = useLanguage();

  const onMenuParentItemOpenHandler = (e) => {
    let tempMenuState = {};
    Object.keys(subParentCategoryListIsOpen).forEach((menuKey) => {
      tempMenuState[menuKey] = false;
    });
    setSubParentCategoryListIsOpen({
      ...tempMenuState,
      [e.target.closest("[data-name]").dataset.name]:
        !subParentCategoryListIsOpen[
          e.target.closest("[data-name]").dataset.name
        ],
    });
  };

  useEffect(() => {
    (async () => {
      setSubParentCategoryList(await loadSubParentCategoryListById(item.id));
      let menuList = await loadSubParentCategoryListById(item.id);
      menuList.forEach((item) => {
        // let menuItem = `${item.id}`
        setSubParentCategoryListIsOpen({
          ...subParentCategoryListIsOpen,
          ["menuItem" + item.id]: false,
        });
      });
    })();
  }, []);

  return (
    <>
      <ListItem
        onClick={onMenuItemOpenHandler}
        data-name={`menuItem${item.id}`}
      >
        <ListItemIcon>
          <Box
            sx={{
              transition: "all .2s ease",
              transform: `rotate(${
                menuItemIsOpen[`menuItem${item.id}`] ? " 90deg" : " 0deg"
              })`,
            }}
          >
            <ArrowForwardIosIcon />
          </Box>
        </ListItemIcon>
        <ListItemText
          sx={{
            textTransform: "capitalize",
          }}
          primary={`${language == "az" && item.pendingCategoryOne
          ? item.pendingCategoryOne
          : language == "ru" && item.pendingCategoryTwo
          ? item.pendingCategoryTwo
          : language == "en" && item.pendingCategoryThree
          ? item.pendingCategoryThree
          : item.name}`}
          
        />
      </ListItem>
      <Collapse
        in={menuItemIsOpen[`menuItem${item.id}`]}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {subParentCategoryList !== null
            ? subParentCategoryList.map((item, i) => (
                <MobileMenuSubChildItem
                  setMobileMenuIsOpen={setMobileMenuIsOpen}
                  className="mobileMenuItem"
                  key={i}
                  item={item}
                  onMenuParentItemOpenHandler={onMenuParentItemOpenHandler}
                  subParentCategoryListIsOpen={subParentCategoryListIsOpen}
                />
              ))
            : ""}
        </List>
      </Collapse>
    </>
  );
};

export default MobileMenuSubParentItem;
