import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet"; // Import Leaflet library
import "leaflet/dist/leaflet.css";
import RoomIcon from "@mui/icons-material/Room";
import { Icon } from "leaflet";

// Import the custom marker icon image
// import RoomIcon from './custom-marker-icon.png';

function MapWithMarkers() {
  const [markers, setMarkers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchCoordinates() {
      try {
        const links = [
          "https://www.google.com/maps?ll=41.458789,48.79578&z=6&t=m&hl=az&gl=AZ&mapclient=embed&cid=3759529272097493367",
          "https://www.google.com/maps/place/Barkod+Electronics+Zaqatala/@41.629549,46.637075,16z/data=!4m6!3m5!1s0x40464b25514c2baf:0x18beef37cc4a71c1!8m2!3d41.6295493!4d46.637075!16s%2Fg%2F11kqsc3dld?hl=az&entry=ttu",
          // Add more Google Maps links here
        ];
        const apiKey = "AIzaSyCa5KQioVjTh76_icx_dhxAISNWUyVmIXU"; // Your Google Maps API key

        const markersData = [];

        for (const link of links) {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
              link
            )}&key=${apiKey}`
          );
          const data = await response.json();

          if (data.status === "OK") {
            const { lat, lng } = data.results[0].geometry.location;
            markersData.push({ position: [lat, lng], name: link });
          } else {
            console.error("Geocoding error for link:", link, data.status);
          }
        }

        setMarkers(markersData);
      } catch (error) {
        console.error("Geocoding error:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchCoordinates();
  }, []);

  // Create a custom icon instance
  const customIcon = new L.Icon({
    iconUrl: RoomIcon, // Path to the custom marker icon image
    iconSize: [500, 500], // Size of the icon
    iconAnchor: [15, 30], // Point of the icon which will correspond to marker's location
    popupAnchor: [0, -30], // Point from which the popup should open relative to the iconAnchor
  });

  const fallbackIcon = new Icon({
    iconUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  return (
    <div className="px-[40px] pb-[110px]">
      <div className="w-full border-2 rounded-lg py-4 mb-[34px]">
        <span className="font-bold ml-6">Mağazalarımız</span>
      </div>
      <div className="mb-[34px]">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <MapContainer
            center={[40.4093, 49.8671]}
            zoom={8}
            style={{ height: "683px" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {markers.map((marker, index) => (
              <Marker
                key={index}
                position={marker.position}
                icon={customIcon || fallbackIcon}
              >
                <Popup>{marker.name}</Popup>
              </Marker>
            ))}
          </MapContainer>
        )}
      </div>

      <div className="pb-[34px]">
        <span className="text-white rounded-[10px] bg-black p-3">
          Regionlar
        </span>
      </div>

      <div className="flex gap-[30px] mb-[42px]">
        <div className="border-2 rounded-lg p-4 w-50">
          <div className="flex flex-col gap-[50px]">
            <div className="flex flex-col">
              <span className="font-bold text-lg">
                Barkod Electronics "Xudat"
              </span>
              <span className="text-base">
              M.F.Axundov küç.
              </span>
            </div>
            <div>
              <p> <span className="text-green-400">İş saatları</span>: 09:00-dan 19:00-dək</p>
            </div>
          </div>
        </div>
        <div className="border-2 rounded-lg p-4 w-50">
          <div className="flex flex-col gap-[50px]">
            <div className="flex flex-col">
              <span className="font-bold text-lg">
                Barkod Electronics "Xaçmaz"
              </span>
              <span className="text-base">
                N.Nərimanov pr. (Notariusun yanı)
              </span>
            </div>
            <div>
              <p> <span className="text-green-400">İş saatları</span>: 09:00-dan 19:00-dək</p>
            </div>
          </div>
        </div>
        <div className="border-2 rounded-lg p-4 w-50">
          <div className="flex flex-col gap-[50px]">
            <div className="flex flex-col">
              <span className="font-bold text-lg">
                Barkod Electronics "İsmayıllı"
              </span>
              <span className="text-base">
                M.F.Axundov küç. (Buta binası)
              </span>
            </div>
            <div>
              <p> <span className="text-green-400">İş saatları</span>: 09:00-dan 19:00-dək</p>
            </div>
          </div>
        </div>
        <div className="border-2 rounded-lg p-4 w-50">
          <div className="flex flex-col gap-[50px]">
            <div className="flex flex-col">
              <span className="font-bold text-lg">
                Barkod Electronics "Siyəzən"
              </span>
              <span className="text-base">
                H.Əliyev pr. 18
              </span>
            </div>
            <div>
              <p> <span className="text-green-400">İş saatları</span>: 09:00-dan 19:00-dək</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-[30px]">
        <div className="border-2 rounded-lg p-4 w-50">
          <div className="flex flex-col gap-[50px]">
            <div className="flex flex-col">
              <span className="font-bold text-lg">
                Barkod Electronics "Zaqatala"
              </span>
              <span className="text-base">
                Faiq Əmirov küç. (Bayraq meydanının yaxınlığı)
              </span>
            </div>
            <div>
              <p> <span className="text-green-400">İş saatları</span>: 09:00-dan 19:00-dək</p>
            </div>
          </div>
        </div>
        <div className="border-2 rounded-lg p-4 w-50">
          <div className="flex flex-col gap-[50px]">
            <div className="flex flex-col">
              <span className="font-bold text-lg">
                Barkod Electronics "Qazax"
              </span>
              <span className="text-base">
                H.Əliyev pr. 108 (Mərkəzi bazarın yaxınlığı)
              </span>
            </div>
            <div>
              <p> <span className="text-green-400">İş saatları</span>: 09:00-dan 19:00-dək</p>
            </div>
          </div>
        </div>
        <div className="border-2 rounded-lg p-4 w-50">
          <div className="flex flex-col gap-[50px]">
            <div className="flex flex-col">
              <span className="font-bold text-lg">
                Barkod Electronics "Tovuz"
              </span>
              <span className="text-base">
                M.Musayev küç.
              </span>
            </div>
            <div>
              <p> <span className="text-green-400">İş saatları</span>: 09:00-dan 19:00-dək</p>
            </div>
          </div>
        </div>
        <div className="border-2 rounded-lg p-4 w-50">
          <div className="flex flex-col gap-[50px]">
            <div className="flex flex-col">
              <span className="font-bold text-lg">
                Barkod Electronics "Bərdə"
              </span>
              <span className="text-base">
                Üzeyir Hacıbəyov küç. (Poliklinikanın yaxınlığı)
              </span>
            </div>
            <div>
              <p> <span className="text-green-400">İş saatları</span>: 09:00-dan 19:00-dək</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapWithMarkers;
