import React,{useEffect,useState} from 'react';


// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import {
    Box,
    Grid,
    TextField,
    FormControl,
    Typography,
    IconButton,
    Button,
    Checkbox,
} from '@mui/material';


import{
    FaRegTimesCircle
}from 'react-icons/fa';


import {
    executePurchase,
    sendPurchaseId,
} from '../services/services';
import { useTranslation } from 'react-i18next';





function PurchasePopUp({ products, closePurchaseAction, typeOfPurchase="Nağd", successOpen,errorOpen}) {
const {t} = useTranslation()
    const [purchaseState, setPurchaseState] = useState({
        "cardNumber": "",
        "creditTime": "",
        "customerEmail": "",
        "customerFatherName": "",
        "customerFinCode": "",
        "customerName": "",
        "customerNote": "",
        "customerSurname": "",
        "deliveryAddress": "",
        "deliveryCity": "",
        "pendingPayByCashFour": "",
        "pendingPayByCashOne": "",
        "pendingPayByCashThree": "",
        "pendingPayByCashTwo": "",
        "phoneNumber": "",
        "productId": {
          
        }
      });

    useEffect(()=>{
        let productIds={};
        products.forEach((item)=>{
            productIds[item.id]=item.count;
        })
        setPurchaseState({
            ...purchaseState,
            productId:productIds
        })
    },[])

    const purchaseChangeHadnler = (e) => {
        setPurchaseState({
            ...purchaseState,
            [e.target.name]: e.target.value,
        });
        if(purchaseState.phoneNumber.length >= 9  || purchaseState.customerName.length >= 2){
            setFormIsOkay(false)
        }
    };

    const [formIsOkay,setFormIsOkay] = useState(false);

    const closePurchasePopUpHandler = () => {
        closePurchaseAction();
    };
    const purchaseFormSubmitHandler = (e) => {
        e.preventDefault();

        if(purchaseState.phoneNumber.length < 9  || purchaseState.customerName.length <= 2){
            setFormIsOkay(true)
        }else{
            (async()=>{
                const purchaseRes = await executePurchase(purchaseState)
                if(purchaseRes.status===201){
                    const mailSended = await sendPurchaseId(purchaseRes.data.id);
                    if(mailSended.status === 200 ) {
                        successOpen(true)
                    }else{
                        errorOpen(true)
                    }
                }
            })()
            
        }
    };

    return (
        <Box
            className="purchasePopUp"
        >
            <Box
                sx={{
                    position: 'fixed',
                    left: '0%',
                    top: '0%',
                    width: '100vw',
                    height: '100vh',
                    zIndex: '9999999999999999',
                    paddingBottom:'20px',
                    backgroundColor: 'rgba(0,0,0,.5)',
                }}
                onClick={closePurchasePopUpHandler}
            ></Box>
            <Box
                className="purchaseForm"
                sx={{
                    backgroundColor: '#fff',
                    position: 'fixed',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%,-50%)',
                    width: 'calc(100% - 350px)',
                    border: '.5px solid lightgrey',
                    zIndex: '99999999999999999',
                    borderRadius: '5px',
                    overflow: 'hidden',
                    padding: '15px 30px',
                    paddingBottom:'25px',
                    boxShadow: '0px 0px 10px -2px #000',
                    // marginTop:'200px',

                    '& fieldset': {
                        borderRadius: '5px',
                    }
                }}
            >
                <Box
                    sx={{
                        textAlign: 'right'
                    }}
                >
                    <IconButton onClick={closePurchasePopUpHandler}>
                        <FaRegTimesCircle />
                    </IconButton>
                </Box>
                <span
                    style={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        fontWeight: '700',
                        fontSize: '23px',
                        marginBottom:'25px',
                    }}
                >
                    {t("Müştəri məlumatları")}
                </span>
                <form onSubmit={purchaseFormSubmitHandler}>
                    <Grid container spacing={2}>
                        <Grid item md={8} >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={3} className="purchaseFormField">
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField
                                            fullWidth
                                            value={purchaseState.customerName}
                                            onChange={purchaseChangeHadnler}
                                            name="customerName"
                                            variant="outlined"
                                            sx={{
                                                '& fieldset':{
                                                    borderColor:purchaseState.customerName.length < 3 ? "red" :'',
                                                }
                                            }}
                                            label={`${t("Ad")} ${t("Soyad")}`}
                                            size={"small"} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={3} className="purchaseFormField">
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField
                                            fullWidth
                                            value={purchaseState.deliveryAddress}
                                            onChange={purchaseChangeHadnler}
                                            name="deliveryAddress"
                                            variant="outlined"
                                            label={t("Ünvan")}
                                            size={"small"} />
                                    </FormControl>
                                </Grid>
                                
                                <Grid item xs={12} sm={6} md={6} lg={3} className="purchaseFormField">
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField
                                            fullWidth
                                            value={purchaseState.phoneNumber}
                                            sx={{
                                                '& fieldset':{
                                                    borderColor:purchaseState.phoneNumber.length < 9 ? "red" :'',
                                                }
                                            }}
                                            onChange={purchaseChangeHadnler}
                                            name="phoneNumber"
                                            type="number"
                                            variant="outlined"
                                            label={t("Telefon Nömrəsi")}
                                            size={"small"} />
                                    </FormControl>
                                </Grid>
                                
                                <Grid item xs={12} sm={6} md={6} lg={3} className="purchaseFormField">
                                    <FormControl sx={{ width: '100%' }}>
                                        <TextField
                                            fullWidth
                                            value={purchaseState.deliveryCity}
                                            onChange={purchaseChangeHadnler}
                                            name="deliveryCity"
                                            variant="outlined"
                                            label={t("Şəhər")}
                                            size={"small"} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item  xs={12} sm={6} md={4} lg={3} className="purchaseFormField">
                                <FormControl sx={{ width: '100%' }}>
                                    <TextField
                                        fullWidth
                                        value={purchaseState.customerNote}
                                        onChange={purchaseChangeHadnler}
                                        name="customerNote"
                                        variant="outlined"
                                        label={t("Qeyd")}
                                        multiline
                                        sx={{
                                            '& fieldset':{
                                                height:'101px',
                                            }
                                        }}
                                        rows={4}
                                        maxRow={4}
                                        minRow={4}
                                        size={"small"} />
                                </FormControl>
                        </Grid>
                        {
                            formIsOkay ?
                                <Grid item md={12} sm={12} sx={{paddingTop:'0px !important',}}>
                                    <p
                                        style={{
                                            margin:'0px !important',
                                            color:'red',
                                            fontSize:'12px',
                                        }}
                                    >
                                        {t("Zəruri məlumatları daxil edin!")}
                                    </p>
                                </Grid>
                            :''
                            }



                        <Grid item xs={12} xl={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor:'#ed1c22',
                                    textTransform: 'capitalize',
                                    border:'.5px solid #ed1c22',
                                    marginTop:'25px',
                                    '&:hover':{
                                        backgroundColor:'#ed1c22',
                                        border:'.5px solid #ed1c22',
                                        color:'#fff',

                                    },
                                }}
                            >
                                {t("Təstiq Et")}
                            </Button>
                        </Grid>
                    </Grid>

                </form>
            </Box>
        </Box>
    );
}

export default PurchasePopUp;
