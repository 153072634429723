import React, { useEffect, useRef, useState } from 'react';
import ProductItem from './ProductItem';
import { Link } from 'react-router-dom';


import {
    Box,
    Typography,
    Button,
    Skeleton,

} from '@mui/material';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import BasketAddedSuccess from '../components/BasketAddedSuccess';
// import BasketAddedError from '../components/BasketAddedError';
import BasketAddedInfo from '../components/BaskertAddedInfo';
import { useTranslation } from 'react-i18next';

const ProductSlider = ({ sliderHeading, sliderList, sliderName }) => {
    const { t } = useTranslation()
    // let sliderRef = useRef(null);
    let dragging = false;


    const productSettings = {
        dots: false,
        infinite: sliderList.length > 4 ? true : false,
        autoplay: true,
        autoplaySpeed: 3000,
        margin: 5,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        beforeChange: () => dragging = true,
        afterChange: () => dragging = false,
        // nextArrow:'',
        // prevArrow:'',
        responsive: [
            {
                breakpoint: 1282,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    // infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1026,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    //   infinite: true,
                    infinite: sliderList.length > 3 ? true : false,
                    dots: false
                }
            },
            {
                breakpoint: 914,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    //   infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 822,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    //   infinite: true,/
                    dots: false
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    infinite: sliderList.length > 2 ? true : false,
                    slidesToScroll: 1,
                    //   infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 415,
                settings: {
                    slidesToShow: 1,
                    infinite: sliderList.length > 1 ? true : false,
                    slidesToScroll: 1,
                    //   infinite: true,
                    dots: false
                }
            },
        ]
    };

    const [successPopUp, setSuccessPopUp] = useState(false);
    const [infoPopUp, setInfoPopUp] = useState(false);
    const [errorPopUp, setErrorPopUp] = useState(false);

    return (
        <>
            {
                successPopUp ?
                    <BasketAddedSuccess close={setSuccessPopUp} />
                    : ''
            }
            {
                infoPopUp ?
                    <BasketAddedInfo close={setInfoPopUp} />
                    : ''
            }

            <Box
                className='productList productListSlider'
                sx={{
                    width: '100%',
                    padding: '0px 0px',
                    marginBottom: '80px',
                    '& .slick-slider.slick-initialized': {
                        // paddingLeft: '25px',
                    },
                    marginTop: '75px',
                    borderRadius: '8px',
                    height: '100%',
                    // borderBottom
                    // overflow:'hidden',
                    // maxHeight:'400px',
                    '& svg': {
                        color: '#fff',
                        backgroundColor: '#253444',
                        height: '25%',
                        borderRadius: '5px',
                        fontSize: '23px !important',
                        margin: '0px !important',
                        '& path': {
                        },
                        '&:hover': {
                            color: '#fff',
                            backgroundColor: '#253444',
                        },
                        '&.slick-prev': {
                            marginLeft: '50px',
                            zIndex: '9',
                        },
                        '&.slick-next': {
                            marginRight: '50px',
                            zIndex: '9',
                        }
                    },
                    '& img': {
                        width: '100%',
                    },
                    '& .slick-slider': {
                        overflowX: 'clip',
                        // zIndex:'9999',
                    },
                    '& .slick-list': {
                        zIndex: '9999',
                        overflow: 'visible !important',
                    },
                    '& .slick-dots': {
                        width: 'auto',
                        right: '0%',
                        '& li': {
                            '&.slick-active': {
                                '& button': {
                                    '&:before': {
                                        color: '#ff0009',
                                        opacity: '1',
                                    }
                                }
                            },
                            '& button': {
                                '&:before': {
                                    color: '#2C3333',
                                    opacity: '1',
                                    width: '5px',
                                }
                            }
                        }
                    }
                }}
            >
                {
                    sliderHeading ?
                        <Box
                            className="productSliderHeading"
                            sx={{
                                width: '100%',
                                fontFamily: 'arial',
                                color: '#000',
                                textAlign: 'start',
                                marginBottom: '37.5px',
                                borderBottom: '.5px solid #fff',
                                fontSize: '36px',
                                fontWeight: '600',
                            }}
                        >
                            {sliderHeading}
                        </Box>
                        : ''
                }
                <Slider
                    {...productSettings}
                >
                    {
                        sliderList != null ?
                            sliderList.map((item, i) => {
                                if (i <= 6) {
                                    return (
                                        <ProductItem myClick={(e) => dragging && e.preventDefault()} key={i} item={item} success={setSuccessPopUp} info={setInfoPopUp} />
                                    )
                                }
                            }
                            )
                            : ''
                    }
                </Slider>
                <Box
                    sx={{
                        textAlign: 'center',
                        marginTop: '45px',
                    }}
                >
                    <Button
                        className="seeMoreBtn"
                        variant="contained"
                        sx={{
                            color: '#8e8e8e',
                            backgroundColor: '#fff',
                            textTransform: 'lowercase',
                            boxShadow: 'none',
                            fontFamily: 'light-roboto-mono',
                            padding: '0px',
                            width: '191px',
                            '& span': {
                                textTransform: 'capitalize',
                            },
                            '&:hover': {
                                color: '#fff',
                                backgroundColor: '#ed1c22',
                                '& a': {
                                    color: '#fff',
                                }
                            },
                            '& a': {
                                minWidth: '191px',
                                height: '56px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '4px',

                                padding: '16px, 48px, 16px, 48px',
                                textDecoration: 'none',
                                color: '#fff',
                                backgroundColor: '#DB4444',
                            }
                        }}
                    >
                        <Link
                            to={
                                `/neticeler/${sliderName === 'Endirimli məhsullar' ?
                                    'Endirim'
                                    : sliderName === 'Həftənin təklifləri' ? 'Hefte'
                                        : sliderName === 'Sezon təklifləri' ? 'Sezon' : ''
                                }/1`
                            }

                        >
                            <span style={{ marginRight: '-4px', width: "max-content" }}>{t("Daha ətraflı")}</span>
                        </Link>
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default ProductSlider;