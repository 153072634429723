import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_en from "./en/common.json";
import common_ru from "./ru/common.json";
import common_az from "./az/common.json";

const resources = {
  en: { common: common_en },
  ru: { common: common_ru },
  az: { common: common_az },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('lang') || 'en',
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  });
  
export default i18n;
