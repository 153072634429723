import Types from '../types/types';


const reducerState={
    productList:[
        {
            productId:1,
            price:150,
            discountPrice:50,
            name:'iPhone 14pro max ultra plus plus minus minus multiply decade',
            count:1,
        },
        {
            productId:2,
            price:460,
            discountPrice:100,
            name:'iPhone 14pro max ultra plus plus minus minus multiply decade',
            count:1,
        },
        {
            productId:3,
            price:3200,
            discountPrice:null,
            name:'iPhone 14pro max ultra plus plus minus minus multiply decade',
            count:1,
        },
        {
            productId:4,
            price:750,
            discountPrice:null,
            name:'iPhone 14pro max ultra plus plus minus minus multiply decade',
            count:1,
        },
        {
            productId:5,
            price:3200,
            discountPrice:null,
            name:'iPhone 14pro max ultra plus plus minus minus multiply decade',
            count:1,
        },
        {
            productId:6,
            price:750,
            discountPrice:null,
            name:'iPhone 14pro max ultra plus plus minus minus multiply decade',
            count:1,
        },
        {
            productId:7,
            price:3200,
            discountPrice:null,
            name:'iPhone 14pro max ultra plus plus minus minus multiply decade',
            count:1,
        },
        {
            productId:8,
            price:750,
            discountPrice:null,
            name:'iPhone 14pro max ultra plus plus minus minus multiply decade',
            count:1,
        },
        {
            productId:9,
            price:3200,
            discountPrice:null,
            name:'iPhone 14pro max ultra plus plus minus minus multiply decade',
            count:1,
        },
        {
            productId:10,
            price:750,
            discountPrice:null,
            name:'iPhone 14pro max ultra plus plus minus minus multiply decade',
            count:1,
        },
        {
            productId:11,
            price:3200,
            discountPrice:null,
            name:'iPhone 14pro max ultra plus plus minus minus multiply decade',
            count:1,
        },
        {
            productId:12,
            price:750,
            discountPrice:null,
            name:'iPhone 14pro max ultra plus plus minus minus multiply decade',
            count:1,
        },
        {
            productId:13,
            price:750,
            discountPrice:null,
            name:'iPhone 14pro max ultra plus plus minus minus multiply decade',
            count:1,
        },
        {
            productId:14,
            price:750,
            discountPrice:null,
            name:'iPhone 14pro max ultra plus plus minus minus multiply decade',
            count:1,
        },
        {
            productId:15,
            price:750,
            discountPrice:null,
            name:'iPhone 14pro max ultra plus plus minus minus multiply decade',
            count:1,
        },
    ],
}

export const productLoadReducer =(state=reducerState)=>{
    return state
}