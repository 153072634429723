class BasketStorage {
    _getStorageList(){
        return JSON.parse(localStorage.getItem('basketList'));   
    }

    _addItemStorage(item){
        const currentStorage = this._getStorageList();
        // console.log(currentStorage)
        if(currentStorage){
                localStorage.setItem('basketList',JSON.stringify([...currentStorage,{...item,count:1}]))
        }else{
            localStorage.setItem('basketList',JSON.stringify([{...item,count:1}]))            
        }
    }

    _itemIsInList(item){
        // localStorage.clear()
        const currentStorage = this._getStorageList();
        if(currentStorage){
            const checkedItem = currentStorage.find(currItem=>currItem.id===item.id);
            if(checkedItem){
                return true
            }else{
                return null;
            }
        }else{
            return null
        }
    }

    _removeItemStorage(item){
        // console.log(item)
        const currentStorage = this._getStorageList();
        const filteredList = currentStorage.filter(currItem=>currItem.id!==item.id);
        if(filteredList.length===0){
            this._clearStorageList();
        }else{
            localStorage.setItem('basketList',JSON.stringify(filteredList));
        }
    }

    _clearStorageList(){
        localStorage.clear();
    }
}

export default new BasketStorage();