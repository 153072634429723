import React, { useEffect, useState, useMemo } from "react";

import {
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Button,
  IconButton,
  Typography,
} from "@mui/material";

import { useParams } from "react-router-dom";

import {
  loadChildProductsById,
  loadProductsByParentSubCategoryId,
  loadDiscountProducts,
  loadOfferOfWeekProducts,
  loadOfferOfSeasonProducts,
  clickcerIncreaser,
} from "../services/services";

import ProductItem from "../components/ProductItem";

import BasketAddedSuccess from "../components/BasketAddedSuccess";
// import BasketAddedError from '../components/BasketAddedError';
import BasketAddedInfo from "../components/BaskertAddedInfo";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../contexts/LanguageContext";

const ChildCategoryProducts = () => {
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [infoPopUp, setInfoPopUp] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const { t } = useTranslation();
  const [productsList, setProductsList] = useState(null);
  const { language } = useLanguage();
  const { id, type } = useParams();
  // console.log(categoryId,subcategoryId);

  useEffect(() => {
    (async () => {
      if (type === "umumi") {
        setProductsList(await loadProductsByParentSubCategoryId(id));
      } else if (type === "alt-mehsullar") {
        setProductsList(await loadChildProductsById(id));
      } else if (type === "Endirim") {
        setProductsList({
          // name:'',
          list: await loadDiscountProducts(),
        });
      } else if (type === "Hefte") {
        setProductsList({
          list: await loadOfferOfWeekProducts(),
        });
      } else if (type === "Sezon") {
        setProductsList({
          list: await loadOfferOfSeasonProducts(),
        });
      }
    })();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    return () => {
      (async () => {
        if (type === "umumi") {
        } else if (type === "alt-mehsullar") {
          // await clickcerIncreaser({
          //     "aboutClicker": true,
          //     "basketClicker": false,
          //     "branchClicker": false,
          //     "contactClicker": false,
          //     "counter": 0,
          //     "discountClicker": false,
          //     "facebookClicker": false,
          //     "homeClicker": false,
          //     "instagramClicker": false,
          //     "pendingClickerFour": false,
          //     "pendingClickerOne": false,
          //     "pendingClickerThree": false,
          //     "pendingClickerTwo": false,
          //     "productClicker": false,
          //     "seasonClicker": false,
          //     "weekClicker": false
          // })
        } else if (type === "Endirim") {
          await clickcerIncreaser({
            aboutClicker: false,
            basketClicker: false,
            branchClicker: false,
            contactClicker: false,
            counter: 0,
            discountClicker: true,
            facebookClicker: false,
            homeClicker: false,
            instagramClicker: false,
            pendingClickerFour: false,
            pendingClickerOne: false,
            pendingClickerThree: false,
            pendingClickerTwo: false,
            productClicker: false,
            seasonClicker: false,
            weekClicker: false,
          });
        } else if (type === "Hefte") {
          await clickcerIncreaser({
            aboutClicker: false,
            basketClicker: false,
            branchClicker: false,
            contactClicker: false,
            counter: 0,
            discountClicker: false,
            facebookClicker: false,
            homeClicker: false,
            instagramClicker: false,
            pendingClickerFour: false,
            pendingClickerOne: false,
            pendingClickerThree: false,
            pendingClickerTwo: false,
            productClicker: false,
            seasonClicker: false,
            weekClicker: true,
          });
        } else if (type === "Sezon") {
          await clickcerIncreaser({
            aboutClicker: false,
            basketClicker: false,
            branchClicker: false,
            contactClicker: false,
            counter: 0,
            discountClicker: false,
            facebookClicker: false,
            homeClicker: false,
            instagramClicker: false,
            pendingClickerFour: false,
            pendingClickerOne: false,
            pendingClickerThree: false,
            pendingClickerTwo: false,
            productClicker: false,
            seasonClicker: true,
            weekClicker: false,
          });
        }
      })();
    };
  }, [id, type]);
  return (
    <>
      {successPopUp ? <BasketAddedSuccess close={setSuccessPopUp} /> : ""}
      {infoPopUp ? <BasketAddedInfo close={setInfoPopUp} /> : ""}

      <Box
        className="px-[40px]"
        sx={{
          marginTop: "35px",
          "& .productItem": {
            marginRight: "0px !important",
          },
        }}
      >
        <Typography
          sx={
            {
              // color:'red',
            }
          }
        >
          <Box>
            <span
              className="flex justify-center items-center h-full p-8 font-bold text-3xl"
            >
              {productsList !== null ? (
                <>
                  {productsList.name ? (
                    <div className="flex justify-around gap-2">
                      <span
                      // style={{ marginRight: "27.5px" }}
                      >
                        {`${language == "az" &&
                            productsList.nameAz
                            ? productsList.nameAz
                            : language == "ru" &&
                              productsList.nameRu
                              ? productsList.nameRu
                              : language == "en" &&
                                productsList.nameEn
                                ? productsList.nameEn
                                : productsList.name
                          }`}
                      </span>
                      <span>
                        {t("məhsulları")}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
              {type === "Endirim"
                ? t("Endirimli məhsullar")
                : type === "Hefte"
                  ? t("Həftənin təklifləri")
                  : type === "Sezon"
                    ? t("Sezon təklifləri")
                    : ""}
            </span>
          </Box>
        </Typography>
        <Grid container spacing={2} className="searchResultsContainer">
          {productsList !== null
            ? productsList.list.map((productItem, i) => (
              <Grid item xs={12} sm={4} md={3} lg={3} key={i}>
                <ProductItem
                  item={productItem}
                  success={setSuccessPopUp}
                  info={setInfoPopUp}
                />
              </Grid>
            ))
            : ""}
        </Grid>
      </Box>
    </>
  );
};

export default ChildCategoryProducts;
