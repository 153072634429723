import React from 'react';

import {
    Box,
    Typography,
    Button,
} from '@mui/material';

import {
    HiOutlineEmojiSad
} from 'react-icons/hi';
import { useTranslation } from 'react-i18next';

const ErrorPopUpGeneral = () => {
    const {t} = useTranslation()
    const pageReloadHandler = ()=>{
        window.location.reload();
    }

    return (
        <Box
            sx={{
                position:'fixed',
                top:'0%',
                left:'0%',
                width:'100%',
                height:'100%',
                backgroundColor:'rgba(0,0,0,.5)',
                zIndex:'9999999999',
            }}
            className="aaaaaaaa"
        >
            <Box 
                sx={{
                    maxWidth:'450px',
                    // maxHeight:'250px',
                    width:'100%',
                    // height:'100%',
                    padding:'30px 0px',
                    backgroundColor:'#fff',
                    borderRadius:'4px',
                    overflow:'hidden',
                    top:'50%',
                    left:'50%',
                    position:'absolute',
                    transform:'translate(-50%,-50%)',
                    boxShadow:'0px 0px 15px -10px #000',
                }}
            >

                <Box
                    sx={{
                        textAlign:'center',
                        
                    }}
                >
                    <Box    
                        sx={{
                            '& svg':{
                                fontSize:'50px',
                                color:'#f32e2e',
                            },
                        }}
                    >
                        <HiOutlineEmojiSad/>
                        <Typography
                            sx={{
                                color: '#8e8e8e',
                                fontWeight: '700',
                                margin: '30px',
                            }}
                        >
                            Xəta baş verdi.
                        </Typography>
                    </Box>
                    <Button
                        onClick={pageReloadHandler}
                        variant="contained"
                        sx={{
                            color:'#fff',
                            backgroundColor:'#f32e2e',
                            '&:hover':{
                                backgroundColor:'#f32e2e',
                            }
                        }}
                    >
                        {t("Yenidən cəhd et")}
                    </Button>
               </Box>
            </Box>

        </Box>
    );
}

export default ErrorPopUpGeneral;
