import Types from '../types/types';

const reducerState= {
    basketItems:[],
    totalPrice:0,
};

export const basketReducer =(state=reducerState,{type,payload})=>{
    switch(type){

        case Types.BASKET_INIT_STORAGE:
            
            return {
                ...state,
                basketItems:payload,
                // totalPrice:calclateTotalPrice(payload),
            }

        case Types.BASKET_ADD_ITEM:
            // const increasedBasket=[];
            // console.log(payload)
            // console.log(state.basketItems)
            if(state.basketItems.length===0){
                return{
                    ...state,
                    basketItems:[{...payload,count:1}],
                }
            }else{
                // let isInList = null;
                // isInList = state.basketItems.find(basketItem=>basketItem.id===payload.id)
                // if(isInList){
                    return {
                        ...state,
                        basketItems:[...state.basketItems,{...payload,count:1}],
                    }
                // }
            }
            // return {
            //     ...reducerState,
            //     basketItems:increasedBasket,
            // }
        case Types.BASKET_DELETE_ITEM:
            const reducedBasket = state.basketItems.filter(basketItem=>basketItem.id!==payload.id)
            return{
                ...reducerState,
                basketItems:reducedBasket,
            }
        case Types.BASKET_INCREASE_ITEM:
            const updatedBasketListINC = state.basketItems.map(
                basketItem=>basketItem.id !== payload.id ? basketItem : {...basketItem,count:basketItem.count+1}
            )
            return {
                ...reducerState,
                basketItems:updatedBasketListINC,
            }        
        case Types.BASKET_DECREASE_ITEM:
            const updatedBasketListDISC = state.basketItems.map(
                basketItem=>basketItem.id !== payload.id ? basketItem : {...basketItem,count:(basketItem.count > 1 ? basketItem.count-1 : 1)}
            )
            return {
                ...reducerState,
                basketItems:updatedBasketListDISC,
            }        
        case Types.BASKET_RESET_ITEM:
            const updatedBasketListRST = state.basketItems.map(
                basketItem=>basketItem.id !== payload.id ? basketItem : {...basketItem,count:1}
            )
            return {
                ...reducerState,
                basketItems:updatedBasketListRST,
            }
        default: return reducerState;
    }
    
    // const calclateTotalPrice=(list)=>{
    //     if(basketReducer.basketItems.length > 0){
    //         return basketReducer.basketItems.reduce((acc,curr)=>{
                
    //             if(curr.productDiscountPrice!==0){
    //                 return acc+curr.productDiscountPrice
    //                 // setTotalPrice(totalPrice+item.productDiscountPrice)
    //             }else{
    //                 return acc+curr.price
    //                 // setTotalPrice(totalPrice+item.price)
    //             }
    //         },0) 
    //         // basketReducer.basketItems.forEach(item=>{
    //         //     // console.log(item)
    //         // })
    //     }
    // }
}
