import React, { createContext, useContext, useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('lang') || 'az');
  const {i18n} = useTranslation()

  useEffect(() => {
    localStorage.setItem('lang', language);
    i18n.changeLanguage(language);
  }, [language]);

  const value = {
    language,
    setLanguage,
  };

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
