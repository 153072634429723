import React,{useEffect,useState} from 'react';

import {
    Box,
    Grid,
    Card,
    CardContent,
    CardActions,
    CardMedia,
    Button,
    IconButton,
    Typography,
}from '@mui/material';

import {useParams } from 'react-router-dom';

import {loadProductsByParentSubCategoryId} from '../services/services';

import ProductItem from '../components/ProductItem';

const ParentSubCategoryProducts = () => {
    const [productsList,setProductsList] = useState(null);
    
    const {id} = useParams();
    // console.log(categoryId,subcategoryId);

    useEffect(()=>{
        (async()=>{
            setProductsList(await loadProductsByParentSubCategoryId(id));
            // await (async()=>{
                // console.log(productsList)
            // })()  
        })()
     
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
      
    },[id])
    return (
        <Box
            className="container"
            sx={{
                marginTop:'35px',
            }}
        >
            <Grid container spacing={5}>
                {
                 productsList !== null ?
                     productsList.map((productItem,i)=>(
                        <Grid item md={3} key={i}>
                            <ProductItem item={productItem}/>
                        </Grid> 
                      ))    
                 :''
               } 
            </Grid>
        </Box>
    );
}

export default ParentSubCategoryProducts;
