import React,{useState,useEffect} from 'react';
import Header from './Header';
import Footer from './Footer';
import MobileMenu from './MobileMenu';
import NavigationBar from '../components/NavigationBar';
import { BrowserRouter ,Link,useParams} from 'react-router-dom';
import BuyWithCashgPopUp from '../components/CashBuyingPopUp';
import {connect} from 'react-redux';

import {Box} from '@mui/material';

import {createTheme,ThemeProvider,styled} from '@mui/material/styles';
import Headertest from './Headertest';

const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        xl: 1536,

            
        // 1920 ,
        // 1280,
        // 601,
        // 414,
        // 360,

        xxlTab:1281,
        xlTab: 1200,
        lgTab:1025,
        mdTab:992,
        smTab:821,
        xsTab:768,


        xxlMob:541,
        xlMob:415,
        lgMob:393,
        mdMob:376,
        smMob:360,
        xsMob:281,

      },
    },
  });

const ResponsiveTheme = styled('div')(({theme}) => ({


    [theme.breakpoints.up('1921')]: {
        
        '& .homeBannerSlider .slick-list':{

            height:'800px !important',
        },
        //    },
        '& header ':{
            '& .container':{
                maxWidth:'100%',
                // width:'100%',
                padding:'0px 116.5px',
            },
    },
    },
    [theme.breakpoints.down('1921')]: {
    //     '& .banner-container':{
    //         paddingLeft:'72px',
    
       '& .navigation-bar ':{
            maxWidth:'1320px',
            paddingLeft:'12px',
       },
       '& .bannerArea':{
            maxWidth:'1320px',
            paddingLeft:'12px',
       },
       '& .homeBannerSlider .slick-list':{
            height:'575px !important',
            '& .slick-track':{
                height:'100% !important',
                '& *':{
                    height:'100% !important',
                //     '& *':{
                //         height:'100% !important',
                //     },
                },
            },
            '& img':{
                height:'100% !important',
            }
        }
    },
    [theme.breakpoints.down('1536')]:{
        '& .homeBannerSlider .slick-list':{
            height:'525px !important',
        },
        '& .navigation-bar ':{
            maxWidth:'1320px',
            paddingLeft:'12px',
       },
       '& .bannerArea':{
            maxWidth:'1320px',
            paddingLeft:'12px',
       },
    },
    [theme.breakpoints.up('1400')]:{

    },
    [theme.breakpoints.down('1400')]:{
        // '& .homeBannerSlider .slick-list':{
        //     height:'525px !important',
        // },
        '& .navigation-bar ':{
            '& .container':{
                maxWidth:'1320px',
                paddingLeft:'12px',
            },
       },
    //    '& .bannerArea':{
    //         maxWidth:'1320px',
    //         paddingLeft:'12px',
    //    },
    },
    [theme.breakpoints.down('1367')]: {
        '& .homeBannerSlider .slick-list':{
            height:'450px !important',
        },
        '& .categoryPlaceForHome':{
            width:'250px',
        },
        '& .homeBannerSliderArea':{
           width:'calc(100% - 250px)',
        },
       '& .banner-container':{
            paddingLeft:'116.5px',
       }
    },
    [theme.breakpoints.up('1264')]: {
        '& .navigation-bar ':{
            '& .container':{
                maxWidth:'1320px',
                paddingLeft:'12px',
            },
       },
    },
    [theme.breakpoints.down('1264')]: {
        // '& .banner-container':{
        //     paddingLeft:'82px'
        // }, 
        '& .navigation-bar ':{
            maxWidth:'1140px !important',
            paddingLeft:'12px',
       },
       '& .bannerArea':{
            maxWidth:'1140px',
            paddingLeft:'12px',
       },
        '& .homeBannerSlider .slick-list':{
            height:'500px !important',
        },
        '& .categoryPlaceForHome':{
            width:'250px',
        },
        '& .homeBannerSliderArea':{
           width:'calc(100% - 250px)',
        },
    //    '& .banner-container':{
    //         paddingLeft:'73.5px',
    //    },


        '& .aboutFirstStage':{
            '& .firstStageLeft':{
                width:'45%',
                maxWidth:'45%',
                '& .aboutTextArea ':{
                    padding:'0px 73.5px',
                    paddingRight:'20px',
                }
            },
            '& .firstStageRight':{
                '& .aboutLogoArea ':{
                    marginRight:'73.5px',
                    maxHeight: '341px',
                },
                width:'55%',
                maxWidth:'55%',
            },
        },

        '& .calculatorWrapper':{
            width:'auto'
        }

    },
    [theme.breakpoints.down('1025')]: {
        // '& .banner-container':{
        //     paddingLeft:'82px'
        // },
        '& .paymentCardItem':{
            display:'inline-block',
            marginRight:'15px'
        },
        '& .rangeSliderArea ':{
            marginRight:'15px',
        },
        '& .detailsWrapper':{
            flexDirection:'column',
            // '& div':{
            //     '& div':{
            //         // display:'inline-block',
            //         display:'flex',
            //         marginRight:'10px',
            //         '& div':{
            //             display:'flex',
            //         },
            //     },
            // },
        },
        '& .navigation-bar ':{
            maxWidth:'960px !important',
            paddingLeft:'12px',
            // top:'119px',
       },
       '& .bannerArea':{
            maxWidth:'960px',
            paddingLeft:'12px',
       },
        '& .homeBannerSlider .slick-list':{
            height:'450px !important',
        },
        '& .banner-container':{
       },
    //    '& .bannerArea ':{
    //         paddingLeft:'44px',
    //    },
        '& .headerSearchInput ':{
            padding:'0px 30px',
        },
        '& .socials a':{
            '& svg':{
                fontSize:'23px',
            },
            fontSize:'13px',
            '&:last-of-type':{
                fontSize:'13px',
                fontSize: '13px',
                padding: '5px 9px',
            }
        },
        '& .searchResultProductItem':{
            width:'33.333333% !important',
            flexBasis:'33.333333%',
            maxWidth:'33.333333%',
        },
        '& .footerArea':{
            '& .footerMainContent':{
                width:'100% !important',
                padding:'44px',
                '& .footerTopWrapper':{
                    '& .footer1':{
                        width:'50% !important',
                        minWidth:'50% !important',
                    },
                    '& .footer2':{
                        width:'50% !important',
                        minWidth:'50% !important',
                    },
                    '& .footer3':{
                        width:'50% !important',
                        minWidth:'50% !important',
                        paddingLeft:'0% !important',
                        '& *':{
                            width:'100%',
                        },
                    },
                    '& .footer4':{
                        width:'50% !important',
                        minWidth:'50% !important',
                    },
                },
            },
            '& .footerDown':{
                padding:'15px 40px',
            },
        },
        '& .aboutFirstStage':{
            '& .firstStageLeft':{
                width:'45%',
                maxWidth:'45%',
                '& .aboutTextArea ':{
                    padding:'0px 44px',
                    paddingRight:'20px',
                }
            },
            '& .firstStageRight':{
                '& .aboutLogoArea ':{
                    marginRight:'44px',
                    maxHeight: '300px',
                },
                width:'55%',
                maxWidth:'55%',
            },
        },
    },
    [theme.breakpoints.down('993')]: {
        '& .homeBannerSlider .slick-list':{
            height:'430px !important',
        },
        '& .container ':{
            margin:'0px',
            width:'100%',
            maxWidth:'100%',
        },
        '& .purchaseForm':{
            width:'calc(100% - 50px) !important'
        },
        '& .banner-container':{
            paddingLeft:'12px'
       },
       '& .navigation-bar ':{
            top:'79px',
        },
       '& .socials ':{
            '& ul':{
                display:'flex',
                'svg':{
                    fontSize:'20px',
                },
                '& a':{
                    padding:'5px !important',
                },
            },
        },
        '& .basketText':{
            // display:'none',
            fontSize:'13px',
            textWrap:"nowrap"
        },
        '& .footerArea':{
            '& .footerMainContent':{
                '& .footerTopWrapper':{
                    '& .footer1':{
                        width:'50% !important',
                        minWidth:'50% !important',
                    },
                    '& .footer2':{
                        width:'50% !important',
                        minWidth:'50% !important',
                    },
                    '& .footer3':{
                        width:'50% !important',
                        minWidth:'50% !important',
                        paddingLeft:'0% !important',
                        '& *':{
                            width:'100%',
                        },
                    },
                    '& .footer4':{
                        width:'50% !important',
                        minWidth:'50% !important',
                    },
                },
            },
        },
        '& .aboutFirstStage':{
            '& .firstStageLeft':{
                width:'45%',
                maxWidth:'45%',
                '& .aboutTextArea ':{
                    padding:'0px 12px',
                    paddingRight:'20px',
                }
            },
            '& .firstStageRight':{
                '& .aboutLogoArea ':{
                    marginRight:'12px',
                    maxHeight: '341px',
                },
                width:'55%',
                maxWidth:'55%',
            },
        },
    },
    [theme.breakpoints.down('913')]: {
        " .language-change":{
            display:"none"
        },
        '& .calculationWrapper':{
            flexDirection:'column'
        },
        '& .detailsMore':{
            '& ul':{
                '& li:last-child':{
                    '& div':{
                        '& button:first-child':{
                            marginLeft:'0px',
                        }
                    }
                },
            },
        },

            '& .contactWrapper ':{
                // padding:'0px 15px'
                '& .personalFormWrapper':{
                    flexDirection:'column',
                },
                '& .contactAdressStage':{
                    width:'100%',
                        maxWidth: '100%',
                    '& ul':{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        '& li':{
                            width:'33%',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                        },
                    },
                },
                '& .contactFormsStage ':{
                    width:'100%',
                    maxWidth: '100%',
                },
            },
            '& .headerLeftPart ':{
                padding:'0px 10px'
            },
            '& .app-logo':{
                margin:'0px',
            },
            
            '& .navigation-bar':{
                display:'none',
            },
            '& .banner-container':{
                paddingLeft:'0px'
            },
            '& .container':{
                width:'100% !important',
                maxWidth:'100%',
                padding:'0',
            },
            '& .categoryPlaceForHome':{
                display:'none',
            },
            '& .homeBannerSliderArea':{
                width:'100%',
            },
            '& header':{
                // backgroundColor:'rgba(0,0,0,.9) !important',
                padding:'10px 50px',
            },
            '& .headerLeftPart':{
                width:'100%',
                flexDirection:'row-reverse',
                justifyContent:'space-between',
            },
            '& .socials ':{
                display:'none',
                // '& ul':{
                //     display:'flex',
                //     'svg':{
                //         fontSize:'20px',
                //     },
                //     '& a':{
                //         padding:'5px !important',
                //     },
                // },
            },
            '& .headerSearchInput':{
                display:'none',
            },
            '& .webMenuToggle':{
                display:'none',
            },
            '& .mobileSearch':{
                display:'inherit !important',
            },
            '& .mobileMenuToggle':{
                display:'inherit !important',
            },
            
            '& .productSliderHeading':{
                fontSize:'32px',
            },
            '& .footerLogoArea':{
                margin:'0px',

            },
            '& .aboutFirstStage':{
                '& .aboutContent ':{
                    flexDirection:'column',
                },
                '& .firstStageLeft':{
                    width:'100%',
                    maxWidth:'100%',
                    marginBottom:'25px',
                    padding:'0px 90px',
                    '& .aboutTextArea ':{
                        padding:'0px',
                    }
                },
                '& .firstStageRight':{
                    '& .aboutLogoArea ':{
                        margin:'0px 200px',
                        maxHeight: '250px',
                    },
                    width:'100%',
                    maxWidth:'100%',
                },
            },
            '& .basketPrice':{
                fontSize:'14px',
            },
            '& .basketDiscountPrice':{
                '& p':{
                    fontSize:'16px',
                }
            },
            '& .priceControlButtons':{
               '& .btnDec':{
                    padding:'5px',
               },
               '& .btnInc':{
                    padding:'5px',
               },
               '& .btnRes':{
                    padding:'5px',
               },                
            },
            '& .basketProductName':{
                maxWidth:'250px',
            },
            // '& .basketProductName':{

            // },
            '& .homeBannerSlider .slick-list':{
                height:'450px !important',
            },
    },
    [theme.breakpoints.down('821')]: {
         '& .searchResultProductItem':{
            width:'50% !important',
            flexBasis:'50%',
            maxWidth:'50%',
        },
        '& .basketList':{
            padding:'15px',
        },
        '& .contactWrapper ':{
            padding:'0px 35px'
            // '& .personalFormWrapper':{
            //     flexDirection:'column',
            // },
            // '& .contactAdressStage':{
            //     width:'100%',
            //         maxWidth: '100%',
            //     '& ul':{
            //         display: 'flex',
            //         width: '100%',
            //         justifyContent: 'space-between',
            //         '& li':{
            //             width:'33%',
            //             textAlign: 'center',
            //             display: 'flex',
            //             justifyContent: 'center',
            //         },
            //     },
            // },
            // '& .contactFormsStage ':{
            //     width:'100%',
            //     maxWidth: '100%',
            // },
        },
        '& .app-logo':{
            marginRight:'0px !important',
        },
        
        '& .footerArea ':{
            padding:'30px 0px',
        },
        '& .footerLogoArea':{
            // textAlign:'center',
            alignItems:'center',
            marginBottom:'25px',
        },
        '& .detailsMore':{
            padding:'0px 30px',
            '& .productName':{
                // color:'red',
                fontSize:'30px'
            },
            '& .calculationWrapper ':{
                flexDirection:'column',
            },
            '& .purchaseButton':{
                maxWidth:'auto',
                width:'33%',
                height:'40px',
                fontSize:'12px',
                // margin:'0'
                '&:nth-of-type(2)':{
                    margin:'0px 5px',
                }
            }
        },
        '& .aboutFirstStage':{
            '& .firstStageLeft':{
                width:'100%',
                maxWidth:'100%',
                marginBottom:'25px',
                '& .aboutTextArea ':{
                    padding:'0px',
                },
            },
            '& .firstStageRight':{
                '& .aboutLogoArea ':{
                    margin:'0px 200px',
                    maxHeight: '250px',
                },
                width:'100%',
                maxWidth:'100%',
            },
        },
        '& .calculatorWrapper':{
            width:'100%',
            '& .loanOption':{
                width:'100px',
                height:'100px',
                borderWidth:'7px',
                '& div':{
                    width:'100px',
                    height:'100px',
                    left:'-5px',
                    top:'-6px',
                },
                '&.selected div':{
                    width:'120px !important',
                    height:'120px !important',
                    left:'50%',
                    top:'50%',
                    transform:'translate(-50%, -50%)',
                    fontSize:'22px',
                },
            },
            '& .loanDivide':{
                height:'7px !important',
            },
            
        },
        '& .homeBannerSlider .slick-list':{
            height:'420px !important',
        },
    },
    [theme.breakpoints.down('769')]: {
        '& .mapArea':{
            minHeight:'450px',
        },
        '& .searchResultsContainer':{
            padding:'10px 10px 0px 10px',
        },
        '& .footerArea':{
            paddingBottom:'0px !important',
        },
        '& .calculationWrapper':{
            flexDirection:'column'
        },
        '& .detailsMore':{
            '& ul':{
                '& li:last-child':{
                    '& div':{
                        // justifyContent:'',
                        '& button':{
                            width:'50%',
                            // margin:'0px',
                        },
                        '& button:first-child':{
                            marginLeft:'0px',
                            marginRight:'5px',
                        }
                    }
                },
            },
        },
        '& .purchaseButton':{
            fontSize:'11px !important',
        },
        '& .seeMoreBtn':{
            fontSize:'13px',
        },
        '& .aboutFirstStage':{
            '& .firstStageLeft':{
                width:'100%',
                maxWidth:'100%',
                marginBottom:'25px',
                padding:'0px 40px',
                '& .aboutTextArea ':{
                    padding:'0px',
                }
            },
            '& .firstStageRight':{
                '& .aboutLogoArea ':{
                    margin:'0px 40px',
                    maxHeight: '250px',
                },
                width:'100%',
                maxWidth:'100%',
            },
        },
        '& .homeBannerSlider .slick-list':{
            height:'430px !important',
        },
    },
    [theme.breakpoints.down('541')]: {
        '& .secStage':{
            padding:'0px 50px',
            '& .pageHeading':{
                    fontSize:'25px !important'
            },
        },
        '& .purchaseFormWrapper':{
            overflowY: 'scroll',
            height: 'calc(100% - 100px)',
            // height:'400px',
            // overflowY:'scroll'
        },
        '& .purchaseForm ':{
            height:'400px',
            overflowY:'scroll'
        },
        '& .homeBannerSlider .slick-list':{
            height:'325px !important',
        },
        '& .detailsLeftPart':{
            marginBottom:'50px'
        },
        '& .branchContentArea':{
            height:'700px'
        },
        '& .branchSliderArea ':{
            height:'350px !important',
            marginBottom:'25px'
        },
        '& .basketContactForm':{
            '& span':{
                fontSize:'12px',
                display:'block',
            },
            '& form':{
                width:'200px !important'
            },
            '& .resultAmount':{
                marginRight:'25px !important'
            },
        },
        '& .resultAmount':{
            // flexDirection:'column',
        },
        '& .purchaseButton':{
            fontSize:'13px !important',
        },
        '& .contactWrapper ':{
            
            '& .contactAdressStage':{
                width:'100%',
                    maxWidth: '100%',
                '& ul':{
                    display: 'block !important',
                    '& li':{
                        width:'100%',
                        textAlign: 'center',
                        display: 'block',
                    },
                },
            },
           
        },
        '& .footerCenterArea':{
            display:'flex',
            justifyContent:'left',
            '& .footerCenter':{
                marginLeft:'50px',
                '& .headOfficeWrapper ':{
                    marginRight:'0px !important',
                },
            },
            marginBottom:'20px',
        },
        '& .calculatorWrapper':{
            '& .loanOption':{
                width:'55px',
                height:'55px',
                borderWidth:'4px',
                '& div':{
                    width:'59px',
                    height:'59px',
                    // left:'-4px',
                    // top:'-4px',
                    fontSize:'10px',
                },
                '&.selected div':{
                    width:'80px !important',
                    height:'80px !important',
                    left:'50%',
                    top:'50%',
                    transform:'translate(-50%, -50%)',
                    fontSize:'16px',
                },
            },
            '& .loanDivide':{
                width:'35px !important',
                height:'4px !important',
            },
            
        },
       '& .basketListItem':{
            flexDirection:'column',
            '& .basketItemLeft':{
                marginBottom:'25px',
            },
            '& .basketItemRight':{
                display:'flex',
                '& .right1':{},
                '& .right2':{
                    width:'66%',
                    display:'flex',
                    justifyContent:'space-between',
                },
            },
        }
        
    },
    [theme.breakpoints.down('415')]: {
        '& .homeBannerSlider .slick-list':{
            height:'235px !important',
        },
        '& .searchResultsContainer':{
            padding:'25px 25px 0px 25px',
        },
        '& .searchResultProductItem':{
            width:'100% !important',
            flexBasis:'100%',
            maxWidth:'100%',
        },
        '& .basketContactForm':{
            flexDirection:'column',
            justifyContent:'space-between',
            alignItems:'center',
            textAlign:'center',
            '& span':{
                fontSize:'12px',
                // width:'100%',
                marginBottom:'15px',
                display:'block',
            },
            '& form':{
                width:'200px !important'
            },
            '& .resultAmount':{
                marginRight:'0px !important'
            },
        },
        '& .resultAmount':{
            width:'100%',
            marginRight:'0px !important',
            textAlign:'center',
            justifyContent:'center !important',
            // flexDirection:'column',
        },
        '& .purchaseButton':{
            fontSize:'13px !important',
        },
        '& .product-details-content ':{
            paddingBottom:'25px !important'
        },

        '& .productSliderHeading':{
            fontSize:'22px',
        },
        '& .seeMoreBtn':{
            fontSize:'12px',
        },
        '& .footerArea':{
            '& .footerMainContent':{
                width:'100% !important',
                padding:'40px',
                '& .footerTopWrapper':{
                    margin:'0px',
                    width:'100%',
                    '& .footer1':{
                        width:'100% !important',
                        minWidth:'50% !important',
                        padding:'0%',
                        marginBottom:'30px',
                    },
                    '& .footer2':{
                        width:'100% !important',
                        minWidth:'100% !important',
                        padding:'0%',
                        marginBottom:'30px',
                    },
                    '& .footer3':{
                        width:'100% !important',
                        minWidth:'100% !important',
                        paddingLeft:'0% !important',
                        padding:'0%',
                        marginBottom:'30px',
                        '& .footer3Custom':{
                            padding:'0%',
                        },
                        '& *':{
                            width:'100%',
                        },
                    },
                    '& .footer4':{
                        width:'100% !important',
                        minWidth:'100% !important',
                        padding:'0%',
                        marginBottom:'30px',
                    },
                },
            },
            '& .footerDown':{
                padding:'15px 40px',
            },
        },
        '& .calculatorWrapper':{
            padding:'10px',
            '& .loanOption':{
                width:'55px',
                height:'55px',
                borderWidth:'3px',
                '& div':{
                    width:'50px',
                    height:'50px',
                    left:'0px',
                    top:'0px',
                    fontSize:'10px',
                },
                '&.selected div':{
                    width:'65px !important',
                    height:'65px !important',
                    left:'50%',
                    top:'50%',
                    transform:'translate(-50%, -50%)',
                    fontSize:'13px',
                },
            },
            '& .loanDivide':{
                width:'25px !important',
                height:'4px !important',
            },
            
        },
        '& .basketListItem':{
            '& .priceControlButtons':{
                margin:'0px',
                padding:'3px 7.5px',
                '& button':{
                    padding:'2.5px',
                    '& svg':{
                        fontSize:'19px',
                    }
                },
            },
            flexDirection:'column',
            '& .basketItemLeft':{
                marginBottom:'25px',
            },
            '& .basketItemRight':{
                display:'flex',
                '& .right1':{},
                '& .right2':{
                    width:'74%',
                    display:'flex',
                    justifyContent:'space-between',
                },
            },
        }
    },
    [theme.breakpoints.down('412')]: {
        // '& .homeBannerSlider .slick-list':{
        //     height:'235px !important',
        // },
        '& .purchaseButton':{
            fontSize:'10px !important',
        }
    },
    [theme.breakpoints.up('390')]: {
        // '& .homeBannerSlider .slick-list':{
        //     height:'235px !important',
        // },
    },
    [theme.breakpoints.down('376')]: {
        '& .homeBannerSlider .slick-list':{
            height:'220px !important',
        },
    },
    [theme.breakpoints.up('360')]: {
       
    },
}));


    

const FullLayout = ({children,buyWithCashReducer}) => {
    const [mobileMenuIsOpen,setMobileMenuIsOpen] = useState(false);
    const [headerDropMenuIsOpen,seHeaderDropMenuIsOpen] = useState(false);

   document.addEventListener('click',(e)=>{
       if(!e.target.closest('.webMenuToggle')){
            seHeaderDropMenuIsOpen(false);

        }
   })

   

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <ResponsiveTheme>
                    {/* <ThemeProvider theme={responsiveTheme}> */}
                    
                        {/* <Header headerDropMenuIsOpen={headerDropMenuIsOpen} seHeaderDropMenuIsOpen={seHeaderDropMenuIsOpen} setMobileMenuIsOpen={setMobileMenuIsOpen}/> */}
                        <Headertest headerDropMenuIsOpen={headerDropMenuIsOpen} seHeaderDropMenuIsOpen={seHeaderDropMenuIsOpen} setMobileMenuIsOpen={setMobileMenuIsOpen}/>
                        <NavigationBar/>
                        <MobileMenu isOpen={mobileMenuIsOpen} setMobileMenuIsOpen={setMobileMenuIsOpen}/>                        
                        <div className='page-content'>
                                {/* {
                                    buyWithCashReducer.buyWithCashItem!==null ? 
                                        <BuyWithCashgPopUp/>
                                    :''
                                } */}
                                {
                                    headerDropMenuIsOpen ?
                                    <Box
                                        sx={{
                                            width:'100%',
                                            height:'100%',
                                            backgroundColor:'#00000066',
                                            left:'0%',
                                            top:'0%',
                                            zIndex:'999',
                                            position:'fixed',
                                        }}
                                        onClick={()=>seHeaderDropMenuIsOpen(false)}
                                    >
                                    </Box>
                                    :''
                                }
                                  
                                {children}
                        </div>
                        <Footer/>
                    {/* </ThemeProvider> */}
                </ResponsiveTheme>
            </ThemeProvider>
        </BrowserRouter>
    );
}

const mapStateToProps=state=>state;
const mapDispatchToProps={

}

export default connect(mapStateToProps,mapDispatchToProps)(FullLayout);
