import React,{useState,useEffect} from 'react';

import {
    Box,
    Grid,
    Typography,
    Card,
    CardMedia,
    CardActions,
    CardHeader,
    CardContent,
    Button,
}from '@mui/material'

import {loadBranches} from '../services/services';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import {

    clickcerIncreaser,
} from '../services/services';
import { useTranslation } from 'react-i18next';

const BannerNextArrow = (props)=>{
    const {className,style,onClick} = props;
    return (
            <ArrowForwardIosIcon
                className={className}
                style={{...style,}}
                onClick={onClick}
            />
    )
}


const BannerPrevArrow = (props)=>{
    const {className,style,onClick} = props;
    return (
            <ArrowBackIosNewIcon
                className={className}
                style={{...style,}}
                onClick={onClick}
            />
    )
}

const Branches = () => {
    const {t} = useTranslation()
    const [branchState,setBranchState] = useState(null);
    const settings = {
        dots: true,
        infinite: true,
        arrows:false,
        speed: 500,
        autoplay:true,
        autoplaySpeed:2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow:<BannerNextArrow/>,
        prevArrow:<BannerPrevArrow />,
    };
    useEffect(()=>{
        (async()=>{
            setBranchState(await loadBranches());
            
        })()
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        return (()=>{
            (async()=>{
                await clickcerIncreaser({
                    "aboutClicker": false,
                    "basketClicker": false,
                    "branchClicker": true,
                    "contactClicker": false,
                    "counter": 0,
                    "discountClicker": false,
                    "facebookClicker": false,
                    "homeClicker": false,
                    "instagramClicker": false,
                    "pendingClickerFour": false,
                    "pendingClickerOne": false,
                    "pendingClickerThree": false,
                    "pendingClickerTwo": false,
                    "productClicker": false,
                    "seasonClicker": false,
                    "weekClicker": false
                  })
            })()
        })
        
    },[])

    return (
        <Box
            className=""
            sx={{
                paddingTop:'30px',
                paddingX: '40px'
            }}
        >
            <Box>
                <span style={{fontWeight: 'bold'}}>
                    {t("Mağazalarımız")}
                </span>
            </Box>
                {
                    branchState !== null ?
                        branchState.map((item,i)=>(
                            
                                <Card
                                    sx={{
                                        // padding:'5px',
                                        border:'1px solid #8e8e8e6b',
                                        boxShadow:'0px 0px 15px -10px #000',
                                        padding:'0px',
                                        marginBottom:'75px',
                                    }}
                                >
                                   
                                    <CardContent
                                        className='branchContentArea'
                                        sx={{
                                            padding:'0px !important',
                                            height:'350px',
                                            '& div':{
                                                height:'100%',
                                            }
                                        }}
                                    >   
                                        
                                        <Grid container spacing={1} sx={{height:'100%'}}>

                                            <Grid item md={6} sm={12} xs={12} className='branchSliderArea'>
                                                <Box
                                                    sx={{
                                                        height:'100%',
                                                        display:'flex',
                                                        flexDirection:'column',
                                                        justifyContent:'space-between',
                                                        // paddingRight:'15px',
                                                       
                                                    }}
                                                >
                                                    
                                                    <Box>
                                                        <Grid item md={12}>
                                                            <Box
                                                                sx={{
                                                                    width:'100%',
                                                                    height:'100%',
                                                                    '& .slick-slider':{
                                                                        '& .slick-dots li button:before':{
                                                                            fontSize:'10px',
                                                                            lineHeight:'19px',
                                                                        },
                                                                        '& .slick-dots li.slick-active button:before':{
                                                                            // fontSize:'10px',
                                                                            opacity: '1',
                                                                            color: '#ed1c22',
                                                                            border: '2px solid #ed1c22',
                                                                            borderRadius: '50%',
                                                                        },
                                                                    },
                                                                    '& .slick-arrow':{
                                                                        background: '#00000030',
                                                                        color: '#fff',
                                                                        zIndex:' 99999999',
                                                                        height:'100%',
                                                                        fontSize:'52px',
                                                                        '&.slick-next':{
                                                                            right:'0%',
                                                                        },
                                                                        '&.slick-prev':{
                                                                            left:'0%',
                                                                        },
                                                                    },
                                                                    // right: 0;
   
                                                                }}
                                                            >
                                                                <Slider {...settings}>
                                                                    {
                                                                        item.branchImages.length !== 0 ? 
                                                                            item.branchImages.map((item,i)=>(
                                                                                <div key={i} 
                                                                                    className="ss"
                                                                                    style={{
                                                                                        height:'100% !important',
                                                                                    }} 
                                                                                >
                                                                                    <img src={item.branchImageSource} alt=""  style={{width:'100%',height:'100%'}}/>
                                                                                </div>
                                                                            ))
                                                                        :''
                                                                    }
                                                                
                                                                </Slider>
                                                            </Box>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid  item md={6} sm={12} xs={12} className='mapArea'>
                                                <iframe 
                                                    src={item.branchMapAddressURL} 
                                                    width="100%" 
                                                    height="100%" 
                                                    // style="border:0;" 
                                                    allowFullScreen="" 
                                                    loading="lazy" 
                                                    referrerPolicy="no-referrer-when-downgrade"
                                                >
                                                </iframe>
                                            </Grid>
                                            
                                        </Grid>
                                       
                                    </CardContent>
                                    <Box
                                        className="branchTextArea"
                                        sx={{
                                            padding:'15px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize:'23px',
                                            }}
                                        >
                                            {
                                                item.branchName
                                            }
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color:'#8e8e8e',
                                                marginBottom:'10px',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    marginRight:'15px',
                                                }}
                                            >
                                                {
                                                    item.branchRegion
                                                }
                                            </span>
                                            <span
                                                style={{
                                                    fontSize:'14px',
                                                    // fontWeight:'700'
                                                }}
                                            >
                                                {
                                                    item.branchAddress
                                                }
                                            </span>
                                        </Typography>
                                    </Box>
                                    {/* <CardActions
                                        sx={{
                                            // textAlign:'right',
                                        }}
                                    >
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            sx={{
                                                
                                                textTransform:'capitalize',
                                                fontSize:'11px',
                                                '& a':{
                                                    textDecoration:'none',
                                                    color:'#fff'
                                                }
                                            }}
                                        >
                                           <a href={item.branchMapAddressURL} target="_blank">
                                                Xəritədə bax
                                           </a>
                                        </Button>
                                    </CardActions> */}
                                </Card>
                        ))
                    :''
                }
        </Box>
    );
}

export default Branches;
