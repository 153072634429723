import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Box, Button, Grid, IconButton, List, ListItem } from "@mui/material";

import BuyWithCashgPopUp from "./CashBuyingPopUp";

// import Slider from "react-slick";
import { connect } from "react-redux";
import ExampleImg from "../assets/img/exampleProductImg.webp";

import { basketAddAction } from "../redux/actions/basketAction";

import BasketStorage from "../redux/Storage";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FaShoppingBasket } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Skeleton from '@mui/material/Skeleton';

const ProductItem = ({
  item,
  productItem,
  basketAddAction,
  success,
  info,
  myClick,
}) => {
  const { t } = useTranslation();
  // console.log(props)
  // const [productItemState,setProductItemState] = useState({});

  // const [selectedByingWithCashProduct,setBuyingWithCashProduct]= useState(null);

  // const selectBuyingWithCashProductHandler=(item)=>{
  //     // setBuyingWithCashProduct(item);
  //     buyWithCashAction(productItem);
  //     // console.log('it is opened')
  // }

  const [isMouseOver, setIsMouseOver] = useState(false);
  const mouseOverHandler = () => {
    setIsMouseOver(true);
    // console.log('mouse enter')
  };
  const mouseOutHandler = () => {
    setIsMouseOver(false);
    // console.log('mouse leave')
  };

  // localStorage.clear()
  const itemAddToBasketHandler = (item) => {
    // console.log(BasketStorage._getStorageList())
    if (BasketStorage._itemIsInList(item)) {
      info(true);
      // toast.info("Məhsul səbətdə mövcuddur.",{
      //     position: "bottom-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      // });
    } else {
      success(true);
      // toast.info("Məhsul əlavə edildi.",{
      //     position: "bottom-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      // });
      BasketStorage._addItemStorage(item);
      basketAddAction(item);
    }
  };

  // useEffect(() => {
  //   // console.log(selectedByingWithCashProduct)
  // }, []);


  return (
    <>
    
      <Box
        className="md:h-[530px] sm:h-[]"
        onMouseEnter={mouseOverHandler}
        onMouseLeave={mouseOutHandler}
        sx={{
          borderRadius: "8px",
          position: "relative",
          backgroundColor: "#fff",
          // height: "530px",
          border: ".5px solid lightgrey",
          transition: "all .1s ease",
          marginRight: "25px",
          paddingBottom: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          "&:hover": {
            boxShadow: "0px 0px -17px 10px black",
            boxShadow: "0px 0px 33px -15px black",

            // transform: 'scale(1.01)',
            zIndex: "999",
            transform: "scale(1.1)",
          },
        }}
      >
        <Box
          className="sliderImgArea"
          sx={{ padding: "0px 30px", paddingTop: "30px", maxHeight: "295px" }}
        >
          {
            item.productDiscount
              ? <div>
                <span>
                  {item.productDiscount}

                </span>
              </div>
              : ''
          }
          <Link
            className="sliderProductImg"
            to={`/details/${item.id}`}
            onClick={myClick}
          >
            <img
              src={
                item.images.length
                  ? item.images.sort((a, b) => a.id - b.id)[
                    item.images.length - 1
                  ].source
                  : ""
              }
              style={{
                maxHeight: "200px",
                height: "100%",
                width: "100%",
                margin: "0px auto",
                objectFit: 'contain',
                justifyItems: 'center',
                justifyContent: 'center',
              }}
              alt={item.name}
            />
          </Link>
        </Box>
        <Box
          sx={{
            // height:'100%',

            display: "flex",
            flexDirection: "column",
            alignItems: "space-between",

            justifyContent: "space-between !important",
            padding: "15px",
          }}
        >
          <Box
            sx={{
              padding: "0px 10px",
              color: "#ed1c22",
            }}
          >
            <Link to={`/details/${item.id}`} onClick={myClick}>
              {item.productFreeDelivery === true ? (
                <span className="product-tag free-bring">
                  {t("Pulsuz çatdırılma")}
                </span>
              ) : (
                ""
              )}
              {item.productInterestFree === true ? (
                <span className="product-tag free-bring">{t("Faizsiz təklif")}</span>
              ) : (
                ""
              )}

              {item.productCommissioned === true ? (
                <span className="product-tag free-bring">{t("Komissiyasız")}</span>
              ) : (
                ""
              )}
              {item.active === true ? (
                <span className="product-tag free-bring">{t("Stokda var")}</span>
              ) : (
                ""
              )}
            </Link>
          </Box>

          {/* <Box
                        sx={{
                            fontSize:' 12px',
                            textAlign: 'center',
                            fontWeight: '700!important',
                            color: 'rgba(254,2,60,.91)!important',
                            margin:'15px 0px',
                        }}
                    >
                        0₼ 0% 18ay
                    </Box> */}
          <Box>
            <Grid container className="priceDetails">
              <Grid item md={12} lg={12} sm={12} xs={12} sx={{}}>
                <Link
                  to={`/details/${item.id}`}
                  onClick={myClick}
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#282828",
                      textAlign: "center",
                      textDecoration: "none",
                      marginBottom: 0,
                      overflow: "hidden",
                      marginTop: "15px",
                      textAlign: "left",
                      textTransform: "capitalize",
                      paddingLeft: "15px",
                      marginBottom: "10px",
                      maxHeight: "32px",
                      whiteSpace: "nowrapm",
                      overflow: "hidden",
                      textOverflow: " ellipsis",
                    }}
                    className="md:!hidden sm:!block"
                  >
                    {item.name.substring(0, 18)} {item.name.length >= 18 && "..."}
                  </Box>
                  <Box
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#282828",
                      textAlign: "center",
                      textDecoration: "none",
                      marginBottom: 0,
                      overflow: "hidden",
                      marginTop: "15px",
                      textAlign: "left",
                      textTransform: "capitalize",
                      paddingLeft: "15px",
                      marginBottom: "10px",
                      maxHeight: "32px",
                      whiteSpace: "nowrapm",
                      overflow: "hidden",
                      textOverflow: " ellipsis",
                    }}
                    className="md:!block sm:!hidden"
                  >
                    {item.name.substring(0, 24)} {item.name.length >= 24 && "..."}
                  </Box>
                </Link>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    padding: "0px 15px",
                    alignItems: "start",
                    flexDirection: "column",
                    minHeight: "55px",

                  }}
                >
                  <Box
                    sx={{
                      fontSize: item.productDiscount ? "" : "18px",
                      fontWeight: item.productDiscount ? "700" : "700",
                      marginRight: item.productDiscount ? "17.5px" : "",
                      color: item.productDiscount ? "#2C3333" : "#ff0009",
                      textDecoration: item.productDiscount
                        ? "line-through"
                        : "",
                      fontWeight: item.productDiscount ? "normal" : "bold",
                      textDecorationColor: 'red',
                    }}
                  >
                    {item.price}₼
                  </Box>
                  {item.productDiscount ? (
                    <Box
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      {item.productDiscountPrice
                        ? item.productDiscountPrice + "₼"
                        : ""}
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
              <Grid
                item
                md={12}
                lg={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 15px",
                  // justifyContent:'space-between',
                  "& a": {
                    textDecoration: "none",
                  },
                }}
              >
                <Button
                  variant="outlined"
                  color="info"
                  size="medium"
                  onClick={() => itemAddToBasketHandler(item)}
                  sx={{
                    width: "100%",
                    fontSize: "15px !important",
                    borderRadius: "8px",
                    fontWeight: "bold",
                    borderColor: "#E1E1E1",
                    backgroundColor: "#E1E1E1",
                    color: "#000",
                    textTransform: "capitalize",
                    padding: '10px',
                    "&:hover": {
                      borderColor: "#BABABA",
                      backgroundColor: "#BABABA",
                      color: "#000",
                      "& svg": {
                        color: "#fff !important",
                        backgroundColor: "transparent",
                      },
                    },
                    "& svg": {
                      backgroundColor: "transparent !important",
                      color: "#8e8e8e !important",
                      marginRight: "10px !important",
                      fontSize: "18px !important",
                    },
                  }}
                >
                  {t("Səbətə əlavə et")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => state;
const mapDispatchToProps = {
  basketAddAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductItem);
